/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function EventDetails(props) {
  const {
    date,
    time,
    pace,
    distanceFromHome,
    address,
    latlng,
    attendees,
    status,
    uploadHandler,
    confirmHandler,
    cancelHandler,
    checkinHandler,
    imageSrc,
    attendanceStatus,
    confirmDisplay,
    cancelDisplay,
    checkInDisplay,
    uploadDisplay,
    uploadSlot,
    imagesSlot,
    buttoncolor = "#9D28E2",
    titlestr = "Rundezvous Event",
    field1 = "Pace",
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="400px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="5px 5px 5px 5px"
      backgroundColor="rgba(250,250,250,1)"
      {...getOverrideProps(overrides, "EventDetails")}
      {...rest}
    >
      <Flex
        gap="28px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15000000596046448)"
        borderRadius="5px"
        padding="5px 5px 5px 5px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Frame 442")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          children={imagesSlot}
          {...getOverrideProps(overrides, "ImagesSlot")}
        ></Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display={uploadDisplay}
          {...getOverrideProps(overrides, "UploadFrame")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="152px"
            height="40px"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            children={uploadSlot}
            {...getOverrideProps(overrides, "UploadSlot")}
          ></Flex>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            backgroundColor={buttoncolor}
            children="Upload Photo"
            onClick={uploadHandler}
            {...getOverrideProps(overrides, "Button42281875")}
          ></Button>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 499")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="25px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 484")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="25px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={titlestr}
              {...getOverrideProps(overrides, "title")}
            ></Text>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 500")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281222")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Date"
                {...getOverrideProps(overrides, "Date")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281225")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={date}
                {...getOverrideProps(overrides, "2023-04-01")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 501")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281229")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Time"
                {...getOverrideProps(overrides, "Time")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281231")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={time}
                {...getOverrideProps(overrides, "08:00 - 09:00")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 502")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281234")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={field1}
                {...getOverrideProps(overrides, "Pace")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281236")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={pace}
                {...getOverrideProps(overrides, "10.5kph")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 503")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281239")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Distance from&#xA;home"
                {...getOverrideProps(overrides, "Distance from home")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281241")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={distanceFromHome}
                {...getOverrideProps(overrides, "2.3km")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 504")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281244")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Address"
                {...getOverrideProps(overrides, "Address")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281246")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={address}
                {...getOverrideProps(
                  overrides,
                  "11 Rue de l\u2019Arbre, Bruxelles, 1050, Belgium"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 505")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281249")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Lat/Lng"
                {...getOverrideProps(overrides, "Lat/Lng")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281251")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={latlng}
                {...getOverrideProps(overrides, "51.2423, 0.14123")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 506")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281254")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Confirmed attendees"
                {...getOverrideProps(overrides, "Confirmed attendees")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281256")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={attendees}
                {...getOverrideProps(overrides, "5")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 507")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281259")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Status"
                {...getOverrideProps(overrides, "Status")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281261")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={status}
                {...getOverrideProps(
                  overrides,
                  "Draft: awaiting sufficient attendance"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 511")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542361695")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Attendance"
                {...getOverrideProps(overrides, "Attendance:")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642361697")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={attendanceStatus}
                {...getOverrideProps(overrides, "Confirmed")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display={confirmDisplay}
            {...getOverrideProps(overrides, "ConfirmationFrame")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281264")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Confirm Attendance"
                {...getOverrideProps(overrides, "Confirm Attendance")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281266")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                backgroundColor={buttoncolor}
                children="Confirm"
                onClick={confirmHandler}
                {...getOverrideProps(overrides, "Button42281853")}
              ></Button>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display={cancelDisplay}
            {...getOverrideProps(overrides, "CancelFrame")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281858")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Can’t make it?"
                {...getOverrideProps(overrides, "Can\u2019t make it?")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281860")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                backgroundColor={buttoncolor}
                children="Cancel Attendance"
                onClick={cancelHandler}
                {...getOverrideProps(overrides, "Button42281861")}
              ></Button>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display={checkInDisplay}
            {...getOverrideProps(overrides, "CheckInFrame")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="150px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51542281866")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Check-in"
                {...getOverrideProps(overrides, "Check-in")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 51642281868")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                backgroundColor={buttoncolor}
                children="Check-in"
                onClick={checkinHandler}
                {...getOverrideProps(overrides, "Button42281869")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
