import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import studioTheme from './ui-components/studioTheme.js';
import { ThemeProvider } from "@aws-amplify/ui-react";
import './styles.css';
import {IntlProvider} from "react-intl";

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={studioTheme}>
        <IntlProvider>
            <App />
        </IntlProvider>
    </ThemeProvider>
);

reportWebVitals();
