import { useParams } from 'react-router';
import lookup from 'coordinate_to_country';
import { Flex, useTheme, Image, Text, TextField, Button, SelectField } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';
import getMember from '../utils/getMember.js';
import putMember from '../utils/putMember.js';
import { useNavigate } from 'react-router-dom';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MobileLayout from '../ui-components/MobileLayout.jsx';
import ChooseMembership from '../ui-components/ChooseMembership.jsx';
import MobileProgressBar from '../ui-components/MobileProgressBar.jsx';
import Free from '../ui-components/Free.jsx';
import Price from '../ui-components/Price.jsx';
import Discounted from '../ui-components/Discounted.jsx';
import RDVLogo from '../specific/images/Logo.png';
import { useState, useEffect } from 'react';
import NewMemberComponent from '../components/NewMemberComponent.js';
import PaymentComponent from '../components/PaymentComponent.js';
import QuickSchedule from '../components/QuickSchedule.js';
import Lottie from 'react-lottie';
import animationData from '../resources/purpleAnimation.json';
import SubscriptionDetails from '../ui-components/SubscriptionDetails.jsx';
import settings from '../specific/settings.json';
import appstoreblack from '../resources/appstoreblack.svg';
import playstoreblack from '../resources/google-play-badge.png';
import { getUrl } from 'aws-amplify/storage';
import { downloadData } from 'aws-amplify/storage';
import { signUp, signOut, deleteUser } from "aws-amplify/auth"
import { confirmSignUp } from 'aws-amplify/auth';
import { signIn } from 'aws-amplify/auth'
import ProgressBar from '../ui-components/ProgressBar.jsx';
import { useIntl } from 'react-intl';
import getUserLocale from 'get-user-locale';
import CLM from 'country-locale-map';
import updateMember from '../utils/updateMember.js'
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  EmailIcon
} from "react-share";

function NewMember(state) {
  const { code } = useParams();
  const [isReferral, setIsReferral] = useState(false);
  const navigate = useNavigate();
  const [member, setMember] = useState({});
  const { tokens } = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [isClub, setIsClub] = useState(false);
  const [membershipLevel, setMembershipLevel] = useState("Full");
  const [clubSettings, setClubSettings] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const [stage, setStage] = useState(0); //0: Not Started; 1: Details created; 2: Signed-up; 3:Confirmed; 4: Signed-in 4: Paid; 5: Schedule Provided;
  const intl = useIntl();
  const [currency, setCurrency] = useState(intl.locale === "en" ? "GBP" : intl.locale === "be" ? "EUR" : "USD");
  const [currencySymbol, setCurrencySymbol] = useState(intl.locale === "en" ? "£" : intl.locale === "be" ? "€" : "$")
  const [session, setSession] = useState(null);
  const [w3wInvalid, setW3wInvalid] = useState(false);

  // THREE :  Confirm email address and sign in
  const sendConfirmation = async () => {
    setIsSubmitting(true);
    if (confirmationCode.length === 6) {
      // console.log("Correct length");
    } else {
      // console.log("Wrong length");
      return;
    }
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username: email,
      confirmationCode: confirmationCode
    });
    if (isSignUpComplete) {
      setStage(3);
      getMember(email).then((tempMember)=> {
        tempMember.sign_up_stage = 3;
        console.log(tempMember);
        setMember(tempMember);
        // updateMember(tempMember);
        // console.log("Sign-up Complete!");
        signIn({
          username: email,
          password: password,
        }).then(() => {
          // console.log("Signed in - stage 4!");
          if(membershipLevel==="Full" && session !== null) {
            // console.log("HERE IS THE SESSION!");
            // console.log(session);
            window.location.replace(session.url);
            tempMember.sign_up_stage = 4;
            updateMember(tempMember);
            setMember(tempMember);
            setStage(4);
            setIsSubmitting(false);
          } else {
            setStage(5);
            tempMember.sign_up_stage = 5;
            updateMember(tempMember);
            // console.log("Updated the member to stage 5");
            setMember(tempMember);
            setIsSubmitting(false);
          }
  
        })
      });
      
    } else {
      console.log("Something went wrong with confirmation");
    }
  }

  // TWO : Submit the details, if successful, sign-up the user
  const putAndNav = async (item) => {
    setIsSubmitting(true);
    const tempPassword = item.password;
    // Submit the details with callback.
    putMember(item, 
      async (response) => {
        console.log("Running the success response");
        setStage(1);
        // console.log("Set stage 1");
        // console.log(response);
        if ('session' in response){
          setSession(response.session);
        }
        try{
          const { isSignUpComplete, userId, nextStep } = await signUp({
            username: item.email,
            password: tempPassword,
          });
          getMember(item.email).then((tempMember) => {
            console.log("In the then");
            console.log("tempMember:");
            console.log(tempMember);
            if (nextStep === "DONE") {
              setStage(3);
              // console.log("Set stage 3");
              tempMember.sign_up_stage = 3;
              updateMember(tempMember);
              setMember(tempMember);
              //Otherwise log the email and password for auto-sign in
            } else {
              setEmail(item.email);
              setPassword(tempPassword);
              setStage(2);
              console.log("Setting stage 2");
              // console.log(item.email);
              console.log(tempMember);
              tempMember.sign_up_stage = 2;
              console.log("After the set stage:");
              console.log(tempMember);
              updateMember(tempMember);
              setMember(tempMember);
              // console.log("Need to confirm sign up");
              setIsSubmitting(false);
              return userId;
            }
          });
          
        } catch {
          await signIn({
            username: item.email,
            password: tempPassword,
          }).then((mes) => {
            console.log(mes);
            if(mes.isSignedIn){
              getMember(item.email).then((tempMember) => {
                setStage(4);
                tempMember.sign_up_stage = 4;
                updateMember(tempMember);
                setMember(tempMember);
                setIsSubmitting(false);
              })
              
            }else if (mes.nextStep.signInStep === "CONFIRM_SIGN_UP") {
              getMember(item.email).then((tempMember) => {
                setStage(2);
                setPassword(tempPassword);
                setEmail(item.email);
                tempMember.sign_up_stage = 2;
                updateMember(tempMember);
                setMember(tempMember);
                setIsSubmitting(false);
                return;
              })
              
            }    
          })
          
        }
        
        // console.log("Complete? : " + isSignUpComplete + " - " + userId + " - " + nextStep);
        // If no confirmation required progress directly to stage 3
        
      },
      async (e) => {
        console.log("In the error callback."+e);
        
        if(e === "w3wError"){
          console.log("Setting the w3w error.")
          setW3wInvalid(true);
        }else{
          console.log("Unknown error!");
        }
        item.password = tempPassword;
        setMember(item);
        setIsSubmitting(false);
      }
    );
  }

  //ONE -> initialise the stage or reset the stage to where previously got to.
  useEffect(() => {
    setIsFetching(true);
    console.log("Running use effect");
    // console.log(getUserLocale());
    const locale = getUserLocale();
    const sp = locale.split("-")[1];
    const cur = CLM.getCountryByAlpha2(sp).currency;
    // console.log(cur);
    // console.log(sp);
    if(cur === "GBP" || cur === "EUR" || cur === "USD"){
      // console.log("Currency locally supported.");
      setCurrency(cur);
      if(cur==="GBP"){
        setCurrencySymbol("£");
      }else if (cur==="EUR"){
        setCurrencySymbol('€');
      }else{
        setCurrencySymbol("$")
      }
    }else{
      setCurrency("USD");
      setCurrencySymbol("$");
    }
    (async function () {
      //First see if there's an authenticated user
      const tempEmail = await currentAuthenticatedUser();
      console.log("Temp Email: " + tempEmail);
      
      if (tempEmail == null) {
        // console.log("There was no authenticated user.")
        setStage(0);
        setMember({
          member_name: "",
          email: "",
          member_location: "",
          lat: 0,
          lng: 0,
          num_runs: "",
          pace: "",
          gender: "",
          my_referred_code: '',
        })
      } else {
        setEmail(tempEmail);
        getMember(tempEmail).then((tempMember) => {
          if(typeof tempMember === undefined){
            // deleteUser();
            console.log("Type of user was undefined");
            signOut();
            window.location.replace(settings.Domain+"NewMember");
            return;
          }
          console.log(tempMember);
          setMember(tempMember);
          if (tempMember && 'sign_up_stage' in tempMember) {
            if(tempMember.sign_up_stage === 5){
              window.location.replace(settings.Domain+"RunList");
            } else if(((tempMember.sign_up_stage === 4) && (tempMember.subscription_status !== "trialing"))){
              if(session) {
                setStage(4);
                window.location.replace(session.url);
                
              }else{
                if (window.location.host === "www.rundezvous.io") {
                  window.open("https://buy.stripe.com/9AQ9DWecofzYcIU3cc", "_blank");
                } else {
                  window.open("https://buy.stripe.com/test_6oEdUh8Uj8h38dqbII", "_blank");
                }
                setStage(5);
              }
              
            } else if (tempMember.sign_up_stage === 4) {
              setStage(5);
              // console.log("Found a session id");
              tempMember.sign_up_stage = 5;
              // console.log(await tempMember);
              updateMember(tempMember);
            }
            // setStage(tempMember.sign_up_stage);
            
          }else{
            window.location.replace(settings.Domain+"RunList");
            // window.location.href = (settings.Domain+"RunList");
          }
          if (window.location.href.split("?").length > 1) {
            var args = window.location.href.split("?")[1].split("&").map((x) => x.split("="));
            for (let i = 0; i < args.length; i++) {
              if (args[i][0] === "redirect_status" && args[i][1] === "succeeded") {
                setStage(5);
              }
              if (args[i][0] === "session_id") {
                setStage(5);
                // console.log("Found a session id");
                tempMember.sign_up_stage = 5;
                // console.log(await tempMember);
                updateMember(tempMember);
                // console.log("Updated the member to stage 5"+ (await tempMember).sign_up_stage);
                setMember(tempMember);
              }
              if ( args[i][0] === "session_failed") {
                console.log("THE SESSION FAILED");
              }
            }
          };
        });
        
      }

      //CHECK FOR REFERRAL CODE
      if (code) {
        setIsReferral(true);
        if (tempEmail == null) {
          setMember({
            member_name: "",
            email: "",
            member_location: "",
            lat: null,
            lng: null,
            num_runs: "",
            pace: "",
            gender: "",
            my_referred_code: code,
          })
        }
      }
      try{
        if (code && code.substring(0, 5).toLowerCase() === "club-") {
          setIsClub(true);
          const path = "clubs/" + code.split("club-")[1].toUpperCase() + "/logo.png"
  
          console.log(path);
          const url = await getUrl({ key: path, options: { level: 'guest' } });
  
          console.log(url);
          setImage(url.url.href);
  
          const settings_path = "clubs/" + code.split("club-")[1].toUpperCase() + "/clubsettings.json"
          // console.log("public/" + settings_path);
          const { body, eTag } = await downloadData({ key: settings_path, options: { level: 'guest' } }).result;
          // console.log(JSON.parse(await body.text()));
          setClubSettings(JSON.parse(await body.text()));
          setIsFetching(false);
          if (tempEmail == null) {
            setMember({
              member_name: "",
              email: "",
              member_location: "",
              lat: 0,
              lng: 0,
              num_runs: "",
              pace: "",
              gender: "",
              my_referred_code: code.split("club-")[1].toUpperCase(),
            })
          }
        }
      } catch (err){
        navigate('/')
      }
      
      
    })();
  }, []);

  const complete = () =>{
    setStage(6);
  }

  const mainContent = (
    isSubmitting ? <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
      height={400}
      width={400} />
      :
      stage === 0 || stage === 1 ? //Not started or Details Created
       
        <Flex width={"100%"} direction={"column"} alignItems={"center"}>
          {state.state.state !== "desktop" ?<ChooseMembership width={"100%"} isFull={membershipLevel === "Full"} clickFull={() => setMembershipLevel("Full")} clickBasic={() => setMembershipLevel("Basic")} /> : ""}
            {state.state.state !== "desktop" ? 
            membershipLevel==="Full" ?

              (isClub && clubSettings && (!isFetching)) ? 
              <Flex alignItems={"center"} width={"100%"} direction={"column"} backgroundColor={"#ffffff"} padding={20}>
                <Discounted newpricestr={currencySymbol + (4 * (1 - (clubSettings['PercentOff'] / 100))) + " per month"} oldpricestr={currencySymbol + "4 per month"} /> 
                <Image width={"35%"} alt="Logo" src={image}/>
                </Flex>
                : 
                <Flex alignItems={"center"} width={"100%"} direction={"column"} backgroundColor={"#ffffff"} padding={20}>
                <Price pricestr={currencySymbol + "4 per month"} /> 
                </Flex>
            :
            <Flex alignItems={"center"} width={"100%"} direction={"column"} backgroundColor={"#ffffff"} padding={20}><Free /></Flex>
            :
            ""
            }
          <NewMemberComponent w3wInvalid={w3wInvalid} marginLeft={state.state.state === "desktop" ? 20 :0} width={"100%"} isreferral={isReferral} membershiplevel={membershipLevel} mobiledesktop={state.state.state} initialData={member} backgroundColor={tokens.colors.white}
            onSubmit={(item) => {
              var currentdate = new Date();
              var datetime = currentdate.getFullYear() + "-"
                + (currentdate.getMonth() + 1) + "-"
                + currentdate.getDate() + " "
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds() + "."
                + currentdate.getMilliseconds()
              item.last_updated = datetime;
              item.num_runs = Number(item.num_runs);
              item.member_location = undefined;
              // console.log(item.lat+","+item.lng);
              item.country = lookup(item.lat,item.lng,true)[0];
              item.field1 = Number(item.field1);
              item.currency = currency;
              item.return_url = window.location.href;
              if(membershipLevel === "Full"){
                if (window.location.host === "www.rundezvous.io") {
                  item.priceid = "price_1PnG57AlvTIDDgz5y4N9LyJW";
                } else {
                  item.priceid = "price_1Pn6KVAlvTIDDgz5vZ1s9n8h";
                }
                if (isClub) {
                  console.log("Club so setting discount");
                  item.coupon = code.split("club-")[1].toUpperCase();
                  item.discount_percent = clubSettings ? clubSettings['PercentOff'] : 0
                } else {
                  item.coupon = "";
                }
              }else{
                item.priceid = "";
                item.coupon = "";
                item.discount_percent = 0;
              }
              
              // item.email = email;
              console.log(item);
              putAndNav(item);

            }} />
        </Flex>
        :
        stage === 2 ?//Signed-up
          <Flex
            width={"100%"}
            backgroundColor={"white"}
            padding={"20px"}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
            <Flex>
              <Text>We have sent a six digit confirmation code to {email}. Please make sure to check your junk or spam folders.</Text>
            </Flex>
            <TextField
              width={"100%"}
              label={
                <span style={{ display: "inline-flex" }}>
                  <span>Confirmation Code</span>
                  <span style={{ color: "red" }}>*</span>
                </span>
              }
              isRequired={true}
              value={confirmationCode}
              maxLength={6}
              keyboardType='numeric'
              onChange={(e) => {
                setConfirmationCode(e.target.value.replace(/[^0-9]/, ''));
              }}
            ></TextField>
            <Button onClick={() => {
              sendConfirmation();
            }}>
              Submit {isClub && clubSettings['PercentOff'] === 100 ? '' : '(redirect to Stripe for payment)'}
            </Button>
          </Flex>
          :
          stage === 3 || stage === 4 // Confirmed or Signed-In
            ?
            <Flex marginTop={"20px"} padding={"20px"} width={"100%"}>
              {/* <Elements stripe={stripePromise} options={options} currency={currency}>
                  <CheckoutForm />
                </Elements> */}
              <PaymentComponent member={member} currency={currency} isClub={isClub} currencySymbol={currencySymbol} clubSettings={clubSettings} />
            </Flex>
            :
            stage === 5 // Paid or no need to pay
              ?
              <Flex direction={'column'} marginLeft={state.state.state === "desktop"? 20: 0}>
                <Flex width={"100%"} backgroundColor={'#ffffff'} padding={state.state.state === "desktop"? 20: 0}>
                <Text>Please create slots where you are free each week. You can update these at any time. The more slots you add the better our chances of getting you a good match!</Text>
                </Flex>
              <QuickSchedule width={"100%"} email={email} complete={() => complete()} />
              </Flex>
              :
                stage === 6
                ?
                <Flex direction={'column'} marginLeft={state.state.state === "desktop"? 20: 0} width={"100%"} backgroundColor={"#ffffff"} padding={20}>
                  <Text>Thanks for signing up to Rundezvous!</Text>

                  <Text>You can now get the app for your mobile device:</Text>
                  <div marginBottom={"20px"}>
                    <Image src={appstoreblack} width={100} marginRight={20} onClick={() => window.open(settings.URLs.AppStore, "_blank")} />
                    <Image src={playstoreblack} width={112} paddingTop={20} onClick={() => window.open(settings.URLs.PlayStore, "_blank")} />
                  </div>
                  <Flex direction={'column'} width={"100%"} padding={20}>
                    <Text>There are currently only a few members in your area, refer your friends so we can find times for you to run together using your personal referral link and we'll give you a free month of full membership every time someone joins!</Text>
                    <Text> {settings.Domain+"referral/"+member.my_referral_code}</Text>
                    <Flex direction={'column'} alignItems={'center'}>
                    <Flex>
                        <WhatsappShareButton title={"Join me on Rundezvous!"} children={<WhatsappIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                        <FacebookShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                        <EmailShareButton subject={"Join me on Rundezvous!"} body={"Come and join me on Rundezvous and we can run together!"} separator=' : ' children={<EmailIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/>
                        {/* <FacebookMessengerShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookMessengerIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/> */}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex paddingLeft={20} paddingBottom={20} paddingRight={20}>
                    <Button width={"100%"} onClick={() => navigate('/RunList')}>Home</Button>
                  </Flex>
                </Flex>

                :
              <Text>No stage...?</Text>

  )

  const secondaryContent = (
    member &&
      <>
        {isClub ?
          // <Flex width={"100%"} backgroundColor={"White"}>
          //   <Flex width={"100%"}>
          <Flex width={"100%"} direction={"column"} backgroundColor={"white"} alignItems={"center"}>
            

            {/* <Text width={"95%"} backgroundColor={"white"}>Use code {code && code.split("club-")[1]} at Stripe checkout{clubSettings && " for "+clubSettings['PercentOff']+"% off full membership"}.</Text> */}
          </Flex>
          // </Flex> 
          : ""}
        <Flex backgroundColor={"white"} width="100%" padding={"0px"} defaultValue={"Full"}>
          {stage === 0 ?
            <Flex width={"100%"} direction={"column"} alignItems={"center"}>

              <ChooseMembership width={"100%"} isFull={membershipLevel === "Full"} clickFull={() => setMembershipLevel("Full")} clickBasic={() => setMembershipLevel("Basic")} />
              <SelectField
                marginLeft={"20px"}
                marginRight={"20px"}
                marginBottom={"20px"}
                maxWidth={"300px"}
                value={currency}
                onChange={(e) => {
                  let { value } = e.target;
                  setCurrency(value);
                  setCurrencySymbol(value === "GBP" ? "£" : value === "EUR" ? "€" : "$");
                }}
              >
                <option children="GBP (£)" value="GBP" />
                <option children="EUR (€)" value="EUR" />
                <option children="USD ($)" value="USD" />
              </SelectField>
            </Flex>
            : ""}
        </Flex>
        
        {membershipLevel == "Full" ?

          <SubscriptionDetails
            overrides={
              {
                "Frame 575":{height: "0px"},
                "Frame 580":{height: "0px"}
              }}
            width={"100%"}
            logosrc={RDVLogo}
            priceStr={currencySymbol + "4.00 per month"}
            subscribestr={"Submit & Subscribe"}
            titlestr={"Full Membership"}
            subtitlestr={"Unlimited Runs, Every Week."}
            priceslot={isClub && clubSettings && (!isFetching) ? <Discounted newpricestr={currencySymbol + (4 * (1 - (clubSettings['PercentOff'] / 100))) + " per month"} oldpricestr={currencySymbol + "4 per month"} /> : <Price pricestr={currencySymbol + "4 per month"} />}
            buttoncolor={settings.Theme.PrimaryColor}
            termsurl={settings.Domain + "terms"}
            subsubtitlestr={"Free until it works for you!"}
            firstqstr={"What do I get for my subscription?"}
            firstastr={"More than 1 run weekly - every week our algorithm will attempt to match you with as many runs as you request."}
            firsta2str={"You will also get access to “Ad-Hoc” run scheduling, where you can request runs at the last minute and find other people when you want - no need to wait each week for the Wednesday scheduling (coming soon). No banner ads (browser and mobile)."}
            secondqstr={"Free until it works?"}
            secondastr={"We won’t charge you anything until you’re getting good matches every week! * "}
            seconda2str={"We'll keep extending your free-trial until there are enough people locally for us to consistently match you. Our algorithm works best once there is good uptake in your area, so initially there may not be enough people to create the best events for you. Refer your friends for better matches and get more free months for yourself!"}
            thirdqstr={<></>}
            thirdastr={<></>}
            thirda2str={<></>}
            appstr={"Is there an app?"}
            appastr={"Yes, Rundezvous is available on iOS, Android and in your browser. But if you're going to subscribe for Full Membership, it will always be cheapest right here."}
            showterms={true}
            appArea=
            <div>
              <Image src={appstoreblack} width={100} marginRight={20} onClick={() => {}} />
              <Image src={playstoreblack} width={112} paddingTop={20} onClick={() => {}} />
            </div>
          />
          :
          <SubscriptionDetails
            overrides={
              {"Frame 575":{height: "0px"},
                "Frame 580":{height: "0px"}
              }}
            width={"100%"}
            logosrc={RDVLogo}
            priceStr={currencySymbol + "0 (for now)"}
            subscribestr={"Submit & Subscribe"}
            titlestr={"Basic Membership"}
            subtitlestr={"One run per week."}
            priceslot={<Free />}
            buttoncolor={settings.Theme.PrimaryColor}
            termsurl={settings.Domain + "terms"}
            subsubtitlestr={"Free for early adopters!"}
            firstqstr={"What do I get as a Basic Member?"}
            firstastr={"Once each week our algorithm will attempt to match you with ONE social running event."}
            firsta2str={"As a Basic Member you are entitled to  one match every week, to run more frequently or to get access to advanced features, please consider Full Membership."}
            secondqstr={"Free for early adopters?"}
            secondastr={"If you sign-up now you will stay a free Basic Member forever. Sign-up now as this won’t stay free forever!"}
            seconda2str={"Our algorithm works best once there is good uptake in your area, so initially there may not be enough people to create the best events for you. Refer your friends and get free months of free membership!"}
            appstr={"Is there an app?"}
            appastr={"Yes, Rundezvous is available on iOS, Android and in your browser. But if you're going to subscribe for Full Membership, it will always be cheapest right here."}
            showterms={true}
            appArea=
            <div>
              <Image src={appstoreblack} width={100} marginRight={20} onClick={() => window.open(settings.URLs.AppStore, "_blank")} />
              <Image src={playstoreblack} width={112} paddingTop={20} onClick={() => window.open(settings.URLs.PlayStore, "_blank")} />
            </div>
          />
        }
      </>
  )

  return (
        <Flex width={"100%"} backgroundColor={tokens.colors.background.primary}>
          {(state.state.state === "desktop" ?
            (
              <DesktopLayout
                display="block"
                width={"100%"}
                navBar=<ProgressBar width={"100%"} oneactive={stage === 0} onecomplete={stage > 0} onetocome={stage < 0}
                  twoactive={stage === 2} twocomplete={stage > 2} twotocome={stage < 2}
                  threeactive={stage === 4} threecomplete={stage > 4} threetocome={stage < 4}
                  fouractive={stage === 5} fourcomplete={stage > 5} fourtocome={stage < 5} />
                mainContent={mainContent}
                leftBar={secondaryContent}
              />)
            :
            <MobileLayout width="100%"
              mobileHeader=<MobileProgressBar width="100%"
              oneactive={stage === 0} onecomplete={stage > 0} onetocome={stage < 0}
              twoactive={stage === 2} twocomplete={stage > 2} twotocome={stage < 2}
              threeactive={stage === 4} threecomplete={stage > 4} threetocome={stage < 4}
              fouractive={stage === 5} fourcomplete={stage > 5} fourtocome={stage < 5}
                />
              firstContent=<div>
                {mainContent}
                {stage <5 && secondaryContent}
              </div>
            />
          )}
        </Flex>
  );
}

async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    // console.log(`The username: ${username}`);
    // console.log(`The userId: ${userId}`);
    // console.log(`The signInDetails: ${signInDetails.loginId}`);
    // console.log(signInDetails);
    return signInDetails.loginId;
  } catch (err) {
    console.log(err);
  }
}


export default NewMember;