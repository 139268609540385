import Navbar from '../components/Navbar.js';
import { Flex, useTheme } from '@aws-amplify/ui-react';
import getMember from '../utils/getMember.js'
import getAttendance from '../utils/getAttendance.js'
import updateMember from '../utils/updateMember.js'
import { useNavigate } from 'react-router-dom';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MobileLayout from '../ui-components/MobileLayout.jsx'; 
import MyDetailsHeader from '../ui-components/MyDetailsHeader.jsx';
import FormHolder from '../ui-components/FormHolder.jsx';
import MobileSideBar from '../ui-components/MobileSideBar.jsx'; 
import MobileHeaderBar from '../ui-components/MobileHeaderBar.jsx';
import RDVLogo from '../specific/images/Logo.png';
import MenuIcon from '../resources/menu.png';
import LogoutIcon from '../resources/logout.png';
import { useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import DetailsComponent from '../components/DetailsComponent.js';
import { list } from 'aws-amplify/storage';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import logo from '../specific/images/LongLogoColor.png';
import lookup from 'coordinate_to_country';

async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    return signInDetails.loginId;
  } catch (err) {
    console.log(err);
  }
}

function MyDetails(state) {
  // const { data: member } = useMyFetch('member',false);
  // const { data: ats } = useMyFetch('attendance', false);
  const [ats, setAts] = useState([]);
  const [member,setMember] = useState({}); // getMember();//useMyFetch('member', false);
  const {tokens} = useTheme();
  const [menu, setMenu] = useState(false);
  const [memberImage, setMemberImage] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
        const tempEmail = await currentAuthenticatedUser();
        // console.log("tempemail: "+tempEmail);
        var tempMember = await getMember(tempEmail);
        setMember(await tempMember);
        var tempAttendance = await getAttendance(tempEmail);
        // console.log("attendance: ")
        console.log(await tempAttendance);
        setAts(tempAttendance);
        if (tempMember !== undefined){
          const path = "members/"+tempMember.country+"/"+tempMember.pk_hash+".png";
          // console.log(path);
          const imsResult = await list({prefix:path, options:{level: 'public'}});
          // console.log(imsResult);
          if(imsResult.items.length>0) {
            setMemberImage(imsResult.items[0].key);
            // console.log("Set the image")
          }
        }else{
          navigate('/NewMember');
        }
        // console.log(member.member_name);
    })();
    // getMember(currentAuthenticatedUser());
},[]);

  const goHome = () => {
    navigate('/HomePage');
  }
  
  
  return (

    <div className="MyDetails">
      <div className="content">
      <Flex backgroundColor={tokens.colors.background.primary}>
      {(state.state.state==="desktop" ?
                (
                <DesktopLayout 
                    display="block" 
                    width="100%"

                    navBar=<Navbar /> 
                    mainContent= <div>                    
                    {member && <FormHolder innerContent= 
                      <DetailsComponent 
                        backgroundColor={tokens.colors.white} 
                        initialData={member} 
                        onSubmit={(item) => {
                          var currentdate = new Date(); 
                          var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds()
                          item.last_updated = datetime;
                          item.num_runs = Number(item.num_runs);
                          item.field1 = Number(item.field1);
                          item.member_location = undefined;
                          item.country = lookup(item.lat,item.lng,true)[0];
                          item.sign_up_stage = 5;
                          console.log(item);
                          updateMember(item, goHome);
                          // if(result.error === "BadWords"){
                          //   alert("Your what3words was not valid!")
                          // }else{
                            
                          // }
                          navigate('/HomePage');
                          }}
                          
                      />
                    />
                    }
                    </div>
                    leftBar={member && ats && <MyDetailsHeader imageFrame={<StorageImage alt="Member" imgKey={memberImage} accessLevel="guest" />} 
                                            name={member.member_name} 
                                            email={member.email} 
                                            status={member.member_status === "initial_trial" ? "Free Trial" : (member.member_status === "basic" ? "Unsubscribed User" : (member.member_status === "paid" ? "Full Member" : (member.member_status === "stripe_trial"? "Subscribed Trial Period" : "")))} 
                                            stat1title="Scheduled"
                                            stat2title="Confirm"
                                            stat3title="Completed"
                                            stat1={member.upcoming_events}
                                            stat2={member.confirmation_events}
                                            stat3={member.completed_events}
                                            date={ats.length > 0 ? (new Date(ats[0].event.date_time.slice(0, 10))).toLocaleDateString()  : ""}
                                            time={ats.length > 0 ? ats[0].event.date_time.slice(11, 16) : ""}
                                            pace={ats.length > 0 ? (Math.round(ats[0].event.pace * 10) / 10) + "kph" : ""}
                                            />}/>)
                      :
                      <MobileLayout width="100%"
                        mobileHeader = <MobileHeaderBar width = "100%"
                            logoURL={logo} 
                            burgerURL={MenuIcon} 
                            navigateHomeURL={"/HomePage"}
                            burgerHandler={() => {
                                setMenu(!menu);
                                console.log("burger!")
                        }}/>
                        firstContent= <div>                    
                        {!menu && member && <DetailsComponent backgroundColor={tokens.colors.white} initialData={member} onSubmit={(item) => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                              + (currentdate.getMonth()+1) + "-"
                              + currentdate.getDate() + " "
                              + currentdate.getHours() + ":"
                              + currentdate.getMinutes() + ":"
                              + currentdate.getSeconds() + "."
                              + currentdate.getMilliseconds()
                            item.last_updated = datetime
                            item.num_runs = Number(item.num_runs);
                            item.country = lookup(item.lat,item.lng,true)[0];
                            item.member_location = undefined;
                            item.field1 = Number(item.field1);
                            item.sign_up_stage = 5;
                            // console.log("in the MyDetails submit: "+item);
                            updateMember(item);
                            navigate('/HomePage');
                          
                          }}/>}
                          { member && menu && <MobileSideBar 
                                name= {member.member_name}
                                logoURL= {RDVLogo}
                                logoutURL= {LogoutIcon}
                                runListURL= {"/RunList"}
                                myDetailsURL= {"/MyDetails"}
                                scheduleURL= {"/Schedule"}
                                subscriptionURL= {"/Subscribe"}
                                homeURL= {"/HomePage"}
                                />
                            }
                        </div>
            />)}
        </Flex>
      </div>
    </div>
  );
}

export default MyDetails;