import * as React from "react";
import { Button, Flex, Grid, SelectField, TextField, Link, PasswordField, CheckboxField, Icon } from "@aws-amplify/ui-react";
import { CiLocationOn } from "react-icons/ci";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import settings from '../specific/settings.json';
import {APIProvider, Map, AdvancedMarker, Pin} from '@vis.gl/react-google-maps';
import { IconContext } from "react-icons";




export default function NewMemberComponent(props) {
  const { initialData, onSubmit, onJoin, onValidate, onChange, overrides, ...rest } = props;
  const initialValues = {
    member_name: "",
    email: "",
    password: "",
    lat: "",
    lng: "",
    num_runs: "",
    gender: "",
    my_referred_code: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    accepted: false
  };
  const [member_name, setMember_name] = React.useState(initialValues.member_name);
  const [email, setEmail] = React.useState(initialValues.email);
  const [password, setPassword] = React.useState(initialValues.password);
  // const [member_location, setMember_location] = React.useState(initialValues.member_location);
  const [num_runs, setNum_runs] = React.useState(initialValues.num_runs);
  const [gender, setGender] = React.useState(initialValues.gender);
  const [my_referred_code, setmy_referred_code] = React.useState(initialValues.referralCode);
  const [lat, setLat] = React.useState(initialValues.lat);
  const [lng, setLng] = React.useState(initialValues.lng);
  const [field1, setField1]= React.useState(initialValues.field1);
  const [field2, setField2]= React.useState(initialValues.field2);
  const [field3, setField3]= React.useState(initialValues.field3);
  const [field4, setField4]= React.useState(initialValues.field4);
  const [field5, setField5]= React.useState(initialValues.field5);
  const [accepted, setAccepted] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [showMap, setShowMap] = React.useState(false);
  const [validations, setValidations] = React.useState({
    member_name: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    password: [{ type: "Required" }, {
      type: "GreaterThanChar",
      numValues: [7],
      validationMessage: "The password must be at leats 8 characters"
    }], //TODO DOOD
    // member_location: [{ type: "Required" }],
    lat: [{type: "Requred"},
      {
        type: "NotEqualTo",
        numValues: [0],
        validationMessage: "The lat must not be zero",
      }
    ],
    lng: [{type: "Requred"},
      {
        type: "NotEqualTo",
        numValues: [0],
        validationMessage: "The lng must not be zero",
      }],
    gender: [{type: "Required"}],
    num_runs: [
      { type: "Required" },
      {
        type: "LessThanNum",
        numValues: [8],
        validationMessage: "The value must be less than 8",
      },
    ],
    accepted: [
      { type: "Required"}
    ]
  });
  
//   var validations = ;
  const resetStateValues = () => {
    const cleanValues = initialData
      ? { ...initialValues, ...initialData }
      : initialValues;
    setMember_name(cleanValues.member_name);
    setEmail(cleanValues.email);
    setPassword(cleanValues.password);
    // setMember_location(cleanValues.member_location);
    setLat(cleanValues.lat);
    setLng(cleanValues.lng);
    setNum_runs(cleanValues.num_runs);
    setGender(cleanValues.gender);
    setmy_referred_code(cleanValues.my_referred_code);
    setField1(cleanValues.field1);
    setField2(cleanValues.field2);
    setField3(cleanValues.field3);
    setField4(cleanValues.field4);
    setField5(cleanValues.field5);
    setAccepted(false);
    setErrors({});
  };
  React.useEffect(resetStateValues, [initialData]);
  React.useEffect(() => {
    // console.log(initialData)
    if (initialData) {
      setMember_name(initialData.member_name);
      setEmail(initialData.email);
      setPassword(initialData.password);
      // setMember_location(initialData.member_location);
      console.log("initial data:");
      console.log(initialData);
      if(initialData.lat){
        setLat(initialData.lat);
      }
      if(initialData.lng){
        setLng(initialData.lng);
      }
      setNum_runs(initialData.num_runs);
      setGender(initialData.gender);
      setmy_referred_code(initialData.my_referred_code);
      if(initialData.field1){
        setField1(initialData.field1);
      }
      if(initialData.field2){
        setField2(initialData.field2);
      }
      if(initialData.field3){
        setField3(initialData.field3);
      }
      if(initialData.field4){
        setField4(initialData.field4);
      }
      if(initialData.field5){
        setField5(initialData.field5);
      }
      if(initialData.accepted){
        setAccepted(initialData.accepted);
      }
    }

    // setFields(settings.Fields);
    // console.log(settings.Fields);
    var localValidations = validations;
    for (let i = 0; i < settings.Fields.length; i++) {
        // console.log(settings.Fields[i].name);
        localValidations[("field"+(i+1))] =
        [
            { type: "Required"},
            { 
                type: "LessThanNum",
                numValues: [settings.Fields[i].max+1],
                validationMessage: "The value must be less than "+settings.Fields[i].max+1
            },
            {
                type: "GreaterThanNum",
                numValues: [settings.Fields[i].min],
                validationMessage: "The value must be greater than "+settings.Fields[i].min
            },
        ]
    }
    setValidations(localValidations);
    // console.log(validations);
    
  }, []);
  
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    if(fieldName == "accepted"){
      if(!currentValue){
        let validationResponse = {
          hasError: true,
          errorMessage: "Must accept the Terms and Conditions.",
        }
        setErrors((errors) => ({ ...errors, ['accepted']: validationResponse }));
        return validationResponse;
      }
    }else{
      // console.log(validations);
      const value =
        currentValue && getDisplayValue
          ? getDisplayValue(currentValue)
          : currentValue;
      let validationResponse = validateField(value, validations[fieldName]);
      const customValidator = fetchByPath(onValidate, fieldName);
      if (customValidator) {
        validationResponse = await customValidator(value, validationResponse);
      }
      setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
      return validationResponse;
    }
    // console.log(validations);
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };


  return (
    <div>
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        var modelFields = {
          member_name,
          email,
          password,
          lat,
          lng,
          num_runs,
          gender,
          my_referred_code,
          field1,
          field2,
          field3,
          field4,
          field5,
          accepted
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        await onSubmit(modelFields);

      }}
      {...getOverrideProps(overrides, "DetailsComponent")}
      {...rest}
    >
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Email</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name,
              email: value,
              password,
              lat,
              lng,
              num_runs,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <PasswordField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Password</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        value={password}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name,
              email,
              password: value,
              lat,
              lng,
              num_runs,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.password ?? value;
          }
          if (errors.password?.hasError) {
            runValidationTasks("password", value);
          }
          setPassword(value);
        }}
        onBlur={() => runValidationTasks("password", password)}
        errorMessage={errors.password?.errorMessage}
        hasError={errors.password?.hasError}
        {...getOverrideProps(overrides, "password")}
      ></PasswordField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Name</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        value={member_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name: value,
              email,
              password,
              lat,
              lng,
              num_runs,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.member_name ?? value;
          }
          if (errors.member_name?.hasError) {
            runValidationTasks("member_name", value);
          }
          setMember_name(value);
        }}
        onBlur={() => runValidationTasks("member_name", member_name)}
        errorMessage={errors.member_name?.errorMessage}
        hasError={errors.member_name?.hasError}
        {...getOverrideProps(overrides, "member_name")}
      ></TextField>
      
      {/* <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Rough Location</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText={<Link onClick={()=>window.open("https://www.what3words.com/", "_blank")}>Please enter your What3Words rough location (www.what3words.com)</Link>} 
        isRequired={true}
        placeholder="///strike.shops.spirit"
        value={member_location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name,
              email,
              password,
              member_location: value,
              num_runs,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
            };
            const result = onChange(modelFields);
            value = result?.member_location ?? value;
          }
          if (errors.member_location?.hasError) {
            runValidationTasks("member_location", value);
          }
          setMember_location(value);
        }}
        onBlur={() => runValidationTasks("member_location", member_location)}
        errorMessage={(errors.member_location?.errorMessage ? errors.member_location?.errorMessage: "") + (props.w3wInvalid ? "What3Words was invalid" : "")}
        hasError={errors.member_location?.hasError || props.w3wInvalid}
        {...getOverrideProps(overrides, "member_location")}
      ></TextField> */}
      <Flex width={"100%"} alignItems={"center"} direction={"row"}>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Latitude</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        type="number"
        value={lat}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name: value,
              email,
              password,
              lat: value,
              lng,
              num_runs,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.lat ?? value;
          }
          if (errors.lat?.hasError) {
            runValidationTasks("lat", value);
          }
          setLat(parseFloat(value));
        }}
        onBlur={() => runValidationTasks("lat", lat)}
        errorMessage={errors.lat?.errorMessage}
        hasError={errors.lat?.hasError}
        {...getOverrideProps(overrides, "lat")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Longitude</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        type="number"
        value={lng}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name,
              email,
              password,
              lat,
              lng: value,
              num_runs,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.lng ?? value;
          }
          if (errors.lng?.hasError) {
            runValidationTasks("lng", value);
          }
          setLng(parseFloat(value));
        }}
        onBlur={() => runValidationTasks("lng", lng)}
        errorMessage={errors.lng?.errorMessage}
        hasError={errors.lng?.hasError}
        {...getOverrideProps(overrides, "lng")}
      ></TextField>
      <Flex paddingTop={25} onClick={() => {
        if ("geolocation" in navigator && (!showMap)) {
          navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            runValidationTasks("lng", position.coords.longitude);
            runValidationTasks("lat", position.coords.latitude);
            })
          }
        setShowMap(!showMap);
      }}>
      <IconContext.Provider value={{ color: settings.Theme.PrimaryColor, className: "global-class-name" }}>
      <CiLocationOn size={30} />
      </IconContext.Provider>
      </Flex>
      </Flex>
      {showMap && lat && lng ?
      <Flex width={"100%"} height={400}>
        <APIProvider apiKey={'AIzaSyA5xgE8KejRCuXqmulDDHkfX4ERZvsj0Qs'}>
          <Map
            key={0}
            mapId='6d6466716bfe5e47'
            defaultCenter={{lat: lat, lng: lng}}
            defaultZoom={14}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            onCameraChanged={ (ev) => {
              setLat(ev.detail.center.lat);
              setLng(ev.detail.center.lng);
            }}
          >
            <AdvancedMarker
              key={"center"}
              position={{ lat: lat, lng: lng }}>
            <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
            </AdvancedMarker>
            </Map>  
        </APIProvider>
      </Flex>
      :""
      }
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>
              How many events would you like to be matched with each week?
            </span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        placeholder="3"
        type="number"
        step="any"
        value={num_runs}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name,
              email,
              password,
              lat,
              lng,
              num_runs: value,
              gender,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.num_runs ?? value;
          }
          if (errors.num_runs?.hasError) {
            runValidationTasks("num_runs", value);
          }
          setNum_runs(value);
        }}
        onBlur={() => runValidationTasks("num_runs", num_runs)}
        errorMessage={errors.num_runs?.errorMessage}
        hasError={errors.num_runs?.hasError}
        {...getOverrideProps(overrides, "num_runs")}
      ></TextField>
      <SelectField
        label={
            <span style={{ display: "inline-flex" }}>
                <span>Gender</span>
                <span style={{ color: "red" }}>*</span>
            </span>
          }
        placeholder="Please select an option"
        value={gender}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              member_name,
              email,
              password,
              lat,
              lng,
              num_runs,
              gender: value,
              my_referred_code,
              field1,
              field2,
              field3,
              field4,
              field5,
              accepted
            };
            const result = onChange(modelFields);
            value = result?.gender ?? value;
          }
          if (errors.gender?.hasError) {
            runValidationTasks("gender", value);
          }
          setGender(value);
        }}
        onBlur={() => runValidationTasks("gender", gender)}
        errorMessage={errors.gender?.errorMessage}
        hasError={errors.gender?.hasError}
        {...getOverrideProps(overrides, "gender")}
      >
        <option
          children="Prefer not to say"
          value="Prefer not to say"
          {...getOverrideProps(overrides, "genderoption0")}
        ></option>
        <option
          children="Female"
          value="Female"
          {...getOverrideProps(overrides, "genderoption1")}
        ></option>
        <option
          children="Male"
          value="Male"
          {...getOverrideProps(overrides, "genderoption2")}
        ></option>
        <option
          children="Non-binary"
          value="Non-binary"
          {...getOverrideProps(overrides, "genderoption3")}
        ></option>
      </SelectField>
      {settings.Fields.map( (fi) => (
        // (fi.type === "multi") &&
            <SelectField
                label={
                    <span style={{ display: "inline-flex" }}>
                        <span>{fi.name}</span>
                        <span style={{ color: "red" }}>*</span>
                    </span>
                }
                key={fi.id}
                placeholder="Please select an option"
                value={fi.id===0 ? field1 : (fi.id===1 ? field2 : (fi.id===2 ? field3 : (fi.id===3 ? field4 : (fi.id===4 ? field5 : ""))))}
                onChange={(e) => {
                    let { value } = e.target;
                    // console.log("error: "+errors[fi.name]);
                    // console.log("name: "+fi.name)
                    if (errors[fi.name]?.hasError) {
                        runValidationTasks(fi.name, value);
                    }
                    // var tempFlexFields = flexFields;
                    // tempFlexFields[fi.id] = value;
                    if(fi.id===0){
                        setField1(value);
                    }
                    if(fi.id===1){
                        setField2(value);
                    }
                    if(fi.id===2){
                        setField3(value);
                    }
                    if(fi.id===3){
                        setField4(value);
                    }
                    if(fi.id===4){
                        setField5(value);
                    }
                }}
                onBlur={() => {
                        // console.log("Onblur: "+fi.fieldmap +" - "+((fi.id===0 ? field1 : (fi.id===1 ? field2 : (fi.id===2 ? field3 : (fi.id==3 ? field4 : (fi.id==4 ? field5 : "")))))));
                        runValidationTasks(fi.fieldmap, (fi.id===0 ? field1 : (fi.id===1 ? field2 : (fi.id===2 ? field3 : (fi.id===3 ? field4 : (fi.id===4 ? field5 : ""))))))}
                    }
                errorMessage={errors[fi.name]?.errorMessage}
                hasError={errors[fi.name]?.hasError}
            >
                 {fi.options.map(op => (
                    <option
                        children={op.name}
                        value={op.number}
                        key={op.number}
                    ></option>
                ))}
            </SelectField>
            
        )
      )}
      <TextField
            label={
              <span style={{ display: "inline-flex" }}>
                <span>Referral Code</span>
                {/* <span style={{ color: "red" }}>*</span> */}
              </span>
            }
            isRequired={false}
            value={my_referred_code}
            disabled={props.isreferral}
            onChange={(e) => {
              let { value } = e.target;
              if (onChange) {
                const modelFields = {
                  member_name,
                  email,
                  password,
                  lat,
                  lng,
                  num_runs,
                  gender,
                  my_referred_code: value,
                  field1,
                  field2,
                  field3,
                  field4,
                  field5,
                  accepted
                };
                const result = onChange(modelFields);
                value = result?.my_referred_code ?? value;
              }
              // if (errors.my_referred_code?.hasError) {
              //   runValidationTasks("my_referred_code", value);
              // }
              setmy_referred_code(value);
            }}
            // onBlur={() => runValidationTasks("my_referred_code", my_referred_code)}
            // errorMessage={errors.my_referred_code?.errorMessage}
            // hasError={errors.my_referred_code?.hasError}
            {...getOverrideProps(overrides, "my_referred_code")}
          ></TextField>
          <CheckboxField
            label={
              <span style={{ display: "inline-flex" }}>
                <span>I accept the Terms & Conditions</span>
                {/* <span style={{ color: "red" }}>*</span> */}
              </span>
            }
            name="termsandconditions"
            checked={accepted}
            value={true}
            hasError={errors.accepted?.hasError}
            onBlur={() => runValidationTasks("accepted", accepted)}
            errorMessage={errors.accepted?.errorMessage}
            onChange={(e) => {
              console.log(e.target.checked);
              if (onChange) {
                const modelFields = {
                  member_name,
                  email,
                  password,
                  num_runs,
                  gender,
                  my_referred_code,
                  field1,
                  field2,
                  field3,
                  field4,
                  field5,
                  accepted: e.target.checked,
                };
                const result = onChange(modelFields);
                // value = result?.accepted ?? e.target.checked;
              }
              if (errors.accepted?.hasError) {
                runValidationTasks("accepted", e.target.checked);
              }
              setAccepted(e.target.checked);
            }}
          ></CheckboxField>
      
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          {/* <HtmlTooltip title={props.membershiplevel ==="Full" ?
            <React.Fragment>
              <Typography color="inherit">Subscription</Typography>
              You will <b>{"not"}</b> be charged until you are matched with enough event!<br/>
              <em>{"You can cancel anytime."}</em><br/>
              You can also delay a subscription and trial the app first if you prefer. We will remind you before the end of your trial.
            </React.Fragment>
            :
            <React.Fragment>
              <Typography color="inherit">Subscription</Typography>
              You do <b>{"not"}</b> need a subscription for Basic Membership right now.<br/>
            </React.Fragment>
            } TransitionComponent={Zoom}> */}
            <Button
              children="Submit"
              type="submit"
              variation="primary"
              backgroundColor={settings.Theme.PrimaryColor}
              isDisabled={Object.values(errors).some((e) => e?.hasError)}
              {...getOverrideProps(overrides, "SubmitButton")}
            >{props.membershiplevel === "Full"?"Submit and subscribe" : "Submit"}
            </Button>
          {/* </HtmlTooltip> */}
          
          
        </Flex>
        
      </Flex>
      {props.mobiledesktop==="phone" ? 
            <div>
              <React.Fragment>
                <u>Subscription</u><br/>
                You will <b>{"not"}</b> be charged until you are matched with enough events.<br/>
                <em>{"You can cancel anytime."}</em><br/>
                You can also delay a subscription and trial the app first if you prefer. We will remind you before the end of your trial.
              </React.Fragment>
            </div>
            : ""}
      
    </Grid>

  </div>
  );
}
