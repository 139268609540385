/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function MyDetailsHeader(props) {
  const {
    imageURL,
    name,
    email,
    status,
    stat1title,
    stat2title,
    stat3title,
    stat1,
    stat2,
    stat3,
    date,
    time,
    pace,
    actionButtonSpot,
    nexteventstr = "Next run",
    field1str = "Pace",
    datestr = "Date",
    timestr = "Time",
    imageFrame,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "MyDetailsHeader")}
      {...rest}
    >
      <View
        width="350px"
        height="653px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 509")}
      >
        <View
          width="350px"
          height="25px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="0%"
          bottom="96.17%"
          left="0%"
          right="0%"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 444")}
        ></View>
        <View
          width="300px"
          height="628px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="3.83%"
          bottom="0%"
          left="7.14%"
          right="7.14%"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15000000596046448)"
          borderRadius="5px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Details Box")}
        >
          <View
            width="300px"
            height="262px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Header")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              label="MyEmail@tester.com"
              fontWeight="600"
              color="rgba(0,0,0,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="300px"
              height="16px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="150px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={email}
              {...getOverrideProps(overrides, "email")}
            ></Text>
            <Flex
              gap="10px"
              direction="column"
              width="300px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              position="absolute"
              top="190px"
              left="0px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 579")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
                borderRadius="32px"
                padding="8px 12px 8px 12px"
                backgroundColor="rgba(239,240,240,1)"
                {...getOverrideProps(overrides, "StatusBadge")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="14px"
                  label="MemberhsipStatus"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="14px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={status}
                  {...getOverrideProps(overrides, "status_text")}
                ></Text>
              </Flex>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              label="Charlie Jessop"
              fontWeight="700"
              color="rgba(0,0,0,1)"
              lineHeight="25px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="300px"
              height="27px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="103px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={name}
              {...getOverrideProps(overrides, "MyName")}
            ></Text>
          </View>
          <View
            width="300px"
            height="100px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="263px"
            left="0px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Frame 439")}
          >
            <View
              width="100px"
              height="100px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              position="absolute"
              top="0px"
              left="200px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "stat3")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                label="StatTitle"
                fontWeight="600"
                color="rgba(0,0,0,0.4)"
                lineHeight="14px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="68px"
                height="22px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="12px"
                left="calc(50% - 34px - 0px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={stat3title}
                {...getOverrideProps(overrides, "StatTitle39611659")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                label="Stat"
                fontWeight="600"
                color="rgba(0,0,0,0.7)"
                lineHeight="14px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="40px"
                height="32px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="50px"
                left="calc(50% - 20px - 0px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={stat3}
                {...getOverrideProps(overrides, "Stat39611660")}
              ></Text>
            </View>
            <View
              width="100px"
              height="100px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              position="absolute"
              top="0px"
              left="100px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "stat2")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                label="StatTitle"
                fontWeight="600"
                color="rgba(0,0,0,0.4)"
                lineHeight="14px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="68px"
                height="22px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="12px"
                left="calc(50% - 34px - 0px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={stat2title}
                {...getOverrideProps(overrides, "StatTitle39611656")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                label="Stat"
                fontWeight="600"
                color="rgba(0,0,0,0.7)"
                lineHeight="14px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="40px"
                height="32px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="50px"
                left="calc(50% - 20px - 0px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={stat2}
                {...getOverrideProps(overrides, "Stat39611657")}
              ></Text>
            </View>
            <View
              width="100px"
              height="100px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "stat1")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                label="StatTitle"
                fontWeight="600"
                color="rgba(0,0,0,0.4)"
                lineHeight="14px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="68px"
                height="22px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="12px"
                left="calc(50% - 34px - 0px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={stat1title}
                {...getOverrideProps(overrides, "StatTitle39611653")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                label="Stat"
                fontWeight="600"
                color="rgba(0,0,0,0.7)"
                lineHeight="14px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="40px"
                height="32px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="50px"
                left="calc(50% - 20px - 0px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={stat1}
                {...getOverrideProps(overrides, "Stat39611654")}
              ></Text>
            </View>
          </View>
          <View
            width="300px"
            height="238px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="362px"
            left="0px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Frame 438")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              label="2023-04-01"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="173px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="24.37%"
              bottom="65.55%"
              left="40%"
              right="2.33%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={date}
              {...getOverrideProps(overrides, "2023-04-01")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              label="08:00 - 09:00"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="173px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="42.44%"
              bottom="47.48%"
              left="40%"
              right="2.33%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={time}
              {...getOverrideProps(overrides, "08:00 - 09:00")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="600"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="76px"
              height="22px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="7.14%"
              bottom="83.61%"
              left="7.67%"
              right="67%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={nexteventstr}
              {...getOverrideProps(overrides, "Next Run")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              label="10kpm"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="60.08%"
              bottom="29.83%"
              left="40%"
              right="43%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={pace}
              {...getOverrideProps(overrides, "10kpm")}
            ></Text>
            <View
              width="113px"
              height="41px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              position="absolute"
              top="75.63%"
              bottom="7.14%"
              left="57%"
              right="5.33%"
              padding="0px 0px 0px 0px"
              children={actionButtonSpot}
              {...getOverrideProps(overrides, "ActionButtonSpot")}
            ></View>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="143.14px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="60.08%"
              bottom="29.83%"
              left="7.67%"
              right="44.62%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={field1str}
              {...getOverrideProps(overrides, "Pace:")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="143.14px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="24.37%"
              bottom="65.55%"
              left="7.67%"
              right="44.62%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={datestr}
              {...getOverrideProps(overrides, "Date:")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="143.14px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="42.44%"
              bottom="47.48%"
              left="7.67%"
              right="44.62%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={timestr}
              {...getOverrideProps(overrides, "Time:")}
            ></Text>
          </View>
        </View>
        <Icon
          width="300px"
          height="0px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 300,
            height: 0.050673625082708895,
          }}
          paths={[
            {
              d: "M0 0L300 0L300 -1L0 -1L0 0Z",
              stroke: "rgba(0,0,0,0.25)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="316px"
          left="25px"
          transformOrigin="top left"
          transform="rotate(0.01deg)"
          {...getOverrideProps(overrides, "Line 3")}
        ></Icon>
        <View
          width="100px"
          height="100px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="13px"
          left="125px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="0px 0px 0px 0px"
          children={imageFrame}
          {...getOverrideProps(overrides, "ImageFrame")}
        ></View>
        <Icon
          width="300px"
          height="0px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 300,
            height: 0.050673625082708895,
          }}
          paths={[
            {
              d: "M0 0L300 0L300 -1L0 -1L0 0Z",
              stroke: "rgba(0,0,0,0.25)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="416px"
          left="25px"
          transformOrigin="top left"
          transform="rotate(0.01deg)"
          {...getOverrideProps(overrides, "Line 2")}
        ></Icon>
      </View>
    </Flex>
  );
}
