import MobileSupport from '../ui-components/MobileSupport.jsx';
import Support from '../ui-components/Support.jsx';
import image1 from '../specific/images/PrimaryImage1.jpg';
import rdv from '../specific/images/LongLogoWhite.png';
import settings from '../specific/settings.json'

const SupportPage = (state) => {

    return (
        <div className="Terms" overflow="auto">
            {state.state.state==="desktop" ?
            <Support 
                width ={"100%"} 
                margin="auto" 
                widthProp={"100%"} 
                imagesrc={image1} 
                logosrc={rdv} 
                loginurl={"/Login"} 
                homeurl={"/"} 
                termsurl={"/Terms"} 
                supporturl={"/Support"} 
                whitelogosrc={rdv}
                titlestr={settings.AppName}
                buttoncolor = {settings.Theme.PrimaryColor}
                secondaryColor = {settings.Theme.LighterColor}
                darkButtonCOlor = {settings.Theme.DarkerColor}
                supporttextstr={settings.Screens.SupportPage.supporttextstr}
                faq1title = {settings.Screens.SupportPage.faq1title}
                faq1text = {settings.Screens.SupportPage.faq1text}
                faq2title = {settings.Screens.SupportPage.faq2title}
                faq2text = {settings.Screens.SupportPage.faq2text}
                faq3title = {settings.Screens.SupportPage.faq3title}
                faq3text = {settings.Screens.SupportPage.faq3text}
                faq4title = {settings.Screens.SupportPage.faq4title}
                faq4text = {settings.Screens.SupportPage.faq4text}
                faq5title = {settings.Screens.SupportPage.faq5title}
                faq5text = {settings.Screens.SupportPage.faq5text}
                faq6title = {settings.Screens.SupportPage.faq6title}
                faq6text = {settings.Screens.SupportPage.faq6text}
                faq7title = {settings.Screens.SupportPage.faq7title}
                faq7text = {settings.Screens.SupportPage.faq7text}
                faq8title = {settings.Screens.SupportPage.faq8title}
                faq8text = {settings.Screens.SupportPage.faq8text}
                faq9title = {settings.Screens.SupportPage.faq9title}
                faq9text = {settings.Screens.SupportPage.faq9text}
                faq10title = {settings.Screens.SupportPage.faq10title}
                faq10text = {settings.Screens.SupportPage.faq10text}
                faq11title = {settings.Screens.SupportPage.faq11title}
                faq11text = {settings.Screens.SupportPage.faq11text}
                faq12title = {settings.Screens.SupportPage.faq12title}
                faq12text = {settings.Screens.SupportPage.faq12text}
                faq13title = {settings.Screens.SupportPage.faq13title}
                faq13text = {settings.Screens.SupportPage.faq13text}
                copyrighttextstr = {settings.Screens.SupportPage.copyrighttextstr}
                />
            :
            <MobileSupport width ={"100%"} margin="auto" widthProp={"100%"} imagesrc={image1} logosrc={rdv} loginurl={"/Login"} homeurl={"/"} termsurl={"/Terms"} supporturl={"/Support"} whitelogosrc={rdv}/>
            }
        </div>
    );
}

export default SupportPage;