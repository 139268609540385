/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, SelectField } from "@aws-amplify/ui-react";
export default function ScheduleItem(props) {
  const {
    bhSelect,
    ehSelect,
    deleteAction,
    bhChangeHandler,
    ehChangeHandler,
    background = "white",
    updateHandler,
    buttoncolor = "#9D28E2",
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="10px 10px 10px 10px"
      {...getOverrideProps(overrides, "ScheduleItem")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="15px"
        padding="10px 10px 10px 10px"
        backgroundColor={background}
        {...getOverrideProps(overrides, "Frame 446")}
      >
        <SelectField
          width="100px"
          height="unset"
          placeholder="Start"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          children={bhSelect}
          onChange={bhChangeHandler}
          {...getOverrideProps(overrides, "StartTimeField")}
        ></SelectField>
        <SelectField
          width="100px"
          height="unset"
          placeholder="End"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          children={ehSelect}
          onChange={ehChangeHandler}
          {...getOverrideProps(overrides, "EndTimeField")}
        ></SelectField>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="primary"
          backgroundColor={buttoncolor}
          children="Update"
          onClick={updateHandler}
          {...getOverrideProps(overrides, "UpdateButton")}
        ></Button>
      </Flex>
    </Flex>
  );
}
