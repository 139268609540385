import { get } from 'aws-amplify/api';

async function getEvent(pk) {
    try{
        var result = get({apiName: 'membereventsapi', path: '/event/' + pk, options: {}})
        const { body } = await result.response;
        const json = await body.json();
        const parsed = JSON.parse(json.body);
        return parsed;
    }catch (e){
        console.log('GET event call failed: ', JSON.parse(e.response.body));
        return;
    }
}


export default getEvent;