/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex } from "@aws-amplify/ui-react";
export default function DesktopLayout(props) {
  const { mainContent, rightBar, leftBar, navBar, overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="1500px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(250,250,250,1)"
      {...getOverrideProps(overrides, "DesktopLayout")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(250,250,250,1)"
        children={navBar}
        {...getOverrideProps(overrides, "NavBar")}
      ></Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 581")}
      >
        <Flex
          gap="0"
          direction="column"
          width="350px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(250,250,250,1)"
          children={leftBar}
          flex=""
          {...getOverrideProps(overrides, "LeftBar")}
        ></Flex>
        <Flex
          gap="0"
          direction="column"
          width="650px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(250,250,250,1)"
          children={mainContent}
          {...getOverrideProps(overrides, "MainContent")}
        ></Flex>
        <Flex
          gap="0"
          direction="column"
          width="200px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(250,250,250,1)"
          children={rightBar}
          {...getOverrideProps(overrides, "RightBar")}
        ></Flex>
      </Flex>
    </Flex>
  );
}
