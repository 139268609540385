import GenericNavbar from '../ui-components/GenericNavbar.jsx';
import logo from '../specific/images/LongLogoColor.png';
import LogoutIcon from '../resources/logout.png';
import settings from '../specific/settings.json'


export default function Navbar() {

  return (
    <GenericNavbar 
                      width={"100%"}
                      widthProp={"100%"}
                      logosrc={logo} 
                      logoutsrc={LogoutIcon} 
                      link1title="Events" 
                      link2title="Details" 
                      link3title="Schedule" 
                      link4title="Subscription" 
                      link1click={"/RunList"}
                      link2click={"/MyDetails"}
                      link3click={"/Schedule"}
                      link4click={"/Subscribe"}
                      titlestr={"Rundezvous"}
                      innerWidthProp={"1200px"}
                      hoverColor={settings.Theme.PrimaryColor}/>
  )
}
