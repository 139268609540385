/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Safeguarding(props) {
  const {
    logosrc,
    image2src,
    loginurl,
    homeurl,
    termsurl,
    supporturl,
    widthProp = '"100%"',
    subwidthprop = '"1080px"',
    buttoncolor = "#9D28E2",
    darkButtonCOlor = "#54127D",
    safeguardingtitle = "Safeguarding",
    safeguardingprinciplesstr = "Our Safeguarding Policy is based on the following key principles  - Everyone should have the ability to enjoy running socially without fear of abuse and in a safe manner.  - Any allegations of abuse will be treated sensitively and seriously, and be responded to swiftly and appropriately.  - Confidentiality will be appropriately maintained, but the safety and welfare of the individuals will be the overriding consideration when making decisions on whether to share information. - The service is for adults only, the definition of safeguarding is accepted as defined by the Social Care Institute for Excellence.",
    secondaryColor = "#DFBCF5",
    copyrighttextstr = "\u00A9 2024 Rundezvous Ltd. All rights reserved.",
    titlestr = "Rundezvous",
    supportemailstr = "support@rundezvous.io",
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        "long+logo+overlapped+(1) 143891231": {},
        "Frame 517": {},
        Button: {},
        "Frame 321": {},
        Header: {},
        Eyebrow: {},
        Heading: {},
        Message: {},
        HeroMessage: {},
        Left: {},
        image: {},
        Right: {},
        What: {},
        "Safeguarding Policy": {},
        "Frame 432": {},
        "Frame 138": {},
        "Safeguarding Principles": {},
        "Our Safeguarding Policy is based on the following key principles: Everyone should have the ability to enjoy the use of this service without fear of abuse and in a safe manner. Any allegations of abuse will be treated sensitively and seriously, and be responded to swiftly and appropriately. Confidentiality will be appropriately maintained, but the safety and welfare of the individual/s will be the overriding consideration when making decisions on whether to share information. The service is for adults only, the definition of safeguarding is accepted as defined by the Social Care Institute for Excellence.":
          {},
        "Frame 538": {},
        "Response and Reporting": {},
        "Emergency Situation Should you feel that anyone at an event may be at immediate risk of harm, you should: Call the police (or your local equivalent) and follow their advice Take reasonable steps to ensure the individual at risk is safeguarded Do not take actions that would put your own safety at risk Once the incident is under control, ensure it is reported to the Safeguarding Team with subject line \u201CSafeguarding Emergency\u201D at the address below. We will liaise with and support the emergency services as required. Non-Emergency Situation If you have any non-emergency/non-urgent concerns of a safeguarding nature, you must report it to the Safeguarding Team \u2013 subject line \u201CSafeguarding Issue\u201D at the email below. Records may be passed to relevant external agencies, such as local law enforcement agencies or social services.":
          {},
        "Frame 531": {},
        "Confidentiality and Information Sharing": {},
        "The Safeguarding Team will then make decisions as to any further individuals or organisations who should be made aware. Part of this safeguarding decision-making process will include consideration about who to share information with. This could include: Statutory organisations such as your country\u2019s law enforcement who must be informed about child protection concerns Internally we only ever share safeguarding information on a strictly-governed need-to-know basis Our policy is that only those that need to know are told. This means only those individuals stated within an appropriate reporting structure, who need to know, and no one else unless directed by statutory agencies. If we request that information is kept confidential, it is essential that this is adhered to, as failure to do so could significantly increase the risk to the welfare of vulnerable individuals, and may lead to disciplinary action or being stepped down from a role.":
          {},
        "Frame 532": {},
        "Missing person at a an event procedure": {},
        "If you have concerns about someone else at one of your events, you should in the first instance contact your local law enforcement. Then you should also inform the Safeguarding Team at the email below that you have done so, so that we can liaise with them as required. Please use subject line \u201CSafeguarding Report\u201D. The Safeguarding Team will then share any necessary information with law enforcement that may support their investigation.":
          {},
        "Frame 533": {},
        "Bullying and Harassment": {},
        "Procedure for dealing with bullying or harassment Anyone who feels that they are being subjected to bullying or harassment may attempt to resolve the matter informally in the first instance. In some cases, it may be possible and sufficient for them to explain clearly to the person(s) engaged in the unwanted activities that the behaviour is unwelcome, that it offends or makes them uncomfortable. Anyone who feels that they are being bullied or harassed should keep a record of any incidents, detailing when, where, what occurred, and witnesses (if any). In some cases, victims of bullying or harassment may not be sufficiently confident to tell the harasser that their behaviour is unacceptable. We emphasise therefore that you\u00A0are not required\u00A0to approach the harasser in an attempt to resolve the problem informally, and\u00A0are entitled to\u00A0report the matter to the Safeguarding Team the email below (with subject line Safeguarding Issue). The Safeguarding Team will then discuss the matter with the complainant and agree on a course of action. The alleged harasser will also have the right to state their version of events. Action taken might include: Mediation of the issue between the two parties. Modification of the system to prevent runs being scheduled between the two parties in the future. If sufficiently serious and it is felt that the harasser poses a risk of repeat offences to other members, this could include the exclusion of the alleged harasser from future events. If it is agreed that the bullying or harassment has reached a criminal level, the Safeguarding Team will recommend reporting it to a law enforcement agency and will support an individual in doing so if appropriate.":
          {},
        "Frame 534": {},
        "Safeguarding Team Contact": {},
        "support@rundezvous.io": {},
        "Frame 539": {},
        "Frame 390": {},
        "Frame 518": {},
        "Frame 63": {},
        Terms43891250: {},
        Rundezvous: {},
        "Frame 434": {},
        "Frame 313": {},
        Navigation: {},
        Home: {},
        Terms43891304: {},
        Terms43891305: {},
        Support: {},
        "Frame 403": {},
        "Frame 405": {},
        "long+logo+overlapped+(1) 143891309": {},
        LogoWithText: {},
        "\u00A9 2024 Rundezvous Ltd. All rights reserved.": {},
        "Frame 433": {},
        Footer: {},
        Safeguarding: {},
      },
      variantValues: {},
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const longPluslogoPlusoverlappedPlusOpenParenthesisOneCloseParenthesisOneFourThreeEightNineOneTwoThreeOneOnClick =
    useNavigateAction({ type: "url", url: homeurl });
  const buttonOnClick = useNavigateAction({ type: "url", url: loginurl });
  const homeOnClick = useNavigateAction({ type: "url", url: homeurl });
  const termsFourThreeEightNineOneThreeZeroFourOnClick = useNavigateAction({
    type: "url",
    url: termsurl,
  });
  const termsFourThreeEightNineOneThreeZeroFiveOnClick = useNavigateAction({
    type: "url",
    url: loginurl,
  });
  const supportOnClick = useNavigateAction({ type: "url", url: supporturl });
  return (
    <Flex
      gap="0"
      direction="column"
      width={widthProp}
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Safeguarding")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
        padding="10px 120px 10px 120px"
        backgroundColor={buttoncolor}
        display="flex"
        {...getOverrideProps(overrides, "Header")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 517")}
        >
          <Image
            width="186.03px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={logosrc}
            onClick={() => {
              longPluslogoPlusoverlappedPlusOpenParenthesisOneCloseParenthesisOneFourThreeEightNineOneTwoThreeOneOnClick();
            }}
            {...getOverrideProps(
              overrides,
              "long+logo+overlapped+(1) 143891231"
            )}
          ></Image>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 321")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor={darkButtonCOlor}
            size="large"
            isDisabled={false}
            variation="primary"
            children="Sign in"
            onClick={() => {
              buttonOnClick();
            }}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "What")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="300px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="120px 120px 120px 120px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage")}
          >
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(177,85,231,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What is Rundezvous"
                {...getOverrideProps(overrides, "Eyebrow")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="36px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="50px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={safeguardingtitle}
                {...getOverrideProps(overrides, "Heading")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="300px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 20px 0px 20px"
          display="flex"
          {...getOverrideProps(overrides, "Right")}
        >
          <Image
            width="720px"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            borderRadius="5px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={image2src}
            {...getOverrideProps(overrides, "image")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="48px 48px 48px 48px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        textAlign="centre"
        {...getOverrideProps(overrides, "Terms43891250")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 432")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="32px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Safeguarding Policy"
            {...getOverrideProps(overrides, "Safeguarding Policy")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="center"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,0)"
          display="flex"
          maxWidth={800}
          {...getOverrideProps(overrides, "Frame 63")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 518")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 390")}
            >
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 138")}
              ></Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 538")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Safeguarding Principles"
                  {...getOverrideProps(overrides, "Safeguarding Principles")}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={safeguardingprinciplesstr}
                  {...getOverrideProps(
                    overrides,
                    "Our Safeguarding Policy is based on the following key principles: Everyone should have the ability to enjoy the use of this service without fear of abuse and in a safe manner. Any allegations of abuse will be treated sensitively and seriously, and be responded to swiftly and appropriately. Confidentiality will be appropriately maintained, but the safety and welfare of the individual/s will be the overriding consideration when making decisions on whether to share information. The service is for adults only, the definition of safeguarding is accepted as defined by the Social Care Institute for Excellence."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 531")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Response and Reporting"
                  {...getOverrideProps(overrides, "Response and Reporting")}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Emergency Situation&#xA;&#xA;Should you feel that anyone at an event may be at immediate risk of harm, you should:&#xA;&#xA;Call the police (or your local equivalent) and follow their advice&#xA;Take reasonable steps to ensure the individual at risk is safeguarded&#xA;Do not take actions that would put your own safety at risk&#xA;Once the incident is under control, ensure it is reported to the Safeguarding Team with subject line “Safeguarding Emergency” at the address below.&#xA;We will liaise with and support the emergency services as required.&#xA;&#xA;Non-Emergency Situation&#xA;&#xA;If you have any non-emergency/non-urgent concerns of a safeguarding nature, you must report it to the Safeguarding Team – subject line “Safeguarding Issue” at the email below.&#xA;Records may be passed to relevant external agencies, such as local law enforcement agencies or social services."
                  {...getOverrideProps(
                    overrides,
                    "Emergency Situation Should you feel that anyone at an event may be at immediate risk of harm, you should: Call the police (or your local equivalent) and follow their advice Take reasonable steps to ensure the individual at risk is safeguarded Do not take actions that would put your own safety at risk Once the incident is under control, ensure it is reported to the Safeguarding Team with subject line \u201CSafeguarding Emergency\u201D at the address below. We will liaise with and support the emergency services as required. Non-Emergency Situation If you have any non-emergency/non-urgent concerns of a safeguarding nature, you must report it to the Safeguarding Team \u2013 subject line \u201CSafeguarding Issue\u201D at the email below. Records may be passed to relevant external agencies, such as local law enforcement agencies or social services."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 532")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Confidentiality and Information Sharing"
                  {...getOverrideProps(
                    overrides,
                    "Confidentiality and Information Sharing"
                  )}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="The Safeguarding Team will then make decisions as to any further individuals or organisations who should be made aware. Part of this safeguarding decision-making process will include consideration about who to share information with. This could include:&#xA;&#xA;Statutory organisations such as your country’s law enforcement who must be informed about child protection concerns&#xA;Internally we only ever share safeguarding information on a strictly-governed need-to-know basis&#xA;&#xA;Our policy is that only those that need to know are told. This means only those individuals stated within an appropriate reporting structure, who need to know, and no one else unless directed by statutory agencies.&#xA;&#xA;If we request that information is kept confidential, it is essential that this is adhered to, as failure to do so could significantly increase the risk to the welfare of vulnerable individuals, and may lead to disciplinary action or being stepped down from a role."
                  {...getOverrideProps(
                    overrides,
                    "The Safeguarding Team will then make decisions as to any further individuals or organisations who should be made aware. Part of this safeguarding decision-making process will include consideration about who to share information with. This could include: Statutory organisations such as your country\u2019s law enforcement who must be informed about child protection concerns Internally we only ever share safeguarding information on a strictly-governed need-to-know basis Our policy is that only those that need to know are told. This means only those individuals stated within an appropriate reporting structure, who need to know, and no one else unless directed by statutory agencies. If we request that information is kept confidential, it is essential that this is adhered to, as failure to do so could significantly increase the risk to the welfare of vulnerable individuals, and may lead to disciplinary action or being stepped down from a role."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 533")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Missing person at a an event procedure"
                  {...getOverrideProps(
                    overrides,
                    "Missing person at a an event procedure"
                  )}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="If you have concerns about someone else at one of your events, you should in the first instance contact your local law enforcement.&#xA;Then you should also inform the Safeguarding Team at the email below that you have done so, so that we can liaise with them as required. Please use subject line “Safeguarding Report”.&#xA;The Safeguarding Team will then share any necessary information with law enforcement      that may support their investigation."
                  {...getOverrideProps(
                    overrides,
                    "If you have concerns about someone else at one of your events, you should in the first instance contact your local law enforcement. Then you should also inform the Safeguarding Team at the email below that you have done so, so that we can liaise with them as required. Please use subject line \u201CSafeguarding Report\u201D. The Safeguarding Team will then share any necessary information with law enforcement that may support their investigation."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 534")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Bullying and Harassment"
                  {...getOverrideProps(overrides, "Bullying and Harassment")}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Procedure for dealing with bullying or harassment&#xA;&#xA;Anyone who feels that they are being subjected to bullying or harassment may attempt to resolve the matter informally in the first instance. In some cases, it may be possible and sufficient for them to explain clearly to the person(s) engaged in the unwanted activities that the behaviour is unwelcome, that it offends or makes them uncomfortable.&#xA;&#xA;Anyone who feels that they are being bullied or harassed should keep a record of any incidents, detailing when, where, what occurred, and witnesses (if any).&#xA;&#xA;In some cases, victims of bullying or harassment may not be sufficiently confident to tell the harasser that their behaviour is unacceptable. We emphasise therefore that you are not required to approach the harasser in an attempt to resolve the problem informally, and are entitled to report the matter to the Safeguarding Team the email below (with subject line Safeguarding Issue).&#xA;&#xA;The Safeguarding Team will then discuss the matter with the complainant and agree on a course of action. The alleged harasser will also have the right to state their version of events. &#xA;&#xA;Action taken might include:&#xA;&#xA;Mediation of the issue between the two parties.&#xA;Modification of the system to prevent runs being scheduled between the two parties in the future.&#xA;If sufficiently serious and it is felt that the harasser poses a risk of repeat offences to other members, this could include the exclusion of the alleged harasser from future events.&#xA;&#xA;If it is agreed that the bullying or harassment has reached a criminal level, the Safeguarding Team will recommend reporting it to a law enforcement agency and will support an individual in doing so if appropriate."
                  {...getOverrideProps(
                    overrides,
                    "Procedure for dealing with bullying or harassment Anyone who feels that they are being subjected to bullying or harassment may attempt to resolve the matter informally in the first instance. In some cases, it may be possible and sufficient for them to explain clearly to the person(s) engaged in the unwanted activities that the behaviour is unwelcome, that it offends or makes them uncomfortable. Anyone who feels that they are being bullied or harassed should keep a record of any incidents, detailing when, where, what occurred, and witnesses (if any). In some cases, victims of bullying or harassment may not be sufficiently confident to tell the harasser that their behaviour is unacceptable. We emphasise therefore that you\u00A0are not required\u00A0to approach the harasser in an attempt to resolve the problem informally, and\u00A0are entitled to\u00A0report the matter to the Safeguarding Team the email below (with subject line Safeguarding Issue). The Safeguarding Team will then discuss the matter with the complainant and agree on a course of action. The alleged harasser will also have the right to state their version of events. Action taken might include: Mediation of the issue between the two parties. Modification of the system to prevent runs being scheduled between the two parties in the future. If sufficiently serious and it is felt that the harasser poses a risk of repeat offences to other members, this could include the exclusion of the alleged harasser from future events. If it is agreed that the bullying or harassment has reached a criminal level, the Safeguarding Team will recommend reporting it to a law enforcement agency and will support an individual in doing so if appropriate."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 539")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Safeguarding Team Contact"
                  {...getOverrideProps(overrides, "Safeguarding Team Contact")}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={supportemailstr}
                  {...getOverrideProps(overrides, "support@rundezvous.io")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="40px 40px 40px 40px"
        backgroundColor={secondaryColor}
        display="flex"
        {...getOverrideProps(overrides, "Footer")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 313")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 434")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="471px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={titlestr}
              {...getOverrideProps(overrides, "Rundezvous")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 405")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 403")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Navigation"
              {...getOverrideProps(overrides, "Navigation")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color={buttoncolor}
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Home"
              onClick={() => {
                homeOnClick();
              }}
              {...getOverrideProps(overrides, "Home")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color={buttoncolor}
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms"
              onClick={() => {
                termsFourThreeEightNineOneThreeZeroFourOnClick();
              }}
              {...getOverrideProps(overrides, "Terms43891304")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color={buttoncolor}
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Login"
              onClick={() => {
                termsFourThreeEightNineOneThreeZeroFiveOnClick();
              }}
              {...getOverrideProps(overrides, "Terms43891305")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color={buttoncolor}
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Support"
              onClick={() => {
                supportOnClick();
              }}
              {...getOverrideProps(overrides, "Support")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 433")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "LogoWithText")}
          >
            <Image
              width="197px"
              height="39px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={logosrc}
              {...getOverrideProps(
                overrides,
                "long+logo+overlapped+(1) 143891309"
              )}
            ></Image>
          </Flex>
          <Text
            //fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(102,112,133,1)"
            lineHeight="24px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={copyrighttextstr}
            {...getOverrideProps(
              overrides,
              "\u00A9 2024 Rundezvous Ltd. All rights reserved."
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
