import Navbar from '../components/Navbar.js';
import awsconfig from '../aws-exports.js';
import { Flex, Card, useTheme, Image, Button, Text } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
// import useMyFetch from '../utils/useMyFetch.js'
import getMember from '../utils/getMember.js';
import getAttendance from '../utils/getAttendance.js';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MyDetailsHeader from '../ui-components/MyDetailsHeader.jsx';
import FormHolder from '../ui-components/FormHolder.jsx';
import MobileSubscription from '../ui-components/MobileSubscription.jsx';
import NewSubscriptionPage from '../ui-components/NewSubscriptionPage.jsx';
import SubscriptionDetails from '../ui-components/SubscriptionDetails.jsx';
import MobileLayout from '../ui-components/MobileLayout.jsx';
import RDVLogo from '../specific/images/Logo.png';
import logo from '../specific/images/LongLogoColor.png';
import MenuIcon from '../resources/menu.png';
import MobileHeaderBar from '../ui-components/MobileHeaderBar.jsx';
import MobileSideBar from '../ui-components/MobileSideBar.jsx';
import LogoutIcon from '../resources/logout.png';
import { useState, useEffect } from 'react';
import dateFormat from 'dateformat';
import { getCurrentUser } from 'aws-amplify/auth';
import settings from '../specific/settings.json'
import { list } from 'aws-amplify/storage';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import { useNavigate } from 'react-router';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  EmailIcon
} from "react-share";
import appstoreblack from '../resources/appstoreblack.svg';
import playstoreblack from '../resources/google-play-badge.png';


Amplify.configure(awsconfig);

async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails.loginId}`);
    console.log(signInDetails);
    return signInDetails.loginId;
  } catch (err) {
    console.log(err);
  }
}

const Subscribe = (state) => {
    // const { data: member, error, isPending } = useMyFetch('member', false);
    // const { data: ats } = useMyFetch('attendance', false);
    const error = false;
    const isPending = false;
    const [member, setMember] = useState({});
    const [ats, setAts] = useState([]);
    const {tokens} = useTheme();
    const [menu, setMenu] = useState(false);
    const [memberImage, setMemberImage] = useState({});
    const [subLink, setSubLink] = useState("");
    const navigate = useNavigate();
    const getCurrencySymbol = (code) => {
      if (code === 'gbp'){
        return "£";
      } else if (code === 'eur'){
        return "€";
      }else if (code === 'usd'){
        return "$";
      }else{
        return "";
      }
    }

    useEffect(() => {
      if (window.location.host === "www.rundezvous.io") {
        setSubLink("https://buy.stripe.com/9AQ9DWecofzYcIU3cc");
      } else {
        setSubLink("https://buy.stripe.com/test_6oEdUh8Uj8h38dqbII");
      }
      (async function () {
          const tempEmail = await currentAuthenticatedUser();
          // console.log("tempemail: "+tempEmail);
          var tempMember = await getMember(tempEmail);
          setMember(await tempMember);
          var tempAttendance = await getAttendance(tempEmail);
          // console.log("attendance: ")
          console.log(await tempAttendance);
          setAts(tempAttendance);
          // console.log(member.member_name);
          if(tempMember !== undefined){
            const path = "members/"+tempMember.country+"/"+tempMember.pk_hash+".png";
            const imsResult = await list({prefix:path, options:{level: 'public'}});
            // console.log(imsResult);
            if(imsResult.items.length>0) {
                setMemberImage(imsResult.items[0].key);
                // console.log("Set the image")
            }
          } else{
            navigate('/NewMember');
          }
          
      })();
      // getMember(currentAuthenticatedUser());
  },[]);

  return (
    <div className="Subscription">
        <div className="content">
          <Flex backgroundColor={tokens.colors.background.primary} width="100%">
          {(state.state.state==="desktop" ?
            (<DesktopLayout 
                display="block" 
                width="100%"

                navBar=<Navbar /> 
                mainContent= <div>                    
                {member && (member.member_status === "paid" || member.member_status === "stripe_trial") && 
                <>
                  <FormHolder innerContent=
                    <MobileSubscription manageSubscriptionHandler={() =>{
                        if(member.subscription_platform === "ANDROID"){
                          window.open("https://play.google.com/store/account/subscriptions", "_blank");
                        }else if (member.subscription_platform === "APPLE"){
                          window.open("https://www.apple.com/app-store/", "_blank");
                        } else{
                          if (window.location.host === "www.rundezvous.io") {
                            window.open("https://billing.stripe.com/p/login/eVabJqeE058l6M88ww", "_blank");
                          } else{
                            window.open("https://billing.stripe.com/p/login/test_3cs00h4X24759UYeUU", "_blank");
                          }
                        }
                        
                      }}
                      shareReferralCodeHandler={() => {navigator.clipboard.writeText("www.rundezvous.io/referral/"+member.my_referral_code)}}
                      subscriptionStatus={member.subscription_platform === "STRIPE" ? member.subscription_status.charAt(0).toUpperCase() + member.subscription_status.slice(1) : member.member_status==="paid" ? "Subscribed via "+member.subscription_platform.toLowerCase(): "ANDROID"}
                      dateActivated={member.date_activated === "" ? "Trialing/Inactive" : dateFormat(new Date(member.date_activated), "yyyy-mm-dd")}
                      nextPaymentDate={member.next_payment_date === "" ? member['customerInfo']['entitlements']['active']['Full Membership']['latestPurchaseDate'] : dateFormat(new Date(member.next_payment_date), "yyyy-mm-dd")}
                      nextPaymentAmount={getCurrencySymbol(member.currency)+member.next_payment_amount}
                      referralCode={member.my_referral_code}
                      referrals={member.referrals}
                      pendingReferrals={member.pending_referrals}
                      discountStatus={member.discount_active ? "Active" : "Inactive"}
                      discountEnd={member.discount_until ===""? "" : dateFormat(new Date(member.discount_until), "yyyy-mm-dd")}
                      discountPercentage={member.discount_active? (member.discount_percentage)+"%":""}
                      managelabel={"Manage subscription"}
                      copycodelabel={"Copy referral link"}
                      width="100%"
                      shareCodesArea=
                      <div>
                        <WhatsappShareButton title={"Join me on Rundezvous!"} children={<WhatsappIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                        <FacebookShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                        <EmailShareButton subject={"Join me on Rundezvous!"} body={"Come and join me on Rundezvous and we can run together!"} separator=' : ' children={<EmailIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/>
                        {/* <FacebookMessengerShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookMessengerIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/> */}
                      </div>
                      mobileApps=<div>
                        <Image src={appstoreblack} width={100} marginRight={20} onClick={()=>window.open(settings.URLs.AppStore, "_blank")}/>
                        <Image src={playstoreblack} width={112} paddingTop={20}  onClick={()=>window.open(settings.URLs.PlayStore, "_blank")}/>
                      </div>

                    />
                  
                    
                  />
                  </>
                }
                {member && (member.member_status === "basic" || member.member_status === "initial_trial") && 
                  <NewSubscriptionPage   
                    width={650}
                    widthProp={650}
                    logosrc={RDVLogo}
                    coststr={"£4.00pcm"}
                    subscribestr={"Subscribe"}
                    subscribetitlestr={"Subscribe to Rundezvous"}
                    subscribelink={subLink}

                    buttoncolor={settings.Theme.PrimaryColor}
                  />
                }</div>
                leftBar={member && ats && <MyDetailsHeader imageFrame={<StorageImage alt="Member" imgKey={memberImage} accessLevel="guest" />} 
                                        name={member.member_name} 
                                        email={member.email} 
                                        status={member.member_status === "initial_trial" && member.discount_until !== "" ? "Free Trial until "+(new Date(member.discount_until.slice(0, 10))).toLocaleDateString() : (member.member_status === "basic" ? "Unsubscribed User" : (member.member_status === "paid" ? "Full Member" : (member.member_status === "stripe_trial"? "Subscribed Trial Period" : "")))} 
                                        stat1title="Scheduled"
                                        stat2title="Confirm"
                                        stat3title="Completed"
                                        stat1={member.upcoming_events}
                                        stat2={member.confirmation_events}
                                        stat3={member.completed_events}
                                        date={ats.length > 0 ? (new Date(ats[0].event.date_time.slice(0, 10))).toLocaleDateString()  : ""}
                                        time={ats.length > 0 ? ats[0].event.date_time.slice(11, 16) : ""}
                                        pace={ats.length > 0 ? (Math.round(ats[0].event.pace * 10) / 10) + "kph" : ""}
                                        />}
          />)
          :
          (<MobileLayout width="100%"
                            mobileHeader=<MobileHeaderBar width="100%"
                                logoURL={logo}
                                burgerURL={MenuIcon}
                                navigateHomeURL={"/HomePage"}
                                burgerHandler={() => {
                                    setMenu(!menu);
                                    console.log("burger!")
                                }} />
                            firstContent=<Flex width="100%">    
                              {isPending && <Card>Loading...</Card>}
                              {error && <Card>{error}</Card>}                
                              {!menu && member && (member.member_status === "paid" || member.member_status === "stripe_trial") && 
                                  <MobileSubscription manageSubscriptionHandler={() =>{
                                    if(member.subscription_platform === "ANDROID"){
                                      window.open("https://play.google.com/store/account/subscriptions", "_blank");
                                    }else if (member.subscription_platform === "APPLE"){
                                      window.open("https://www.apple.com/app-store/", "_blank");
                                    } else{
                                      if (window.location.host === "www.rundezvous.io") {
                                        window.open("https://billing.stripe.com/p/login/eVabJqeE058l6M88ww", "_blank");
                                      } else{
                                        window.open("https://billing.stripe.com/p/login/test_3cs00h4X24759UYeUU", "_blank");
                                      }
                                    }
                                    
                                  }}
                                  shareReferralCodeHandler={() => {navigator.clipboard.writeText("www.rundezvous.io/referral/"+member.my_referral_code)}}
                                  subscriptionStatus={member.subscription_platform === "STRIPE" ? member.subscription_status.charAt(0).toUpperCase() + member.subscription_status.slice(1) : member.member_status==="paid" ? "Subscribed via "+member.subscription_platform.toLowerCase(): "ANDROID"}
                                  dateActivated={member.date_activated === "" ? "Trialing/Inactive" : dateFormat(new Date(member.date_activated), "yyyy-mm-dd")}
                                  nextPaymentDate={member.next_payment_date === "" ? member['customerInfo']['entitlements']['active']['Full Membership']['latestPurchaseDate'] : dateFormat(new Date(member.next_payment_date), "yyyy-mm-dd")}
                                  nextPaymentAmount={getCurrencySymbol(member.currency)+member.next_payment_amount}
                                  referralCode={member.my_referral_code}
                                  referrals={member.referrals}
                                  pendingReferrals={member.pending_referrals}
                                  discountStatus={member.discount_active ? "Active" : "Inactive"}
                                  discountEnd={member.discount_until ===""? "" : dateFormat(new Date(member.discount_until), "yyyy-mm-dd")}
                                  discountPercentage={member.discount_active? (member.discount_percentage)+"%":""}
                                  managelabel={"Manage subscription"}
                                  copycodelabel={"Copy referral link"}
                                  width="100%"
                                  shareCodesArea=
                                  <div>
                                    <WhatsappShareButton title={"Join me on Rundezvous!"} children={<WhatsappIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                                    <FacebookShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookIcon/>} url={settings.Domain+"referral/"+member.my_referral_code}/>
                                    <EmailShareButton subject={"Join me on Rundezvous!"} body={"Come and join me on Rundezvous and we can run together!"} separator=' : ' children={<EmailIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/>
                                    {/* <FacebookMessengerShareButton title={"Join me on Rundezvous!"} hashtag={"#Rundezvous"} children={<FacebookMessengerIcon/>} url={"www.rundezvous.io/referral/"+member.my_referral_code}/> */}
                                  </div>
                                  mobileApps=<div>
                                    <Image src={appstoreblack} width={100} marginRight={20} onClick={()=>window.open(settings.URLs.AppStore, "_blank")}/>
                                    <Image src={playstoreblack} width={112} paddingTop={20}  onClick={()=>window.open(settings.URLs.PlayStore, "_blank")}/>
                                  </div>
            
                                />
                              }
                              {!menu && member &&  (member.member_status === "basic" || member.member_status === "initial_trial") && 
                                <NewSubscriptionPage   
                                width={650}
                                widthProp={650}
                                logosrc={RDVLogo}
                                coststr={"£4.00pcm"}
                                subscribestr={"Subscribe"}
                                subscribetitlestr={"Subscribe to Rundezvous"}
                                subscribelink={subLink}
            
                                buttoncolor={settings.Theme.PrimaryColor}
                              />
                                
                                // <NewSubscriptionPage width={"100%"} subscribeHandler={() =>{
                                //   console.log(window.location.host);
                                //   if (window.location.host === "www.rundezvous.io") {
                                //     window.open("https://buy.stripe.com/28o6pc1tqa6Y9TG4gg", "_blank");
                                //   } else {
                                //     window.open("https://buy.stripe.com/test_eVa3e5ggq1wIeOs7ss", "_blank");
                                //   }
                                  
                                // }}/>

                                
                              }
                              {member && menu && <MobileSideBar width = "100%"
                                    name={member.member_name}
                                    logoURL={RDVLogo}
                                    logoutURL={LogoutIcon}
                                    runListURL={"/RunList"}
                                    myDetailsURL={"/MyDetails"}
                                    scheduleURL={"/Schedule"}
                                    subscriptionURL={"/Subscribe"}
                                    homeURL={"/HomePage"}
                                />
                                }</Flex>
                        />)
          )}
        </Flex>
      </div>
    </div>
  );
}

export default Subscribe;