import Safeguarding from '../ui-components/Safeguarding.jsx';
import MobileSafeguarding2 from '../ui-components/MobileSafeguarding2.jsx';
import image2 from '../resources/image1.jpg';
import logo from '../specific/images/LongLogoWhite.png';
import settings from '../specific/settings.json'

const SafeguardingScreen = (state) => {

    return (
        <div className="Terms" overflow="auto">
            {state.state.state==="desktop" ?
            <Safeguarding 
                subwidthprop={"100%"} 
                width ={"100%"} 
                margin="auto" 
                widthProp={"100%"}  
                image2src={image2} 
                logosrc={logo} 
                loginurl={"/Login"} 
                homeurl={"/"} 
                termsurl={"/Terms"} 
                supporturl={"/Support"}
                buttoncolor = {settings.Theme.PrimaryColor}
                secondaryColor = {settings.Theme.LighterColor}
                darkButtonCOlor = {settings.Theme.DarkerColor}
                safeguardingtitle = {settings.Screens.SafeguardingScreen.safeguardingtitle}
                safeguardingprinciplesstr = {settings.Screens.SafeguardingScreen.safeguardingprinciplesstr}
                copyrighttextstr = {settings.Screens.SafeguardingScreen.copyrighttextstr}
                titlestr = {settings.Screens.SafeguardingScreen.titlestr}
                supportemailstr = {settings.Screens.SafeguardingScreen.supportemailstr}
                />
            :
            <MobileSafeguarding2 width={"100%"} image2src={image2} logosrc={logo} loginurl={"/Login"} homeurl={"/"} termsurl={"/Terms"} supporturl={"/Support"}/>
            }
        </div>
    );
}

export default SafeguardingScreen;