/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function ChooseMembership(props) {
  const {
    isFull = true,
    clickBasic,
    clickFull,
    basicstr = "Basic",
    fullstr = "Full",
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="row"
      width="250px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="10px 10px 10px 10px"
      {...getOverrideProps(overrides, "ChooseMembership")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,1)"
        borderRadius="20px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 607")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          backgroundColor={isFull == true ? "transparent" : "#9D28E2"}
          onClick={clickBasic}
          {...getOverrideProps(overrides, "BasicButton")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="18px"
            fontWeight="600"
            color={isFull == true ? "#000000" : "#ffffff"}
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={basicstr}
            {...getOverrideProps(overrides, "Basic")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          backgroundColor={isFull == true ? "#9D28E2" : "transparent"}
          onClick={clickFull}
          {...getOverrideProps(overrides, "FullButton")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="18px"
            fontWeight="600"
            color={isFull == true ? "#ffffff" : "#000000"}
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={fullstr}
            {...getOverrideProps(overrides, "Full")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
