import { post } from 'aws-amplify/api';

async function updateSchedule(item) {
    var localJSON = {};
    var schedule;
    localJSON.pk = 'member-'+item.email;
    localJSON.sk ='member-'+item.email;
    localJSON.schedule = item.schedule;
    try{
        var result = post({apiName: 'membereventsapi', path: '/schedule', options: {body:localJSON}});
        // console.log(result);
        const { body } = await result.response;
        const json = await body.json();
        schedule = json.data.schedule;
        return schedule;
    } catch (e) {
        console.log('POST call failed: ', e);
        return;
    }
}

export default updateSchedule;