import { useParams } from 'react-router';
import PublicEventsPage from '../ui-components/PublicEventsPage.jsx';
import PublicEventSlot from '../ui-components/PublicEventSlot.jsx';
import rdv from '../specific/images/LongLogoWhite.png';
import image1p from '../specific/images/PrimaryImage1.jpg';
// import usePublicFetch from '../utils/usePublicFetch.js';
import getPublicEvent from '../utils/getPublicEvent.js';
import { useEffect, useState } from 'react';
import { list } from 'aws-amplify/storage';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { StorageImage } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';

function PublicEvent(state) {
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
      (async function () {
          var tempEvent = await getPublicEvent(id);
          setEvent(tempEvent);
          const date = new Date(tempEvent.date_time.slice(0, 10) + " "+tempEvent.date_time.slice(11, 16) );
          const path = "events/"+date.getFullYear()+"/"+(date.getMonth()+1)+"/"+(date.getDate())+"/"+tempEvent.pk
          const imsResult = await list({prefix:path, options:{level: 'public'}});
          const tempIms = [];
          if(imsResult.items.length>0){
              for (let i = 0; i < imsResult.items.length; i++) {
                  const arr = imsResult.items[i].key.split('.')
                  if (arr[arr.length-1] === "png" || arr[arr.length-1] === "jpg"){
                      tempIms.push(imsResult.items[i].key);
                      console.log("adding: "+imsResult.items[i].key)
                  }
              }
              console.log(tempIms);
              setImages(tempIms);
          }
      })();
    }, [])
    
  return (
    <div className="PublicEventsOverall" overflow="auto">
        {state.state.state==="desktop" ?
        <div className="PublicEvents" overflow="auto">
            <PublicEventsPage 
            width ={"100%"} 
            margin="auto" 
            widthStr={"100%"}
            titletext={"Rundezvous Events"}
            imagesrc={image1p} 
            logosrc={rdv} 
            loginurl={"/Login"} 
            homeurl={"/"} 
            termsurl={"/Terms"}
            frame374={ 
              // <div></div>
              <PublicEventSlot  title={"Rundezvous Event : "+ (event ? (new Date(event.date_time.slice(0, 10))).toLocaleDateString() : "")} 
                                attendees={event ? Math.floor(event.invitees) : ""}
                                loginurl={"/Login"}
                                imageSlot= {
                                  <Carousel infiniteLoop={true} emulateTouch={true} autoPlay={true} interval={5000} showThumbs={false} showArrows={true} onChange={() => {}} onClickItem={() => {}} onClickThumb={() => {}}>
                                    
                                 {images && 
                                    images.map(image => ( 
                                        <div key={image}>
                                        <StorageImage alt="cat" imgKey={image} accessLevel="guest" />
                                        </div>
                                    ))
                                    }
                                </Carousel>
                                }
                                  width = {"100%"}
            
              />
            }
            />
        </div>
        :
        <div className="PublicEventsMobile" overflow="auto">
            <PublicEventsPage width ={"100%"} margin="auto" widthProp={"100%"} imagesrc={image1p} logosrc={rdv} loginurl={"/Login"} homeurl={"/"} termsurl={"/Terms"}/>
        </div>
        }
    </div>
  );
}

export default PublicEvent;