/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "membereventsapi",
            "endpoint": "https://u89y2mkzk1.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yrg5k3nwy5dexoa3idwodlyevq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ws3xvehggvbabhto6rmi5i75ia",
    "aws_cognito_identity_pool_id": "eu-west-2:56859468-0c6f-446d-bc28-9eea0efd6d4b",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_mua65iWwU",
    "aws_user_pools_web_client_id": "3jmoin00d1kdkin0gjrb89kh8h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "memberevents-staging",
            "region": "eu-west-2"
        },
        {
            "tableName": "membereventsarchive-staging",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "rundezvous-amplify-storage74653-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
