/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function SubscriptionDetails(props) {
  const {
    priceStr,
    logosrc,
    titlestr,
    subtitlestr,
    appArea,
    termsurl,
    frame575,
    subsubtitlestr,
    firstqstr,
    firstastr,
    firsta2str,
    secondqstr,
    secondastr,
    seconda2str,
    appstr,
    appastr,
    showterms,
    frame569,
    priceslot,
    buttonSlot,
    thirdqstr,
    thirdastr,
    thirda2str,
    overrides,
    ...rest
  } = props;
  const frameFiveSevenEightFourFourSevenSevenOneTwoSixSevenOnClick =
    useNavigateAction({ target: "_blank", type: "url", url: termsurl });
  return (
    <Flex
      gap="0"
      direction="column"
      width="350px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="10px 10px 10px 10px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SubscriptionDetails")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(120,119,120,1)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 594")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Frame 566")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 567")}
          >
            <Image
              width="175px"
              height="184px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={logosrc}
              {...getOverrideProps(overrides, "Logo 1")}
            ></Image>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 10px 20px 10px"
          {...getOverrideProps(overrides, "Frame 568")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(151,71,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.09px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={titlestr}
            {...getOverrideProps(overrides, "Basic Membership")}
          ></Text>
          <Text
            //fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(120,119,120,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={subtitlestr}
            {...getOverrideProps(overrides, "Unlimited Runs, Every Week.")}
          ></Text>
          <Text
            //fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(151,71,255,1)"
            fontStyle="italic"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={subsubtitlestr}
            {...getOverrideProps(overrides, "Free for early adopters!")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          children={priceslot}
          {...getOverrideProps(overrides, "Frame 569")}
        ></Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="10px"
          padding="0px 0px 0px 0px"
          children={buttonSlot}
          {...getOverrideProps(overrides, "Frame 575")}
        ></Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 576")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 57744651292")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={firstqstr}
              {...getOverrideProps(
                overrides,
                "What do I get as a Basic Member?"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 10px 20px 10px"
            {...getOverrideProps(overrides, "Frame 57844651294")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={firstastr}
              {...getOverrideProps(
                overrides,
                "Once each week our algorithm will attempt to match you with ONE social running event."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 10px 20px 10px"
            {...getOverrideProps(overrides, "Frame 57944781269")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="290px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={firsta2str}
              {...getOverrideProps(
                overrides,
                "As a Basic Member you are entitled to one match every week, to run more frequently or to get access to advanced features, please consider Full Membership."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 57844771251")}
        >
          <Flex
            gap="20px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 20px 10px 20px"
            {...getOverrideProps(overrides, "Frame 57744771252")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={secondqstr}
              {...getOverrideProps(overrides, "Free for early adopters?")}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Frame 57844771254")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="290px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={secondastr}
              {...getOverrideProps(
                overrides,
                "If you sign-up now you will stay a free Basic Member forever. Sign-up now as this won\u2019t stay free forever!"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Frame 57944781271")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="290px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={seconda2str}
              {...getOverrideProps(
                overrides,
                "Our algorithm works best once there is good uptake in your area, so initially there may not be enough people to create the best events for you. Refer your friends for better matches and keep it free!"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 580")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 5774520854")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={thirdqstr}
              {...getOverrideProps(
                overrides,
                "Does my subscription auto renew?"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Frame 5784520856")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={thirdastr}
              {...getOverrideProps(
                overrides,
                "Yes, but you can cancel any time with one tap from this page.4520857"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Frame 5794520860")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={thirda2str}
              {...getOverrideProps(
                overrides,
                "Yes, but you can cancel any time with one tap from this page.4520861"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "MobileApps")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 57744681554")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={appstr}
              {...getOverrideProps(overrides, "Is there an app?")}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Frame 57844681556")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="290px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={appastr}
              {...getOverrideProps(
                overrides,
                "Yes, Rundezvous is available on iOS, Android and in your browser."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            children={appArea}
            {...getOverrideProps(overrides, "AppArea")}
          ></Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 10px 20px 10px"
          {...getOverrideProps(overrides, "Frame 57944771266")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            onClick={() => {
              frameFiveSevenEightFourFourSevenSevenOneTwoSixSevenOnClick();
            }}
            {...getOverrideProps(overrides, "Frame 57844771267")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(120,119,120,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="290px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              hidden={showterms}
              children="*Terms & Conditions"
              {...getOverrideProps(overrides, "*Terms & Conditions")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
