import { get } from 'aws-amplify/api';

async function getEventAttendance(email, pk) {
    var member_pk = "member-"+email;

    try{
        var result = get({apiName: 'membereventsapi', path: '/eventattendance/' + member_pk+"/"+pk, options: {}})
        const { body } = await result.response;
        const json = await body.json();
        return JSON.parse(json.body);
    }catch (e){
        console.log('GET eventattendance call failed: ', JSON.parse(e.response.body));
        return;
    }
}


export default getEventAttendance;