import Terms from '../ui-components/Terms.jsx';
import MobileTerms from '../ui-components/MobileTerms.jsx';
import image2 from '../resources/image1.jpg';
import rdv from '../resources/whitelogo.png';

const TermsPage = (state) => {

    return (
        <div className="Terms" overflow="auto">
            {state.state.state==="desktop" ?
            <Terms width ={"100%"} margin="auto" widthProp={"100%"} image2src={image2} logosrc={rdv} loginurl={"/Login"} homeurl={"/"} termsurl={"/Terms"} termsAnchor={"termsAnchor"} privacyAnchor={"privacyAnchor"} supporturl={"/Support"}/>
            :
            <MobileTerms width ={"100%"} margin="auto" widthProp={"100%"} image2src={image2} logosrc={rdv} loginurl={"/Login"} homeurl={"/"} termsurl={"/Terms"} termsAnchor={"termsAnchor"} privacyAnchor={"privacyAnchor"} supporturl={"/Support"}/>
            }
        </div>
    );
}

export default TermsPage;