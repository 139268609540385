import { Flex, Card,  useTheme } from '@aws-amplify/ui-react';
import Navbar from '../components/Navbar.js';
import MobileHeaderBar from '../ui-components/MobileHeaderBar.jsx';
import getMember from '../utils/getMember.js';
import getAttendance from '../utils/getAttendance.js';
import getArchiveAttendance from '../utils/getArchiveAttendance.js';
import AcceptRejectWrapper from '../components/AcceptRejectWrapper.js';
import Confirmed from '../ui-components/Confirmed.jsx';
import RunCard2 from '../ui-components/RunCard2.jsx';
import RDVLogo from '../specific/images/Logo.png';
import logo from '../specific/images/LongLogoColor.png';
import MenuIcon from '../resources/menu.png';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MobileLayout from '../ui-components/MobileLayout.jsx';
import MyDetailsHeader from '../ui-components/MyDetailsHeader.jsx';
import FilterHolder from '../ui-components/FilterHolder.jsx';
import MobileSideBar from '../ui-components/MobileSideBar.jsx';
import LogoutIcon from '../resources/logout.png';
import { useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router';
import { list } from 'aws-amplify/storage';
import { StorageImage } from '@aws-amplify/ui-react-storage';

function calcCrow(lat1, lon1, lat2, lon2) 
    {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }

// Converts numeric degrees to radians
function toRad(Value) 
{
    return Value * Math.PI / 180;
}

async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails.loginId}`);
      console.log(signInDetails);
      return signInDetails.loginId;
    } catch (err) {
      console.log(err);
    }
  }


const RunList = (state) => {
    const error = false;
    const isPending = false;
    const [ats, setAts] = useState([]);
    const [member,setMember] = useState({});
    const [atsArchive, setAtsArchive] = useState([]);
    const [toConfirm, setToConfirm] = useState(false);
    const [comingUp, setComingUp] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [menu, setMenu] = useState(false);
    const [memberImage, setMemberImage] = useState({});
    const navigate = useNavigate();

    const { tokens } = useTheme();
    const weekday = ["Sun","Mon","Tues","Weds","Thurs","Fri","Sat"];
    
    useEffect(() => {
        (async function () {
            const tempEmail = await currentAuthenticatedUser();
            // console.log("tempemail: "+tempEmail);
            var tempMember = await getMember(tempEmail);
            setMember(await tempMember);
            var tempAttendance = await getAttendance(tempEmail);
            // console.log("attendance: ")
            // console.log(await tempAttendance);
            
            // console.log(member);
            // console.log(tempAttendance[0])
            for (let i = 0; i < tempAttendance.length; i++) {
                const path = "events/"+Number(tempAttendance[0].event.date_time.slice(0, 4))+"/"+
                    Number(tempAttendance[0].event.date_time.slice(5, 7))+"/"+
                    Number(tempAttendance[0].event.date_time.slice(8, 10))+"/"+
                    tempAttendance[0].event.pk+".png"
                const imsResult = await list({prefix:path, options:{level: 'public'}});
                // console.log(imsResult.items);
                if (imsResult.items.length > 0){
                    tempAttendance[i].key = imsResult.items[0].key;
                    // console.log(imsResult.items[0].key);
                    // console.log("set event ims")
                }
            }
            // console.log(tempAttendance);
            setAts(tempAttendance);
            var tempArchiveAttendance = await getArchiveAttendance(tempEmail);
            for (let i = 0; i < tempArchiveAttendance.length; i++) {
                const path = "events/"+Number(tempArchiveAttendance[0].event.date_time.slice(0, 4))+"/"+
                    Number(tempArchiveAttendance[0].event.date_time.slice(5, 7))+"/"+
                    Number(tempArchiveAttendance[0].event.date_time.slice(8, 10))+"/"+
                    tempArchiveAttendance[0].event.pk+".png"
                const imsResult = await list({prefix:path, options:{level: 'public'}});
                // console.log(imsResult.items);
                if (imsResult.items.length > 0){
                    tempArchiveAttendance[i].key = imsResult.items[0].key;
                    // console.log(imsResult.items[0].key);
                    // console.log("set event ims")
                }
            }
            setAtsArchive(tempArchiveAttendance);
            if(tempMember === undefined || (!("member_name" in tempMember))){
                console.log("heading to NewMember")
                navigate('/NewMember');
            }
            if(tempMember !== undefined){
                const path = "members/"+tempMember.country+"/"+tempMember.pk_hash+".png";
                const imsResult = await list({prefix:path, options:{level: 'public'}});
                // console.log(imsResult.items[0].key);
                if(imsResult.items.length>0) {
                    setMemberImage(imsResult.items[0].key);
                }
            }
            
        })();
        // getMember(currentAuthenticatedUser());
    },[]);

    return (
        <div className="RunList" overflow="auto" width={"100%"}>
            <div className="content" overflow="auto">
                <Flex backgroundColor={tokens.colors.background.primary} >
                    {(state.state.state==="desktop" ?
                        (<DesktopLayout
                            display="block"
                            // height={completed ? 500 + (atsArchive.length * 650) :  (ats.length>0 ? 500 + (ats.length * 650) : 1000)}
                            width={"100%"}
                            margin="auto"
                            navBar=<Navbar />
                            mainContent=<div>
                                {isPending && <Card>Loading...</Card>}
                                {error && <Card>{error}</Card>}
                                {ats && ats.length === 0 && !completed && <Card>No events scheduled yet, please check back later.</Card>}
                                {atsArchive && completed && atsArchive.map(at => (
                                    ((!toConfirm) || (!at.accepted)) &&
                                    ((!comingUp) || ((at.accepted) && (Date.parse(at.event.date_time) >= (new Date())))) &&
                                    ((!completed) || (Date.parse(at.event.date_time) < (new Date()))) &&
                                    <div id = {crypto.randomUUID()}>
                                        <span id = {crypto.randomUUID()}>
                                            <RunCard2 
                                                id = {crypto.randomUUID()}
                                                onclickurl={"/EventDetails/"+at.event.pk} 
                                                imageRight={<StorageImage alt="Event" imgKey={at.key} accessLevel="guest" />}
                                                title={"Rundezvous Event"}
                                                date={weekday[(new Date(at.event.date_time.slice(0, 10))).getDay()] + " : " + (new Date(at.event.date_time.slice(0, 10))).toLocaleDateString() }
                                                time={ at.event.date_time.slice(11, 16) }
                                                pace={ (Math.round(at.event.pace * 10) / 10) + " kph" }
                                                faraway={"How far away?"}
                                                status={at.event.event_status}
                                                distance={(Math.round(calcCrow(member.lat, member.lng, at.event.lat, at.event.lng)*10)/10) + " km"}
                                                acceptanceBox={!at.accepted ? <AcceptRejectWrapper id = {crypto.randomUUID()} at={at} /> : <Confirmed id = {crypto.randomUUID()} buttonLabel={"View Location"} 
                                                viewDetailsHandler={() =>{
                                                    window.open(at.event.location_url, "_blank");
                                                }}
                                            />} />
                                        </span>
                                    </div>

                                ))}
                                {ats && (!completed) && member && ats.map(at => (
                                    ((!toConfirm) || (!at.accepted)) &&
                                    ((!comingUp) || ((at.accepted) && (Date.parse(at.event.date_time) >= (new Date())))) &&
                                    ((!completed) || (Date.parse(at.event.date_time) < (new Date()))) &&
                                    <div id = {crypto.randomUUID()}>
                                        <span id = {crypto.randomUUID()}>
                                            <RunCard2 
                                                id = {crypto.randomUUID()} 
                                                onclickurl={"/EventDetails/"+at.event.pk} 
                                                imageRight={<StorageImage alt="Event" imgKey={at.key} accessLevel="guest" />}
                                                title={"Rundezvous Event"}
                                                date={weekday[(new Date(at.event.date_time.slice(0, 10))).getDay()] + " : " + (new Date(at.event.date_time.slice(0, 10))).toLocaleDateString() }
                                                time={ at.event.date_time.slice(11, 16) }
                                                pace={ (Math.round(at.event.pace * 10) / 10) + " kph" }
                                                faraway={"How far away?"}
                                                status={at.event.event_status}
                                                distance={(Math.round(calcCrow(member.lat, member.lng, at.event.lat, at.event.lng)*10)/10) + " km"}
                                                acceptanceBox={!at.accepted ? <AcceptRejectWrapper id = {crypto.randomUUID()} at={at} /> : <Confirmed id = {crypto.randomUUID()} buttonLabel={"View Location"} viewDetailsHandler={() =>{
                                                    window.open(at.event.location_url, "_blank");
                                                }}/>} 
                                            />
                                        </span>
                                    </div>

                                ))}
                            </div>
                            rightBar={<FilterHolder toConfirmHandler={() => {
                                if ((!toConfirm) && comingUp) {
                                    setComingUp(false);
                                }
                                if ((!toConfirm) && completed) {
                                    setCompleted(false);
                                }
                                setToConfirm(!toConfirm);
                            }}
                                comingUpHandler={() => {
                                    if ((!comingUp) && toConfirm) {
                                        setToConfirm(false);
                                    }
                                    if ((!comingUp) && completed) {
                                        setCompleted(false)
                                    }
                                    setComingUp(!comingUp);
                                }}
                                completedHandler={() => {
                                    if ((!completed) && toConfirm) {
                                        setToConfirm(false);
                                    }
                                    if ((!completed) && comingUp) {
                                        setComingUp(false);
                                    }
                                    setCompleted(!completed);
                                }}
                                toConfirmBackground={toConfirm ? "#b054e7" : "White"}
                                comingUpBackground={comingUp ? "#b054e7" : "White"}
                                completedBackground={completed ? "#b054e7" : "White"}
                                toConfirmColor={toConfirm ? "White" : "Black"}
                                comingUpColor={comingUp ? "White" : "Black"}
                                completedColor={completed ? "White" : "Black"}
                            />}
                            leftBar={member && ats && <MyDetailsHeader imageFrame={<StorageImage alt="Member" imgKey={memberImage} accessLevel="guest" />} 
                                name={member.member_name}
                                email={member.email}
                                status={member.member_status === "initial_trial" ? "Free Trial" : (member.member_status === "basic" ? "Unsubscribed User" : (member.member_status === "paid" ? "Full Member" : (member.member_status === "stripe_trial"? "Subscribed Trial Period" : "")))}
                                date={ats.length > 0 ? (new Date(ats[0].event.date_time.slice(0, 10))).toLocaleDateString()  : ""}
                                time={ats.length > 0 ? ats[0].event.date_time.slice(11, 16) : ""}
                                pace={ats.length > 0 ? (Math.round(ats[0].event.pace * 10) / 10) + "kph" : ""}
                                stat1title="Scheduled"
                                stat2title="Confirm"
                                stat3title="Completed"
                                stat1={member.upcoming_events}
                                stat2={member.confirmation_events}
                                stat3={member.completed_events} />} />)
                        :
                        <MobileLayout width="100%"
                            mobileHeader=<MobileHeaderBar width="100%"
                                logoURL={logo}
                                burgerURL={MenuIcon}
                                navigateHomeURL={"/HomePage"}
                                burgerHandler={() => {
                                    setMenu(!menu);
                                    // console.log("burger!")
                                }} />
                            firstContent=<div>
                                {isPending && <Card>Loading...</Card>}
                                {error && <Card>{error}</Card>}
                                {!menu && ats && ats.length === 0 && <Card>No events scheduled yet, please check back later.</Card>}
                                {!menu && ats && ats.map(at => (
                                    ((!toConfirm) || (!at.accepted)) &&
                                    ((!comingUp) || ((at.accepted) && (Date.parse(at.event.date_time) >= (new Date())))) &&
                                    ((!completed) || (Date.parse(at.event.date_time) < (new Date()))) &&
                                    <>
                                            {/* <Text>{at.event.pk}</Text> */}
                                            <RunCard2 id = {crypto.randomUUID()}
                                                width="100%" 
                                                onclickurl={"/EventDetails/"+at.event.pk} 
                                                title="Rundezvous Event"
                                                date={ weekday[(new Date(at.event.date_time.slice(0, 10))).getDay()] + " : " + (new Date(at.event.date_time.slice(0, 10))).toLocaleDateString()}
                                                time={at.event.date_time.slice(11, 16)}
                                                pace={(Math.round(at.event.pace * 10) / 10) + "kph"}
                                                imageRight={<StorageImage alt="Event" imgKey={at.key} accessLevel="guest" />} 
                                                status={at.event.status}
                                                faraway={"How far away?"}
                                                distance={(Math.round(calcCrow(member.lat, member.lng, at.event.lat, at.event.lng)*10)/10) + " km"}
                                                acceptanceBox={!at.accepted ? <AcceptRejectWrapper id = {crypto.randomUUID()} at={at} /> : <Confirmed id = {crypto.randomUUID()} buttonLabel={"View Location"} viewDetailsHandler={() =>{
                                                    window.open(at.event.location_url, "_blank");
                                                }}/>}
                                            />
                                    </>

                                ))}
                                {member && menu && <MobileSideBar
                                    name={member.member_name}
                                    logoURL={RDVLogo}
                                    logoutURL={LogoutIcon}
                                    runListURL={"/RunList"}
                                    myDetailsURL={"/MyDetails"}
                                    scheduleURL={"/Schedule"}
                                    subscriptionURL={"/Subscribe"}
                                    homeURL={"/HomePage"}
                                />
                                }
                            </div>
                            filters={<FilterHolder toConfirmHandler={() => {
                                if ((!toConfirm) && comingUp) {
                                    setComingUp(false);
                                }
                                if ((!toConfirm) && completed) {
                                    setCompleted(false);
                                }
                                setToConfirm(!toConfirm);
                            }}
                                comingUpHandler={() => {
                                    if ((!comingUp) && toConfirm) {
                                        setToConfirm(false);
                                    }
                                    if ((!comingUp) && completed) {
                                        setCompleted(false)
                                    }
                                    setComingUp(!comingUp);
                                }}
                                completedHandler={() => {
                                    if ((!completed) && toConfirm) {
                                        setToConfirm(false);
                                    }
                                    if ((!completed) && comingUp) {
                                        setComingUp(false);
                                    }
                                    setCompleted(!completed);
                                }}
                                toConfirmBackground={toConfirm ? "#b054e7" : "White"}
                                comingUpBackground={comingUp ? "#b054e7" : "White"}
                                completedBackground={completed ? "#b054e7" : "White"}
                                toConfirmColor={toConfirm ? "White" : "Black"}
                                comingUpColor={comingUp ? "White" : "Black"}
                                completedColor={completed ? "White" : "Black"}
                            />}
                        />)}</Flex>

            </div>
        </div>
    );
}

export default RunList;