/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function PublicHomePage(props) {
  const {
    signup,
    image1src,
    image2src,
    image3src,
    image4src,
    logosrc,
    homeurl,
    termsurl,
    loginurl,
    contacturl,
    widthProp = '"100%"',
    supporturl,
    appStoreSrc,
    playstoresrc,
    appstoreblack143681323,
    playstoreurl,
    appstoreurl,
    subtitle_str,
    title_str,
    titlestr = "Rundezvous",
    subtitlestr = "A dynamic scheduling service for sociable runs in your area with people like you.",
    tmstr = "TM",
    text1str = "Running in groups is fun, great for your mental and physical health and lets you meet new people.",
    text2str = "Our mission is to make running in groups fast, easy and dynamic. Allow people to find people in their area with similar schedules to run with.",
    step1titlestr = "Input your weekly schedule",
    step1textstr = "Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace.  The more flexible you can be with your schedule the more runs you\u2019ll get matched with. All changes need to be done by 18:00 on Wednesday evening.",
    step2titlestr = "Offer you running events.",
    step2textstr = "We then use our algorithm to take in this information and create dynamic running events which best suit yours and other people\u2019s schedules. These will be located as close to your home location as possible - the more people who sign-up in your area, the more flexible this will become. You will receive a list of suggested runs for the following week which you can accept or reject at that point by 18:00 on Friday.",
    step3titlestr = "If enough people sign-up - Game on!",
    step3textstr = "If at least 3 people accept the runs you accepted, you will receive a confirmation email that the run is going ahead with full details of meeting point and time. You just turn up then and there, meet your group and head off for a casual run. Don\u2019t forget to share group pictures on your social media with #Rundezvous!",
    benefitstitlestr = "Why run in a group?",
    benefit1titlestr = "Physical Health.",
    benefit11textstr = "Regular running is one of the best ways to stay physically healthy.",
    benefit12textstr = "Low levels of physical activity Studies\u00A0have shown running a bit and often halves your heart attack risk.",
    benefit2titlestr = "Mental Health.",
    benefit21textstr = "Running has innate mental health benefits, as does socialising with others.",
    benefit22textstr = "Rundezvous gives its users the opportunity to join these two activities together.",
    benefit3titlestr = "Make Connections.",
    benefit31textstr = "In an ever increasingly online and virtual world, connections IRL (In Real Life) are more and more difficult to come by. ",
    benefit32textstr = "Rundezvous makes these connections for you and groups you with like-minded people who want to go for a casual run and maybe have a chat too.",
    getstartedtitlestr = "Ready to get started?",
    getstartedtextstr = "Registration takes minutes and you could be on your first Rundezvous next week!",
    detailstextstr = "Rundezvous operates on a monthly subscription, but we offer an initial free trial period with full access to as many runs as you want. After the free-trial you are limited to one run per fortnight.  Email us at support@rundezvous.io",
    detailstitlestr = "Details",
    copyrighttextstr = "\u00A9 2024 Rundezvous Ltd. All rights reserved.",
    buttoncolor = "#9D28E2",
    secondaryColor = '"#DFBCF5"',
    darkButtonCOlor = "#54127D",
    showAppleApp = "true",
    showAndroidApp,
    pricingsrc,
    right4242719,
    benefit13textstr = "One of the biggest challenges is staying consistent which is where Rundezvous comes in.",
    mapSlot,
    PricingTitle,
    Pricing1,
    Pricing2,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        "1": {},
        "2": {},
        "3": {},
        "long+logo+overlapped+(1) 14242831": {},
        "Frame 517": {},
        Button4242832: {},
        Button45501249: {},
        "Frame 321": {},
        Header: {},
        image4266730: {},
        ImageFrame: {},
        Heading4242768: {},
        Heading4350848: {},
        "Frame 554": {},
        Body4242769: {},
        Message4242766: {},
        Vector43681327: {},
        Vector43681328: {},
        Group43681326: {},
        "<Path>43681332": {},
        "<Path>43681333": {},
        "<Group>43681331": {},
        "<Group>43681330": {},
        Vector43681335: {},
        Vector43681336: {},
        Vector43681337: {},
        Vector43681338: {},
        Vector43681339: {},
        Vector43681340: {},
        Vector43681341: {},
        Vector43681342: {},
        Group43681334: {},
        "<Group>43681329": {},
        Group43681325: {},
        Vector43681345: {},
        Vector43681346: {},
        Vector43681347: {},
        Vector43681348: {},
        Vector43681349: {},
        Vector43681350: {},
        Vector43681351: {},
        Vector43681352: {},
        Vector43681353: {},
        Vector43681354: {},
        Vector43681355: {},
        Vector43681356: {},
        Vector43681357: {},
        Group43681344: {},
        "<Group>43681343": {},
        Group43681324: {},
        "appstoreblack 143681323": {},
        "Frame 55643681322": {},
        "google-play-badge 243681359": {},
        "Frame 55743681358": {},
        "Frame 55843681321": {},
        HeroMessage4242765: {},
        Left4242764: {},
        Def: {},
        Eyebrow: {},
        Heading4242716: {},
        Body4242717: {},
        Message4242714: {},
        Button4242718: {},
        HeroMessage4242713: {},
        Left4242712: {},
        image4242720: {},
        Right4242719: {},
        What: {},
        "Frame 4304259727": {},
        "Input your weekly schedule": {},
        "Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace. The more flexible you can be with your schedule the more runs you\u2019ll get matched with. All changes need to be done by 18:00 on Wednesday evening.":
          {},
        "Frame 3744259726": {},
        "Frame 4304259732": {},
        "Offer you running events.": {},
        "We then use our algorithm to take in this information and create dynamic running events which best suit yours and other people\u2019s schedules. These will be located as close to your home location as possible - the more people who sign-up in your area, the more flexible this will become. You will receive a list of suggested runs for the following week which you can accept or reject at that point by 18:00 on Friday.":
          {},
        "Frame 373": {},
        "Frame 136": {},
        "Frame 4304259738": {},
        "If enough people sign-up - Game on!": {},
        "If at least 3 people accept the runs you accepted, you will receive a confirmation email that the run is going ahead with full details of meeting point and time. You just turn up then and there, meet your group and head off for a casual run. Don\u2019t forget to share group pictures on your social media with #Rundezvous!":
          {},
        "Frame 3744259737": {},
        "Frame 393": {},
        How: {},
        Benefits: {},
        "Why run in a group?": {},
        "Frame 432": {},
        "Physical Health.": {},
        "Frame 4294259965": {},
        "Regular running is one of the best ways to stay physically healthy.":
          {},
        "Frame 1384259969": {},
        "Low levels of physical activity Studies\u00A0have shown running a bit and often halves your heart attack risk.":
          {},
        "Frame 1394259973": {},
        "One of the biggest challenges is staying consistent which is where Rundezvous comes in.":
          {},
        "Frame 137": {},
        "Frame 3904259964": {},
        "Frame 518": {},
        "Mental Health.": {},
        "Frame 4294259983": {},
        "Running has innate mental health benefits, as does socialising with others.":
          {},
        "Frame 1384259987": {},
        "Rundezvous gives its users the opportunity to join these two activities together.":
          {},
        "Frame 1394259991": {},
        "Frame 3904259982": {},
        "Frame 519": {},
        "Make Connections.": {},
        "Frame 42942591001": {},
        "In an ever increasingly online and virtual world, connections IRL (In Real Life) are more and more difficult to come by.":
          {},
        "Frame 13842591005": {},
        "Rundezvous makes these connections for you and groups you with like-minded people who want to go for a casual run and maybe have a chat too.":
          {},
        "Frame 13942591009": {},
        "Frame 391": {},
        "Frame 520": {},
        "Frame 63": {},
        Why: {},
        image42591046: {},
        Right42591045: {},
        Heading42591042: {},
        Body42591043: {},
        Message42591040: {},
        Button42591044: {},
        Heading43681361: {},
        Vector43681369: {},
        Vector43681370: {},
        Group43681368: {},
        "<Path>43681374": {},
        "<Path>43681375": {},
        "<Group>43681373": {},
        "<Group>43681372": {},
        Vector43681377: {},
        Vector43681378: {},
        Vector43681379: {},
        Vector43681380: {},
        Vector43681381: {},
        Vector43681382: {},
        Vector43681383: {},
        Vector43681384: {},
        Group43681376: {},
        "<Group>43681371": {},
        Group43681367: {},
        Vector43681387: {},
        Vector43681388: {},
        Vector43681389: {},
        Vector43681390: {},
        Vector43681391: {},
        Vector43681392: {},
        Vector43681393: {},
        Vector43681394: {},
        Vector43681395: {},
        Vector43681396: {},
        Vector43681397: {},
        Vector43681398: {},
        Vector43681399: {},
        Group43681386: {},
        "<Group>43681385": {},
        Group43681366: {},
        "appstoreblack 143681365": {},
        "Frame 55643681364": {},
        "google-play-badge 243681401": {},
        "Frame 55743681400": {},
        "Frame 55843681363": {},
        Message43681360: {},
        HeroMessage42591039: {},
        Left42591038: {},
        Action: {},
        Heading4492975: {},
        Body4492976: {},
        Message4492974: {},
        Body4492978: {},
        Message4492977: {},
        Body4492973: {},
        Message4492971: {},
        Body4492980: {},
        Message4492979: {},
        Message42591055: {},
        HeroMessage42591054: {},
        Left42591053: {},
        image42591052: {},
        Right42591051: {},
        Contact4260730: {},
        Heading4493985: {},
        Body4493987: {},
        Message4493986: {},
        Body4493989: {},
        Message4493988: {},
        Body4493991: {},
        Message4493990: {},
        Message4493992: {},
        Message4493984: {},
        HeroMessage4493983: {},
        Left4493982: {},
        image4493995: {},
        Right4493994: {},
        Contact4493981: {},
        Rundezvous: {},
        TM: {},
        "Frame 555": {},
        "Frame 434": {},
        "Frame 313": {},
        Navigation: {},
        Home: {},
        Terms42591116: {},
        Terms42591146: {},
        Support: {},
        "Frame 403": {},
        "Frame 405": {},
        "long+logo+overlapped+(1) 14264729": {},
        LogoWithText: {},
        "\u00A9 2024 Rundezvous Ltd. All rights reserved.": {},
        "Frame 433": {},
        Footer: {},
        PublicHomePage: {},
      },
      variantValues: {},
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const buttonFourTwoFourTwoEightThreeTwoOnClick = useNavigateAction({
    type: "url",
    url: signup,
  });
  const buttonFourFiveFiveZeroOneTwoFourNineOnClick = useNavigateAction({
    type: "url",
    url: loginurl,
  });
  const frameFiveFiveSixFourThreeSixEightOneThreeTwoTwoOnClick =
    useNavigateAction({ type: "url", url: appstoreurl });
  const googleplaybadgeTwoFourThreeSixEightOneThreeFiveNineOnClick =
    useNavigateAction({ type: "url", url: playstoreurl });
  const buttonFourTwoFourTwoSevenOneEightOnClick = useNavigateAction({
    type: "url",
    url: signup,
  });
  const buttonFourTwoFiveNineOneZeroFourFourOnClick = useNavigateAction({
    type: "url",
    url: signup,
  });
  const frameFiveFiveSixFourThreeSixEightOneThreeSixFourOnClick =
    useNavigateAction({ type: "url", url: appstoreurl });
  const googleplaybadgeTwoFourThreeSixEightOneFourZeroOneOnClick =
    useNavigateAction({ type: "url", url: playstoreurl });
  const homeOnClick = useNavigateAction({ type: "url", url: homeurl });
  const termsFourTwoFiveNineOneOneOneSixOnClick = useNavigateAction({
    type: "url",
    url: termsurl,
  });
  const termsFourTwoFiveNineOneOneFourSixOnClick = useNavigateAction({
    type: "url",
    url: loginurl,
  });
  const supportOnClick = useNavigateAction({ type: "url", url: supporturl });
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "PublicHomePage")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
        padding="10px 120px 10px 120px"
        backgroundColor={buttoncolor}
        display="flex"
        {...getOverrideProps(overrides, "Header")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 517")}
        >
          <Image
            width="186.03px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={logosrc}
            {...getOverrideProps(
              overrides,
              "long+logo+overlapped+(1) 14242831"
            )}
          ></Image>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 321")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor={darkButtonCOlor}
            size="large"
            isDisabled={false}
            variation="primary"
            children="Sign up"
            onClick={() => {
              buttonFourTwoFourTwoEightThreeTwoOnClick();
            }}
            {...getOverrideProps(overrides, "Button4242832")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor="rgba(84,18,125,1)"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Log in"
            onClick={() => {
              buttonFourFiveFiveZeroOneTwoFourNineOnClick();
            }}
            {...getOverrideProps(overrides, "Button45501249")}
          ></Button>
        </Flex>
      </Flex>
      <View
        width="unset"
        height="500px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Def")}
      >
        <Flex
          gap="10px"
          direction="column"
          width={widthProp}
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "ImageFrame")}
        >
          <Image
            width="unset"
            height="500px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="5px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={image1src}
            {...getOverrideProps(overrides, "image4266730")}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width={widthProp}
          height="500px"
          justifyContent="center"
          alignItems="flex-start"
          overflow="hidden"
          position="absolute"
          top="0px"
          left="0px"
          padding="120px 120px 120px 120px"
          display="flex"
          {...getOverrideProps(overrides, "Left4242764")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="20px 20px 20px 20px"
            backgroundColor="rgba(0,0,0,0.2)"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage4242765")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message4242766")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 554")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="48px"
                  fontWeight="300"
                  color="rgba(255,255,255,1)"
                  lineHeight="60px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={titlestr}
                  {...getOverrideProps(overrides, "Heading4242768")}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={tmstr}
                  {...getOverrideProps(overrides, "Heading4350848")}
                ></Text>
              </Flex>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={subtitlestr}
                {...getOverrideProps(overrides, "Body4242769")}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 55843681321")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="163px"
                height="58px"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                onClick={() => {
                  frameFiveFiveSixFourThreeSixEightOneThreeTwoTwoOnClick();
                }}
                {...getOverrideProps(overrides, "Frame 55643681322")}
              >
                <View
                  width="165px"
                  height="61px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "appstoreblack 143681323")}
                >
                  <View
                    padding="0px 0px 0px 0px"
                    width="164.44px"
                    height="61px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0.34%"
                    right="0%"
                    {...getOverrideProps(overrides, "Group43681324")}
                  >
                    <View
                      padding="0px 0px 0px 0px"
                      width="164.44px"
                      height="61px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="0%"
                      bottom="0%"
                      left="0%"
                      right="0%"
                      {...getOverrideProps(overrides, "Group43681325")}
                    >
                      <View
                        padding="0px 0px 0px 0px"
                        width="164.44px"
                        height="61px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="0%"
                        bottom="0%"
                        left="0%"
                        right="0%"
                        {...getOverrideProps(overrides, "Group43681326")}
                      >
                        <Icon
                          width="164.44px"
                          height="61px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 164.44256591796875,
                            height: 61.00019836425781,
                          }}
                          paths={[
                            {
                              d: "M151.347 0.00019825L13.1026 0.00019825C12.5987 0.00019825 12.1008 0.00019825 11.5982 0.00324825C11.1775 0.00629825 10.7601 0.0151585 10.3354 0.0226157C9.41261 0.034666 8.49203 0.124755 7.58161 0.292098C6.67247 0.463086 5.79181 0.785445 4.96942 1.24827C4.14803 1.71502 3.3975 2.3215 2.74506 3.04573C2.0892 3.76792 1.54249 4.60267 1.12595 5.51789C0.708264 6.43129 0.418637 7.41008 0.267076 8.42046C0.114074 9.42956 0.0317418 10.4503 0.0208192 11.4735C0.00806656 11.9411 0.00671985 12.4102 0 12.8778L0 48.1271C0.00671985 48.6006 0.00806656 49.0593 0.0208192 49.533C0.0317452 50.5561 0.114077 51.5768 0.267076 52.5859C0.418219 53.5968 0.707862 54.5762 1.12595 55.4899C1.5423 56.4022 2.08909 57.2335 2.74506 57.9517C3.39503 58.6792 4.14604 59.2861 4.96942 59.7492C5.7918 60.2133 6.67237 60.5376 7.58161 60.7112C8.49218 60.8772 9.41267 60.9673 10.3354 60.9808C10.7601 60.9912 11.1775 60.9972 11.5982 60.9972C12.1008 61.0002 12.5987 61.0002 13.1026 61.0002L151.347 61.0002C151.841 61.0002 152.343 61.0002 152.837 60.9972C153.256 60.9972 153.685 60.9912 154.104 60.9808C155.025 60.9681 155.944 60.8779 156.852 60.7112C157.764 60.5364 158.648 60.2122 159.475 59.7492C160.297 59.2858 161.048 58.6789 161.697 57.9517C162.351 57.2307 162.899 56.4 163.321 55.4899C163.736 54.5755 164.022 53.5962 164.171 52.5859C164.325 51.5767 164.41 50.5562 164.426 49.533C164.432 49.0593 164.432 48.6006 164.432 48.1271C164.443 47.573 164.443 47.022 164.443 46.459L164.443 14.5428C164.443 13.9843 164.443 13.4303 164.432 12.8778C164.432 12.4102 164.432 11.9411 164.426 11.4734C164.41 10.4501 164.325 9.42963 164.171 8.42039C164.022 7.4106 163.735 6.43189 163.321 5.51783C162.472 3.68337 161.127 2.19014 159.475 1.24814C158.648 0.786438 157.764 0.464165 156.852 0.291961C155.944 0.12388 155.025 0.0337601 154.104 0.0224022C153.685 0.0149602 153.256 0.00602375 152.837 0.00305C152.343 0 151.841 0.00019825 151.347 0.00019825Z",
                              fill: "rgba(166,166,166,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0%"
                          left="0%"
                          right="0%"
                          {...getOverrideProps(overrides, "Vector43681327")}
                        ></Icon>
                        <Icon
                          width="162.04px"
                          height="58.33px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 162.04319763183594,
                            height: 58.33429718017578,
                          }}
                          paths={[
                            {
                              d: "M10.4052 58.3312C9.98652 58.3312 9.57794 58.3253 9.16253 58.3149C8.30198 58.3025 7.44344 58.2193 6.59396 58.0662C5.80185 57.9148 5.03452 57.6332 4.31726 57.2307C3.60658 56.8314 2.95838 56.308 2.3975 55.6803C1.8285 55.0601 1.35478 54.3407 0.995114 53.5507C0.631486 52.7555 0.379833 51.9032 0.248923 51.0234C0.107551 50.0781 0.031063 49.1223 0.0201184 48.1641C0.0114059 47.8425 0 46.7716 0 46.7716L0 11.5432C0 11.5432 0.012148 10.4888 0.0201871 10.1791C0.0306665 9.22241 0.10671 8.26802 0.247659 7.32416C0.378811 6.44198 0.630658 5.58722 0.994482 4.78946C1.35283 3.99999 1.82392 3.28006 2.38949 2.65759C2.95443 2.0292 3.6047 1.50305 4.31659 1.09832C5.0322 0.697068 5.79808 0.417442 6.58858 0.268812C7.44086 0.114125 8.30238 0.030494 9.16591 0.0186203L10.4059 0L151.631 0L152.885 0.0193675C153.741 0.0306513 154.595 0.113536 155.439 0.267317C156.238 0.417812 157.012 0.699389 157.735 1.10279C159.162 1.91843 160.322 3.20885 161.054 4.79318C161.412 5.58543 161.66 6.43322 161.79 7.30778C161.932 8.25939 162.012 9.22128 162.028 10.1858C162.032 10.6177 162.032 11.0816 162.032 11.5432C162.043 12.1151 162.043 12.6594 162.043 13.2082L162.043 45.1244C162.043 45.6785 162.043 46.2191 162.032 46.7641C162.032 47.2601 162.032 47.7144 162.027 48.1819C162.011 49.1292 161.932 50.0739 161.792 51.0085C161.664 51.8946 161.414 52.7536 161.05 53.5553C160.688 54.3367 160.217 55.0499 159.654 55.6684C159.093 56.2995 158.444 56.826 157.731 57.2278C157.01 57.6334 156.237 57.916 155.439 58.0662C154.59 58.2202 153.731 58.3033 152.871 58.3149C152.468 58.3253 152.047 58.3312 151.637 58.3312L150.148 58.3343L10.4052 58.3312Z",
                              fill: "rgba(0,0,0,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="2.19%"
                          bottom="2.18%"
                          left="0.73%"
                          right="0.73%"
                          {...getOverrideProps(overrides, "Vector43681328")}
                        ></Icon>
                      </View>
                      <View
                        padding="0px 0px 0px 0px"
                        width="136.75px"
                        height="37.78px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="21.8%"
                        bottom="16.27%"
                        left="8.33%"
                        right="8.5%"
                        {...getOverrideProps(overrides, "<Group>43681329")}
                      >
                        <View
                          padding="0px 0px 0px 0px"
                          width="24.35px"
                          height="33.21px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="12.09%"
                          left="0%"
                          right="82.2%"
                          {...getOverrideProps(overrides, "<Group>43681330")}
                        >
                          <View
                            padding="0px 0px 0px 0px"
                            width="24.35px"
                            height="33.21px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="0%"
                            left="0%"
                            right="0%"
                            {...getOverrideProps(overrides, "<Group>43681331")}
                          >
                            <Icon
                              width="24.35px"
                              height="25.2px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 24.34611701965332,
                                height: 25.197927474975586,
                              }}
                              paths={[
                                {
                                  d: "M20.3345 9.6497C20.3493 8.37684 20.6539 7.12882 21.2201 6.02174C21.7863 4.91466 22.5957 3.9845 23.5729 3.31781C22.9521 2.33394 22.1332 1.52426 21.1811 0.953063C20.229 0.381871 19.17 0.0649086 18.0882 0.0273484C15.7806 -0.241448 13.5435 1.55971 12.3676 1.55971C11.1689 1.55971 9.35837 0.0540359 7.40875 0.0985506C6.14768 0.143764 4.91867 0.550715 3.84146 1.27975C2.76426 2.00879 1.87559 3.03506 1.26206 4.25855C-1.39565 9.36489 0.586759 16.8695 3.13262 20.9971C4.40637 23.0182 5.89501 25.2759 7.84287 25.1959C9.74897 25.1081 10.4609 23.847 12.7617 23.847C15.0412 23.847 15.7091 25.1959 17.6965 25.145C19.7418 25.1081 21.0304 23.1148 22.2595 21.0745C23.1747 19.6344 23.8789 18.0427 24.3461 16.3584C23.1578 15.8007 22.1438 14.8671 21.4304 13.6741C20.717 12.4811 20.3359 11.0815 20.3345 9.6497Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display={showAppleApp}
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="24.12%"
                              bottom="0%"
                              left="0%"
                              right="0%"
                              {...getOverrideProps(overrides, "<Path>43681332")}
                            ></Icon>
                            <Icon
                              width="6.01px"
                              height="7.66px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 6.012561321258545,
                                height: 7.657356262207031,
                              }}
                              paths={[
                                {
                                  d: "M4.46143 5.32319C5.57663 3.83753 6.12604 1.92795 5.993 0C4.28922 0.198586 2.7154 1.10224 1.58514 2.53091C1.03251 3.22884 0.609264 4.04081 0.339583 4.92038C0.069902 5.79996 -0.0409204 6.72989 0.0134494 7.65702C0.865646 7.66676 1.70872 7.46178 2.47919 7.05752C3.24965 6.65327 3.92741 6.06027 4.46143 5.32319Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="0%"
                              bottom="76.94%"
                              left="49.78%"
                              right="25.53%"
                              {...getOverrideProps(overrides, "<Path>43681333")}
                            ></Icon>
                          </View>
                        </View>
                        <View
                          padding="0px 0px 0px 0px"
                          width="103.15px"
                          height="23.82px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="36.94%"
                          bottom="0%"
                          left="24.57%"
                          right="0%"
                          {...getOverrideProps(overrides, "Group43681334")}
                        >
                          <Icon
                            width="15.18px"
                            height="18.94px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 15.18465518951416,
                              height: 18.937450408935547,
                            }}
                            paths={[
                              {
                                d: "M10.8218 13.8189L4.31719 13.8189L2.75511 18.9375L0 18.9375L6.1611 0L9.02356 0L15.1847 18.9375L12.3826 18.9375L10.8218 13.8189ZM4.99087 11.4569L10.1469 11.4569L7.60514 3.14981L7.53401 3.14981L4.99087 11.4569Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="1.32%"
                            bottom="19.18%"
                            left="0%"
                            right="85.28%"
                            {...getOverrideProps(overrides, "Vector43681335")}
                          ></Icon>
                          <Icon
                            width="11.72px"
                            height="18.52px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 11.719673156738281,
                              height: 18.521455764770508,
                            }}
                            paths={[
                              {
                                d: "M11.7197 7.05122C11.7197 11.3418 9.65032 14.0984 6.5275 14.0984C5.73643 14.1443 4.94971 13.9421 4.25739 13.5149C3.56508 13.0877 2.99516 12.4527 2.61291 11.6828L2.55381 11.6828L2.55381 18.5215L0 18.5215L0 0.147009L2.47199 0.147009L2.47199 2.44344L2.51896 2.44344C2.91879 1.67719 3.49833 1.04554 4.19592 0.61573C4.89351 0.185915 5.68305 -0.025995 6.48053 0.00254546C9.63818 0.00256071 11.7197 2.77257 11.7197 7.05122ZM9.09473 7.05122C9.09473 4.25589 7.79299 2.41813 5.80684 2.41813C3.85558 2.41813 2.54311 4.2946 2.54311 7.05122C2.54311 9.83315 3.85558 11.6962 5.80684 11.6962C7.793 11.6962 9.09473 9.87187 9.09473 7.05122Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.25%"
                            bottom="0%"
                            left="16.26%"
                            right="72.38%"
                            {...getOverrideProps(overrides, "Vector43681336")}
                          ></Icon>
                          <Icon
                            width="11.72px"
                            height="18.52px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 11.719669342041016,
                              height: 18.52144432067871,
                            }}
                            paths={[
                              {
                                d: "M11.7197 7.0512C11.7197 11.3418 9.65032 14.0984 6.5275 14.0984C5.73643 14.1443 4.94971 13.9421 4.25739 13.5149C3.56508 13.0876 2.99516 12.4527 2.61291 11.6828L2.55381 11.6828L2.55381 18.5214L0 18.5214L0 0.146995L2.47194 0.146995L2.47194 2.44343L2.51891 2.44343C2.91875 1.67717 3.49829 1.04553 4.19588 0.615722C4.89346 0.185909 5.68301 -0.0259986 6.48049 0.0025462C9.63824 0.0025462 11.7197 2.77256 11.7197 7.0512ZM9.09473 7.0512C9.09473 4.25588 7.79299 2.41812 5.80684 2.41812C3.85558 2.41812 2.54311 4.29458 2.54311 7.0512C2.54311 9.83314 3.85558 11.6962 5.80684 11.6962C7.793 11.6962 9.09473 9.87186 9.09473 7.0512Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.25%"
                            bottom="0%"
                            left="29.54%"
                            right="59.1%"
                            {...getOverrideProps(overrides, "Vector43681337")}
                          ></Icon>
                          <Icon
                            width="13.09px"
                            height="19.57px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 13.09250259399414,
                              height: 19.568798065185547,
                            }}
                            paths={[
                              {
                                d: "M2.61425 13.9768C2.80347 15.8547 4.44743 17.0878 6.69391 17.0878C8.84647 17.0878 10.3951 15.8547 10.3951 14.1614C10.3951 12.6915 9.46109 11.8113 7.24949 11.2082L5.0379 10.6169C1.90435 9.77699 0.44961 8.15071 0.44961 5.51175C0.44961 2.24431 3.01551 0 6.65902 0C10.2649 0 12.7369 2.24431 12.8201 5.51175L10.2421 5.51175C10.0878 3.62187 8.68003 2.48111 6.62275 2.48111C4.56547 2.48111 3.15772 3.63529 3.15772 5.31517C3.15772 6.65402 4.05687 7.44183 6.25639 8.04492L8.13651 8.55723C11.6378 9.4761 13.0925 11.0369 13.0925 13.8069C13.0925 17.3498 10.5494 19.5688 6.50464 19.5688C2.72022 19.5688 0.165069 17.4019 0 13.9766L2.61425 13.9768Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="17.85%"
                            left="47.14%"
                            right="40.17%"
                            {...getOverrideProps(overrides, "Vector43681338")}
                          ></Icon>
                          <Icon
                            width="6.74px"
                            height="17.15px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 6.740824222564697,
                              height: 17.154573440551758,
                            }}
                            paths={[
                              {
                                d: "M4.37489 0L4.37489 3.26743L6.74082 3.26743L6.74082 5.51175L4.37489 5.51175L4.37489 13.1233C4.37489 14.3058 4.84862 14.8568 5.88866 14.8568C6.16953 14.8514 6.44995 14.8295 6.72875 14.7913L6.72875 17.0222C6.26114 17.1191 5.78587 17.163 5.31026 17.1532C2.79135 17.1532 1.809 16.1033 1.809 13.4256L1.809 5.51175L0 5.51175L0 3.26743L1.80897 3.26743L1.80897 0L4.37489 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="9.14%"
                            bottom="18.84%"
                            left="60.93%"
                            right="32.53%"
                            {...getOverrideProps(overrides, "Vector43681339")}
                          ></Icon>
                          <Icon
                            width="11.8px"
                            height="14.15px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 11.802836418151855,
                              height: 14.147943496704102,
                            }}
                            paths={[
                              {
                                d: "M0 7.07397C0 2.72981 2.30553 0 5.90075 0C9.50803 0 11.8028 2.72975 11.8028 7.07397C11.8028 11.4301 9.52011 14.1479 5.90075 14.1479C2.28267 14.1479 0 11.43 0 7.07397ZM9.2007 7.07397C9.2007 4.09397 7.97009 2.33516 5.90074 2.33516C3.83138 2.33516 2.60212 4.10737 2.60212 7.07397C2.60212 10.0659 3.83138 11.8113 5.90074 11.8113C7.97009 11.8113 9.2007 10.0659 9.2007 7.07397Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.15%"
                            bottom="18.46%"
                            left="68.79%"
                            right="19.76%"
                            {...getOverrideProps(overrides, "Vector43681340")}
                          ></Icon>
                          <Icon
                            width="6.36px"
                            height="13.95px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 6.36244535446167,
                              height: 13.95377254486084,
                            }}
                            paths={[
                              {
                                d: "M1.04843e-05 0.146881L2.43573 0.146881L2.43573 2.4969L2.49482 2.4969C2.65961 1.76294 3.04708 1.11431 3.59207 0.660038C4.13706 0.205763 4.80636 -0.0264618 5.48747 0.00239944C5.78177 0.00125909 6.07526 0.0367309 6.36245 0.108145L6.36245 2.75902C5.9909 2.63303 5.60332 2.57518 5.21499 2.58776C4.84399 2.57106 4.47412 2.64365 4.13078 2.80055C3.78745 2.95746 3.47877 3.19495 3.22595 3.49673C2.97314 3.79852 2.78216 4.15744 2.66614 4.54886C2.55012 4.94028 2.5118 5.35493 2.55381 5.76434L2.55381 13.9538L0 13.9538L1.04843e-05 0.146881Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.25%"
                            bottom="19.17%"
                            left="82.28%"
                            right="11.55%"
                            {...getOverrideProps(overrides, "Vector43681341")}
                          ></Icon>
                          <Icon
                            width="11.37px"
                            height="14.15px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 11.365355491638184,
                              height: 14.147943496704102,
                            }}
                            paths={[
                              {
                                d: "M11.2231 9.92144C10.8795 12.4278 8.68001 14.1479 5.86585 14.1479C2.2465 14.1479 0 11.4569 0 7.1395C0 2.80874 2.25857 0 5.75849 0C9.2007 0 11.3654 2.62406 11.3654 6.81037L11.3654 7.78137L2.57798 7.78137L2.57798 7.95263C2.53742 8.46076 2.59474 8.97265 2.74615 9.45431C2.89755 9.93596 3.13958 10.3764 3.45614 10.7462C3.77269 11.1161 4.15654 11.407 4.58218 11.5997C5.00783 11.7923 5.46555 11.8822 5.92491 11.8634C6.52841 11.9262 7.13404 11.7711 7.65171 11.4212C8.16939 11.0713 8.57146 10.5453 8.79812 9.92142L11.2231 9.92144ZM2.59005 5.80066L8.81019 5.80066C8.83311 5.34377 8.7707 4.88656 8.62685 4.45772C8.483 4.02887 8.26082 3.63765 7.97427 3.30861C7.68772 2.97957 7.34298 2.7198 6.96168 2.54562C6.58037 2.37144 6.17074 2.28661 5.75849 2.29644C5.34262 2.29368 4.93037 2.38235 4.54553 2.55733C4.1607 2.73232 3.8109 2.99014 3.51633 3.31593C3.22175 3.64173 2.98824 4.02903 2.82926 4.4555C2.67027 4.88197 2.58898 5.33914 2.59005 5.80066Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.15%"
                            bottom="18.46%"
                            left="88.98%"
                            right="0%"
                            {...getOverrideProps(overrides, "Vector43681342")}
                          ></Icon>
                        </View>
                      </View>
                    </View>
                    <View
                      padding="0px 0px 0px 0px"
                      width="95.02px"
                      height="9.7px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="21.09%"
                      bottom="63.01%"
                      left="29.81%"
                      right="12.41%"
                      {...getOverrideProps(overrides, "<Group>43681343")}
                    >
                      <View
                        padding="0px 0px 0px 0px"
                        width="95.02px"
                        height="9.7px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="0%"
                        bottom="0%"
                        left="0%"
                        right="0%"
                        {...getOverrideProps(overrides, "Group43681344")}
                      >
                        <Icon
                          width="6.85px"
                          height="9.11px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 6.848809242248535,
                            height: 9.109756469726562,
                          }}
                          paths={[
                            {
                              d: "M2.96178 0.0103255C3.49718 -0.0323123 4.03444 0.0574492 4.53481 0.273134C5.03517 0.48882 5.48617 0.825052 5.85526 1.25758C6.22435 1.69011 6.50233 2.20815 6.66916 2.77434C6.836 3.34053 6.88752 3.94075 6.82001 4.53171C6.82001 7.43874 5.40421 9.10976 2.96178 9.10976L0 9.10976L0 0.0103255L2.96178 0.0103255ZM1.27354 7.8229L2.81952 7.8229C3.2021 7.84828 3.58497 7.77867 3.94026 7.61913C4.29554 7.45959 4.6143 7.21415 4.87333 6.90066C5.13236 6.58717 5.32513 6.21353 5.43763 5.80694C5.55012 5.40035 5.5795 4.97103 5.52363 4.55025C5.57545 4.13113 5.54309 3.70448 5.42887 3.30087C5.31465 2.89725 5.12139 2.52667 4.86296 2.21568C4.60452 1.90469 4.28729 1.66098 3.93401 1.50202C3.58073 1.34306 3.20015 1.27278 2.81952 1.29622L1.27354 1.29622L1.27354 7.8229Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="4.51%"
                          bottom="1.54%"
                          left="0%"
                          right="92.79%"
                          {...getOverrideProps(overrides, "Vector43681345")}
                        ></Icon>
                        <Icon
                          width="5.86px"
                          height="7.13px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.863938331604004,
                            height: 7.132043838500977,
                          }}
                          paths={[
                            {
                              d: "M0.013804 3.56298C-0.0250984 3.1117 0.0214224 2.65638 0.150378 2.22623C0.279334 1.79607 0.487876 1.40058 0.762636 1.0651C1.0374 0.729631 1.37231 0.461589 1.74589 0.278168C2.11947 0.0947482 2.52346 2.70894e-15 2.93197 0C3.34048 2.70894e-15 3.74448 0.0947482 4.11806 0.278168C4.49163 0.461589 4.82654 0.729631 5.1013 1.0651C5.37606 1.40058 5.58461 1.79607 5.71357 2.22623C5.84252 2.65638 5.88904 3.1117 5.85013 3.56298C5.88978 4.01472 5.84381 4.47066 5.7152 4.90151C5.58658 5.33236 5.37816 5.72859 5.10333 6.06472C4.8285 6.40085 4.49334 6.66945 4.11939 6.85327C3.74543 7.03708 3.34096 7.13204 2.93197 7.13204C2.52297 7.13204 2.1185 7.03708 1.74455 6.85327C1.3706 6.66945 1.03544 6.40085 0.760608 6.06472C0.485777 5.72859 0.277357 5.33236 0.148743 4.90151C0.020128 4.47066 -0.0258367 4.01472 0.013804 3.56298ZM4.59402 3.56298C4.59402 2.07447 3.99146 1.204 2.93398 1.204C1.87247 1.204 1.27532 2.07447 1.27532 3.56299C1.27532 5.06342 1.87251 5.92719 2.93398 5.92719C3.99149 5.92717 4.59402 5.05745 4.59402 3.56298Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.17%"
                          left="8.68%"
                          right="85.15%"
                          {...getOverrideProps(overrides, "Vector43681346")}
                        ></Icon>
                        <Icon
                          width="8.58px"
                          height="6.87px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 8.575335502624512,
                            height: 6.866968154907227,
                          }}
                          paths={[
                            {
                              d: "M6.87637 6.86697L5.60953 6.86697L4.33062 1.80944L4.234 1.80944L2.96044 6.86697L1.70567 6.86697L0 0L1.23867 0L2.34715 5.2399L2.43841 5.2399L3.71062 0L4.88218 0L6.15439 5.2399L6.25101 5.2399L7.35413 0L8.57534 0L6.87637 6.86697Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="27.65%"
                          bottom="1.54%"
                          left="15.76%"
                          right="75.21%"
                          {...getOverrideProps(overrides, "Vector43681347")}
                        ></Icon>
                        <Icon
                          width="5.28px"
                          height="7.01px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.276923179626465,
                            height: 7.006281852722168,
                          }}
                          paths={[
                            {
                              d: "M1.04843e-05 0.139314L1.17559 0.139314L1.17559 1.23019L1.26685 1.23019C1.42166 0.838387 1.68276 0.509945 2.01377 0.290641C2.34477 0.0713367 2.72919 -0.0279017 3.11343 0.00675963C3.41452 -0.0183615 3.71684 0.0320177 3.998 0.154164C4.27916 0.276311 4.53195 0.467095 4.73764 0.712381C4.94334 0.957668 5.09667 1.25117 5.18627 1.57115C5.27587 1.89112 5.29944 2.22937 5.25525 2.56083L5.25525 7.0062L4.03404 7.0062L4.03404 2.90115C4.03404 1.79761 3.60192 1.24881 2.69875 1.24881C2.49433 1.23825 2.29026 1.27686 2.10055 1.362C1.91083 1.44714 1.73994 1.5768 1.5996 1.74208C1.45926 1.90737 1.35279 2.10438 1.28748 2.3196C1.22216 2.53483 1.19956 2.76318 1.22121 2.989L1.22121 7.00628L0 7.00628L1.04843e-05 0.139314Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.2%"
                          bottom="1.54%"
                          left="26.3%"
                          right="68.15%"
                          {...getOverrideProps(overrides, "Vector43681348")}
                        ></Icon>
                        <Icon
                          width="1.22px"
                          height="9.55px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 1.2212142944335938,
                            height: 9.547628402709961,
                          }}
                          paths={[
                            {
                              d: "M0 0L1.22121 0L1.22121 9.54763L0 9.54763L0 0Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="1.54%"
                          left="33.88%"
                          right="64.84%"
                          {...getOverrideProps(overrides, "Vector43681349")}
                        ></Icon>
                        <Icon
                          width="5.86px"
                          height="7.13px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.864542484283447,
                            height: 7.132131099700928,
                          }}
                          paths={[
                            {
                              d: "M0.0137697 3.5631C-0.0251029 3.1118 0.0214466 2.65646 0.150433 2.22629C0.279419 1.79611 0.487997 1.4006 0.762786 1.06512C1.03757 0.729642 1.37251 0.461596 1.74612 0.278173C2.11972 0.0947497 2.52374 0 2.93228 0C3.34081 0 3.74483 0.0947497 4.11844 0.278173C4.49204 0.461596 4.82697 0.729642 5.10176 1.06512C5.37655 1.4006 5.58512 1.79611 5.71411 2.22629C5.8431 2.65646 5.88964 3.1118 5.85077 3.5631C5.89036 4.01486 5.84436 4.47081 5.7157 4.90166C5.58705 5.33251 5.37859 5.72872 5.10373 6.06485C4.82888 6.40097 4.49369 6.66956 4.11973 6.85337C3.74576 7.03718 3.34128 7.13213 2.93228 7.13213C2.52327 7.13213 2.11878 7.03718 1.74482 6.85337C1.37085 6.66956 1.03567 6.40097 0.760809 6.06485C0.485952 5.72872 0.277499 5.33251 0.148845 4.90166C0.0201899 4.47081 -0.0258208 4.01486 0.0137697 3.5631ZM4.59398 3.5631C4.59398 2.0746 3.99142 1.20413 2.93395 1.20413C1.87243 1.20413 1.27529 2.0746 1.27529 3.56312C1.27529 5.06355 1.87247 5.92731 2.93395 5.92731C3.99144 5.9273 4.59398 5.05757 4.59398 3.5631Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.17%"
                          left="36.93%"
                          right="56.89%"
                          {...getOverrideProps(overrides, "Vector43681350")}
                        ></Icon>
                        <Icon
                          width="5.19px"
                          height="7.12px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.193515777587891,
                            height: 7.123778820037842,
                          }}
                          paths={[
                            {
                              d: "M0 5.05751C0 3.82142 0.829354 3.10882 2.30151 3.00754L3.97767 2.90032L3.97767 2.3076C3.97767 1.58233 3.54554 1.17279 2.71083 1.17279C2.0291 1.17279 1.55672 1.45053 1.42117 1.93603L0.238875 1.93603C0.36368 0.756538 1.36347 0 2.76719 0C4.31854 0 5.19352 0.857051 5.19352 2.3076L5.19352 6.99951L4.01793 6.99951L4.01793 6.03447L3.92131 6.03447C3.72518 6.38063 3.44979 6.66283 3.12325 6.85226C2.79671 7.04168 2.43071 7.13153 2.06264 7.11265C1.80287 7.14263 1.54032 7.11188 1.29192 7.02236C1.04352 6.93285 0.814778 6.78656 0.620441 6.59293C0.426105 6.3993 0.270493 6.16263 0.163629 5.89817C0.0567643 5.63371 0.00102347 5.34734 0 5.05751ZM3.97767 4.47074L3.97767 3.89663L2.46658 4.00386C1.61441 4.06715 1.22791 4.38883 1.22791 4.99421C1.22791 5.61224 1.71103 5.9719 2.37537 5.9719C2.57002 5.99377 2.76664 5.97196 2.95354 5.90777C3.14044 5.84358 3.31379 5.73833 3.46329 5.59827C3.61278 5.45821 3.73536 5.28621 3.82373 5.09251C3.9121 4.8988 3.96445 4.68735 3.97767 4.47074Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.25%"
                          left="44.44%"
                          right="50.09%"
                          {...getOverrideProps(overrides, "Vector43681351")}
                        ></Icon>
                        <Icon
                          width="5.78px"
                          height="9.66px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.77862548828125,
                            height: 9.662416458129883,
                          }}
                          paths={[
                            {
                              d: "M0 6.11119C0 3.94135 1.00516 2.56676 2.56858 2.56676C2.9553 2.54698 3.33911 2.64977 3.67513 2.86311C4.01116 3.07645 4.28552 3.39153 4.46616 3.77151L4.55742 3.77151L4.55742 0L5.77863 0L5.77863 9.54763L4.60842 9.54763L4.60842 8.4627L4.51179 8.4627C4.3172 8.84012 4.03327 9.15127 3.69075 9.36246C3.34822 9.57365 2.96016 9.67682 2.56859 9.6608C0.994425 9.66087 0 8.28625 0 6.11119ZM1.26152 6.11119C1.26152 7.56769 1.88019 8.44412 2.91486 8.44412C3.94414 8.44412 4.58027 7.55505 4.58027 6.11716C4.58027 4.68597 3.93746 3.78423 2.91486 3.78423C1.88685 3.78423 1.26152 4.66661 1.26152 6.11119Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0.35%"
                          left="51.6%"
                          right="42.32%"
                          {...getOverrideProps(overrides, "Vector43681352")}
                        ></Icon>
                        <Icon
                          width="5.86px"
                          height="7.13px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.863938331604004,
                            height: 7.132043838500977,
                          }}
                          paths={[
                            {
                              d: "M0.0138047 3.56298C-0.0250977 3.1117 0.0214179 2.65638 0.150374 2.22623C0.279329 1.79607 0.487877 1.40058 0.762637 1.0651C1.0374 0.729631 1.3723 0.461589 1.74588 0.278168C2.11946 0.0947482 2.52346 0 2.93197 0C3.34048 2.70894e-15 3.74447 0.0947482 4.11805 0.278168C4.49163 0.461589 4.82653 0.729631 5.10129 1.0651C5.37605 1.40058 5.5846 1.79607 5.71356 2.22623C5.84251 2.65638 5.88904 3.1117 5.85014 3.56298C5.88978 4.01472 5.84381 4.47066 5.71519 4.90151C5.58658 5.33236 5.37816 5.72859 5.10333 6.06472C4.8285 6.40085 4.49334 6.66945 4.11938 6.85327C3.74543 7.03708 3.34096 7.13204 2.93197 7.13204C2.52298 7.13204 2.1185 7.03708 1.74455 6.85327C1.37059 6.66945 1.03543 6.40085 0.760603 6.06472C0.485773 5.72859 0.277353 5.33236 0.148738 4.90151C0.0201235 4.47066 -0.025836 4.01472 0.0138047 3.56298ZM4.59402 3.56298C4.59402 2.07447 3.99146 1.204 2.93398 1.204C1.87247 1.204 1.27532 2.07447 1.27532 3.56299C1.27532 5.06342 1.87251 5.92719 2.93398 5.92719C3.99147 5.92717 4.59402 5.05745 4.59402 3.56298Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.17%"
                          left="62.98%"
                          right="30.85%"
                          {...getOverrideProps(overrides, "Vector43681353")}
                        ></Icon>
                        <Icon
                          width="5.28px"
                          height="7.01px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.276924133300781,
                            height: 7.006281852722168,
                          }}
                          paths={[
                            {
                              d: "M0 0.139314L1.17559 0.139314L1.17559 1.23019L1.26685 1.23019C1.42166 0.838387 1.68276 0.509945 2.01377 0.290641C2.34477 0.0713367 2.72919 -0.0279017 3.11344 0.00675963C3.41452 -0.0183615 3.71684 0.0320177 3.998 0.154164C4.27916 0.276311 4.53195 0.467095 4.73765 0.712381C4.94334 0.957668 5.09667 1.25117 5.18627 1.57115C5.27587 1.89112 5.29944 2.22937 5.25525 2.56083L5.25525 7.0062L4.03404 7.0062L4.03404 2.90115C4.03404 1.79761 3.60193 1.24881 2.69876 1.24881C2.49434 1.23825 2.29026 1.27686 2.10055 1.362C1.91083 1.44714 1.73994 1.5768 1.5996 1.74208C1.45926 1.90737 1.35279 2.10438 1.28748 2.3196C1.22216 2.53483 1.19956 2.76318 1.22121 2.989L1.22121 7.00628L0 7.00628L0 0.139314Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.2%"
                          bottom="1.54%"
                          left="70.86%"
                          right="23.58%"
                          {...getOverrideProps(overrides, "Vector43681354")}
                        ></Icon>
                        <Icon
                          width="3.55px"
                          height="8.61px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 3.545558214187622,
                            height: 8.614602088928223,
                          }}
                          paths={[
                            {
                              d: "M2.2049 0L2.2049 1.74094L3.54556 1.74094L3.54556 2.88246L2.2049 2.88246L2.2049 6.41349C2.2049 7.13281 2.47196 7.44778 3.07988 7.44778C3.23551 7.44723 3.391 7.43678 3.54556 7.4165L3.54556 8.54535C3.32625 8.58889 3.10404 8.61206 2.88126 8.6146C1.52316 8.6146 0.982339 8.08442 0.982339 6.76048L0.982339 2.8824L0 2.8824L0 1.74088L0.982339 1.74088L0.982339 0L2.2049 0Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="10.01%"
                          bottom="1.15%"
                          left="81.34%"
                          right="14.93%"
                          {...getOverrideProps(overrides, "Vector43681355")}
                        ></Icon>
                        <Icon
                          width="5.34px"
                          height="9.55px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.343110084533691,
                            height: 9.547642707824707,
                          }}
                          paths={[
                            {
                              d: "M0 0L1.21048 0L1.21048 3.78421L1.30709 3.78421C1.46943 3.38875 1.7377 3.05845 2.07534 2.83832C2.41298 2.61819 2.80356 2.51893 3.19387 2.55408C3.49339 2.536 3.79282 2.59149 4.07079 2.71661C4.34876 2.84172 4.59843 3.03337 4.80198 3.27789C5.00552 3.5224 5.15794 3.81375 5.24834 4.13116C5.33874 4.44857 5.3649 4.7842 5.32496 5.11412L5.32496 9.54764L4.10253 9.54764L4.10253 5.44844C4.10253 4.3516 3.64217 3.79611 2.77932 3.79611C2.56943 3.777 2.3583 3.80902 2.16073 3.88993C1.96315 3.97084 1.78389 4.09867 1.63548 4.2645C1.48708 4.43032 1.37311 4.63013 1.30157 4.84994C1.23002 5.06975 1.20261 5.30425 1.22127 5.53705L1.22127 9.54761L5.24216e-05 9.54761L0 0Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="1.54%"
                          left="86.82%"
                          right="7.55%"
                          {...getOverrideProps(overrides, "Vector43681356")}
                        ></Icon>
                        <Icon
                          width="5.64px"
                          height="7.15px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 5.639939785003662,
                            height: 7.148416042327881,
                          }}
                          paths={[
                            {
                              d: "M5.56614 5.14549C5.40022 5.77309 5.04073 6.31597 4.55057 6.67915C4.0604 7.04233 3.47078 7.20268 2.88484 7.13216C2.47716 7.1441 2.07202 7.05745 1.69755 6.87822C1.32308 6.69899 0.988237 6.43147 0.716269 6.09424C0.444301 5.757 0.241726 5.35813 0.122594 4.9253C0.00346254 4.49247 -0.0293623 4.03606 0.0263899 3.58774C-0.0278979 3.13804 0.00550497 2.68064 0.124355 2.24652C0.243206 1.81239 0.444727 1.41167 0.715263 1.07148C0.985798 0.731297 1.31904 0.459585 1.69242 0.274744C2.06581 0.0899021 2.47062 -0.00375376 2.87946 0.000115163C4.60124 0.000115163 5.63994 1.30551 5.63994 3.46186L5.63994 3.93475L1.27041 3.93475L1.27041 4.0107C1.25129 4.2627 1.27983 4.51635 1.35421 4.75539C1.42858 4.99444 1.54715 5.21359 1.70231 5.39881C1.85747 5.58403 2.0458 5.73123 2.25521 5.83096C2.46463 5.93069 2.69051 5.98075 2.91838 5.97795C3.2105 6.01686 3.50645 5.95849 3.76859 5.81025C4.03074 5.66201 4.24723 5.4306 4.39054 5.14545L5.56614 5.14549ZM1.27038 2.93246L4.39588 2.93246C4.41127 2.70199 4.38305 2.4705 4.31302 2.25285C4.243 2.03521 4.13272 1.83622 3.98928 1.66869C3.84583 1.50116 3.67239 1.36878 3.48009 1.28006C3.2878 1.19135 3.08091 1.14825 2.87272 1.15355C2.6615 1.1506 2.45189 1.19461 2.25625 1.28298C2.0606 1.37134 1.88288 1.50228 1.73355 1.66807C1.58422 1.83385 1.46632 2.03114 1.38677 2.24829C1.30723 2.46545 1.26763 2.69807 1.27038 2.93246Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0%"
                          left="94.06%"
                          right="0%"
                          {...getOverrideProps(overrides, "Vector43681357")}
                        ></Icon>
                      </View>
                    </View>
                  </View>
                </View>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="230px"
                height="61px"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 55743681358")}
              >
                <Image
                  width="204px"
                  height="61px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={playstoresrc}
                  onClick={() => {
                    googleplaybadgeTwoFourThreeSixEightOneThreeFiveNineOnClick();
                  }}
                  {...getOverrideProps(
                    overrides,
                    "google-play-badge 243681359"
                  )}
                ></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </View>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "What")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="528px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="120px 120px 120px 120px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left4242712")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage4242713")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message4242714")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(177,85,231,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What is Rundezvous"
                {...getOverrideProps(overrides, "Eyebrow")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={text1str}
                {...getOverrideProps(overrides, "Heading4242716")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={text2str}
                {...getOverrideProps(overrides, "Body4242717")}
              ></Text>
            </Flex>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              isDisabled={false}
              variation="primary"
              backgroundColor={buttoncolor}
              children="Get started"
              onClick={() => {
                buttonFourTwoFourTwoSevenOneEightOnClick();
              }}
              {...getOverrideProps(overrides, "Button4242718")}
            ></Button>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="528px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="30px 20px 30px 20px"
          display="flex"
          children={right4242719}
          {...getOverrideProps(overrides, "Right4242719")}
        ></Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="30px 48px 30px 48px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "How")}
      >
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 0px 24px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 136")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 3744259726")}
          >
            <Flex
              gap="0"
              direction="row"
              width="50px"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="8px 8px 8px 8px"
              backgroundColor={secondaryColor}
              display="flex"
              {...getOverrideProps(overrides, "Frame 4304259727")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="1"
                {...getOverrideProps(overrides, "1")}
              ></Text>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={step1titlestr}
              {...getOverrideProps(overrides, "Input your weekly schedule")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace.  The more flexible you can be with your schedule the more runs you’ll get matched with. All changes need to be done by 18:00 on Wednesday evening."
              {...getOverrideProps(
                overrides,
                "Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace. The more flexible you can be with your schedule the more runs you\u2019ll get matched with. All changes need to be done by 18:00 on Wednesday evening."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 373")}
          >
            <Flex
              gap="0"
              direction="row"
              width="50px"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="8px 8px 8px 8px"
              backgroundColor={secondaryColor}
              display="flex"
              {...getOverrideProps(overrides, "Frame 4304259732")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="2"
                {...getOverrideProps(overrides, "2")}
              ></Text>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={step2titlestr}
              {...getOverrideProps(overrides, "Offer you running events.")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={step2textstr}
              {...getOverrideProps(
                overrides,
                "We then use our algorithm to take in this information and create dynamic running events which best suit yours and other people\u2019s schedules. These will be located as close to your home location as possible - the more people who sign-up in your area, the more flexible this will become. You will receive a list of suggested runs for the following week which you can accept or reject at that point by 18:00 on Friday."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 393")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="700px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            borderRadius="8px"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 3744259737")}
          >
            <Flex
              gap="0"
              direction="row"
              width="50px"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="8px 8px 8px 8px"
              backgroundColor={secondaryColor}
              display="flex"
              {...getOverrideProps(overrides, "Frame 4304259738")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="3"
                {...getOverrideProps(overrides, "3")}
              ></Text>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={step3titlestr}
              {...getOverrideProps(
                overrides,
                "If enough people sign-up - Game on!"
              )}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={step3textstr}
              {...getOverrideProps(
                overrides,
                "If at least 3 people accept the runs you accepted, you will receive a confirmation email that the run is going ahead with full details of meeting point and time. You just turn up then and there, meet your group and head off for a casual run. Don\u2019t forget to share group pictures on your social media with #Rundezvous!"
              )}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="48px 48px 48px 48px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Why")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 432")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color={buttoncolor}
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Benefits"
            {...getOverrideProps(overrides, "Benefits")}
          ></Text>
          <Text
            //fontFamily="Inter"
            fontSize="32px"
            fontWeight="500"
            color="rgba(13,26,38,1)"
            lineHeight="40px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={benefitstitlestr}
            {...getOverrideProps(overrides, "Why run in a group?")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 63")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 24px 0px 24px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 518")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 3904259964")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4294259965")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="24px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit1titlestr}
                  {...getOverrideProps(overrides, "Physical Health.")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 1384259969")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit11textstr}
                  {...getOverrideProps(
                    overrides,
                    "Regular running is one of the best ways to stay physically healthy."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 1394259973")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit12textstr}
                  {...getOverrideProps(
                    overrides,
                    "Low levels of physical activity Studies\u00A0have shown running a bit and often halves your heart attack risk."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 137")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit13textstr}
                  {...getOverrideProps(
                    overrides,
                    "One of the biggest challenges is staying consistent which is where Rundezvous comes in."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 24px 0px 24px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 519")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 3904259982")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4294259983")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="24px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit2titlestr}
                  {...getOverrideProps(overrides, "Mental Health.")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 1384259987")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit21textstr}
                  {...getOverrideProps(
                    overrides,
                    "Running has innate mental health benefits, as does socialising with others."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 1394259991")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit22textstr}
                  {...getOverrideProps(
                    overrides,
                    "Rundezvous gives its users the opportunity to join these two activities together."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 24px 0px 24px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 520")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 391")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42942591001")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="24px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit3titlestr}
                  {...getOverrideProps(overrides, "Make Connections.")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13842591005")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit31textstr}
                  {...getOverrideProps(
                    overrides,
                    "In an ever increasingly online and virtual world, connections IRL (In Real Life) are more and more difficult to come by."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13942591009")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="312px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={benefit32textstr}
                  {...getOverrideProps(
                    overrides,
                    "Rundezvous makes these connections for you and groups you with like-minded people who want to go for a casual run and maybe have a chat too."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "Action")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="720px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 20px 30px 20px"
          display="flex"
          {...getOverrideProps(overrides, "Right42591045")}
        >
          <Image
            width="720px"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={image3src}
            {...getOverrideProps(overrides, "image42591046")}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="720px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="120px 120px 120px 120px"
          display="flex"
          {...getOverrideProps(overrides, "Left42591038")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage42591039")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message42591040")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={getstartedtitlestr}
                {...getOverrideProps(overrides, "Heading42591042")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={getstartedtextstr}
                {...getOverrideProps(overrides, "Body42591043")}
              ></Text>
            </Flex>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              isDisabled={false}
              variation="primary"
              backgroundColor={buttoncolor}
              children="Get started"
              onClick={() => {
                buttonFourTwoFiveNineOneZeroFourFourOnClick();
              }}
              {...getOverrideProps(overrides, "Button42591044")}
            ></Button>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message43681360")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Now available on mobile"
                {...getOverrideProps(overrides, "Heading43681361")}
              ></Text>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 55843681363")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="112px"
                  height="39px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  onClick={() => {
                    frameFiveFiveSixFourThreeSixEightOneThreeSixFourOnClick();
                  }}
                  {...getOverrideProps(overrides, "Frame 55643681364")}
                >
                  <View
                    width="112px"
                    height="39px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "appstoreblack 143681365")}
                  >
                    <View
                      padding="0px 0px 0px 0px"
                      width="112px"
                      height="39px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="0%"
                      bottom="0%"
                      left="0%"
                      right="0%"
                      {...getOverrideProps(overrides, "Group43681366")}
                    >
                      <View
                        padding="0px 0px 0px 0px"
                        width="112px"
                        height="39px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="0%"
                        bottom="0%"
                        left="0%"
                        right="0%"
                        {...getOverrideProps(overrides, "Group43681367")}
                      >
                        <View
                          padding="0px 0px 0px 0px"
                          width="112px"
                          height="39px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0%"
                          left="0%"
                          right="0%"
                          {...getOverrideProps(overrides, "Group43681368")}
                        >
                          <Icon
                            width="112px"
                            height="39px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 112,
                              height: 39.000125885009766,
                            }}
                            paths={[
                              {
                                d: "M103.081 0.00012675L8.92402 0.00012675C8.5808 0.00012675 8.24171 0.00012675 7.8994 0.00207675C7.61286 0.00402675 7.3286 0.0096915 7.03931 0.0144592C6.41083 0.0221635 5.78382 0.0797611 5.16375 0.186751C4.54454 0.296071 3.94474 0.50217 3.38461 0.798076C2.82517 1.09649 2.314 1.48424 1.86963 1.94727C1.42293 2.409 1.05057 2.94269 0.766873 3.52783C0.482391 4.11181 0.285129 4.73759 0.181903 5.38357C0.0776944 6.02873 0.021619 6.68133 0.0141797 7.33552C0.00549405 7.63445 0.00457681 7.93438 0 8.23336L0 30.7698C0.00457681 31.0725 0.00549405 31.3658 0.0141797 31.6686C0.0216213 32.3228 0.0776967 32.9753 0.181903 33.6205C0.284844 34.2668 0.482117 34.893 0.766873 35.4772C1.05044 36.0604 1.42286 36.5919 1.86963 37.0511C2.31231 37.5162 2.82382 37.9042 3.38461 38.2003C3.94473 38.497 4.54448 38.7044 5.16375 38.8154C5.78393 38.9215 6.41087 38.9791 7.03931 38.9877C7.3286 38.9944 7.61286 38.9982 7.8994 38.9982C8.2417 39.0001 8.58082 39.0001 8.92402 39.0001L103.081 39.0001C103.417 39.0001 103.759 39.0001 104.096 38.9982C104.381 38.9982 104.673 38.9944 104.958 38.9877C105.586 38.9796 106.211 38.922 106.83 38.8154C107.452 38.7036 108.054 38.4963 108.616 38.2003C109.177 37.904 109.688 37.516 110.13 37.0511C110.576 36.5901 110.949 36.059 111.236 35.4772C111.519 34.8925 111.714 34.2664 111.815 33.6205C111.92 32.9752 111.978 32.3228 111.989 31.6686C111.993 31.3658 111.993 31.0725 111.993 30.7698C112 30.4155 112 30.0633 112 29.7033L112 9.29785C112 8.9408 112 8.5866 111.993 8.23336C111.993 7.93438 111.993 7.63445 111.989 7.33548C111.978 6.68124 111.92 6.02878 111.815 5.38353C111.714 4.73793 111.518 4.11219 111.236 3.52779C110.658 2.35494 109.742 1.40025 108.616 0.797989C108.054 0.502805 107.452 0.296761 106.83 0.186664C106.212 0.079202 105.586 0.0215843 104.958 0.0143227C104.673 0.00956475 104.381 0.00385125 104.096 0.00195C103.759 0 103.417 0.00012675 103.081 0.00012675Z",
                                fill: "rgba(166,166,166,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="0%"
                            left="0%"
                            right="0%"
                            {...getOverrideProps(overrides, "Vector43681369")}
                          ></Icon>
                          <Icon
                            width="110.37px"
                            height="37.3px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 110.36581420898438,
                              height: 37.29570007324219,
                            }}
                            paths={[
                              {
                                d: "M7.08687 37.2938C6.8017 37.2938 6.52343 37.2899 6.2405 37.2833C5.65439 37.2754 5.06965 37.2222 4.49107 37.1243C3.95158 37.0275 3.42895 36.8475 2.94044 36.5901C2.4564 36.3349 2.01492 36.0002 1.63291 35.5989C1.24537 35.2024 0.922725 34.7424 0.677761 34.2373C0.430098 33.7289 0.2587 33.184 0.169539 32.6216C0.0732518 32.0172 0.0211567 31.406 0.0137024 30.7934C0.00776844 30.5878 0 29.9032 0 29.9032L0 7.38011C0 7.38011 0.00827387 6.70598 0.0137492 6.50793C0.0208866 5.8963 0.0726789 5.28611 0.168678 4.68266C0.258004 4.11864 0.429534 3.57215 0.677331 3.06211C0.921395 2.55737 1.24225 2.09709 1.62745 1.69911C2.01223 1.29736 2.45512 0.960964 2.93998 0.702205C3.42737 0.445666 3.94901 0.266889 4.48741 0.171863C5.06789 0.0729652 5.65466 0.0194962 6.2428 0.0119048L7.08733 0L103.274 0L104.129 0.0123825C104.711 0.0195967 105.293 0.0725887 105.868 0.170908C106.412 0.267126 106.939 0.44715 107.432 0.705061C108.403 1.22654 109.194 2.05156 109.692 3.06449C109.936 3.57101 110.105 4.11304 110.193 4.67219C110.29 5.28059 110.345 5.89557 110.356 6.51222C110.358 6.78834 110.358 7.08493 110.358 7.38011C110.366 7.74573 110.366 8.09374 110.366 8.4446L110.366 28.8501C110.366 29.2043 110.366 29.5499 110.358 29.8984C110.358 30.2154 110.358 30.5059 110.355 30.8048C110.344 31.4105 110.29 32.0145 110.195 32.612C110.107 33.1785 109.937 33.7277 109.689 34.2402C109.443 34.7399 109.122 35.1958 108.739 35.5913C108.356 35.9947 107.914 36.3314 107.429 36.5882C106.937 36.8476 106.411 37.0283 105.868 37.1243C105.289 37.2227 104.705 37.2759 104.118 37.2833C103.844 37.2899 103.557 37.2938 103.278 37.2938L102.264 37.2957L7.08687 37.2938Z",
                                fill: "rgba(0,0,0,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="2.19%"
                            bottom="2.18%"
                            left="0.73%"
                            right="0.73%"
                            {...getOverrideProps(overrides, "Vector43681370")}
                          ></Icon>
                        </View>
                        <View
                          padding="0px 0px 0px 0px"
                          width="93.14px"
                          height="24.15px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="21.8%"
                          bottom="16.27%"
                          left="8.33%"
                          right="8.5%"
                          {...getOverrideProps(overrides, "<Group>43681371")}
                        >
                          <View
                            padding="0px 0px 0px 0px"
                            width="16.58px"
                            height="21.23px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="12.09%"
                            left="0%"
                            right="82.2%"
                            {...getOverrideProps(overrides, "<Group>43681372")}
                          >
                            <View
                              padding="0px 0px 0px 0px"
                              width="16.58px"
                              height="21.23px"
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="0%"
                              bottom="0%"
                              left="0%"
                              right="0%"
                              {...getOverrideProps(
                                overrides,
                                "<Group>43681373"
                              )}
                            >
                              <Icon
                                width="16.58px"
                                height="16.11px"
                                viewBox={{
                                  minX: 0,
                                  minY: 0,
                                  width: 16.58186912536621,
                                  height: 16.110151290893555,
                                }}
                                paths={[
                                  {
                                    d: "M13.8496 6.16948C13.8597 5.35569 14.0672 4.55777 14.4528 3.84996C14.8384 3.14216 15.3897 2.54747 16.0552 2.12122C15.6324 1.49219 15.0746 0.974524 14.4262 0.609335C13.7777 0.244147 13.0565 0.041499 12.3197 0.017485C10.748 -0.154368 9.22431 0.997195 8.42341 0.997195C7.607 0.997195 6.37388 0.0345475 5.04602 0.0630078C4.18712 0.0919147 3.35005 0.352097 2.61638 0.818204C1.8827 1.28431 1.27745 1.94045 0.859573 2.72268C-0.950561 5.98739 0.399635 10.7854 2.13359 13.4244C3.00113 14.7166 4.01503 16.16 5.34169 16.1088C6.63992 16.0527 7.12478 15.2465 8.69185 15.2465C10.2444 15.2465 10.6993 16.1088 12.0529 16.0763C13.4459 16.0527 14.3236 14.7783 15.1607 13.4739C15.784 12.5531 16.2637 11.5355 16.5819 10.4587C15.7725 10.1021 15.0819 9.50521 14.596 8.74247C14.1101 7.97974 13.8505 7.08488 13.8496 6.16948Z",
                                    fill: "rgba(255,255,255,1)",
                                    fillRule: "nonzero",
                                  },
                                ]}
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="24.12%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                {...getOverrideProps(
                                  overrides,
                                  "<Path>43681374"
                                )}
                              ></Icon>
                              <Icon
                                width="4.1px"
                                height="4.9px"
                                viewBox={{
                                  minX: 0,
                                  minY: 0,
                                  width: 4.095088481903076,
                                  height: 4.895687103271484,
                                }}
                                paths={[
                                  {
                                    d: "M3.03863 3.40335C3.79818 2.4535 4.17238 1.23263 4.08177 0C2.92134 0.126965 1.84943 0.70471 1.07962 1.61812C0.703233 2.06434 0.414963 2.58347 0.231286 3.14582C0.0476095 3.70817 -0.0278704 4.30272 0.00916025 4.89547C0.589582 4.9017 1.16379 4.77065 1.68855 4.51219C2.2133 4.25373 2.67492 3.8746 3.03863 3.40335Z",
                                    fill: "rgba(255,255,255,1)",
                                    fillRule: "nonzero",
                                  },
                                ]}
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="76.94%"
                                left="49.78%"
                                right="25.52%"
                                {...getOverrideProps(
                                  overrides,
                                  "<Path>43681375"
                                )}
                              ></Icon>
                            </View>
                          </View>
                          <View
                            padding="0px 0px 0px 0px"
                            width="70.25px"
                            height="15.23px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="36.94%"
                            bottom="0%"
                            left="24.57%"
                            right="0%"
                            {...getOverrideProps(overrides, "Group43681376")}
                          >
                            <Icon
                              width="10.34px"
                              height="12.11px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 10.3420991897583,
                                height: 12.107549667358398,
                              }}
                              paths={[
                                {
                                  d: "M7.37063 8.83501L2.94039 8.83501L1.87648 12.1075L0 12.1075L4.19626 0L6.14585 0L10.3421 12.1075L8.43362 12.1075L7.37063 8.83501ZM3.39923 7.3249L6.91092 7.3249L5.17977 2.01381L5.13133 2.01381L3.39923 7.3249Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="1.33%"
                              bottom="19.17%"
                              left="0%"
                              right="85.28%"
                              {...getOverrideProps(overrides, "Vector43681377")}
                            ></Icon>
                            <Icon
                              width="7.98px"
                              height="11.84px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.982139587402344,
                                height: 11.84158706665039,
                              }}
                              paths={[
                                {
                                  d: "M7.98214 4.50815C7.98214 7.2513 6.57272 9.01372 4.44581 9.01372C3.90702 9.04308 3.37119 8.91379 2.89966 8.64065C2.42813 8.36752 2.03997 7.96158 1.77962 7.46932L1.73937 7.46932L1.73937 11.8416L0 11.8416L0 0.0939895L1.68365 0.0939895L1.68365 1.5622L1.71564 1.5622C1.98796 1.0723 2.38268 0.668463 2.8578 0.393663C3.33291 0.118864 3.87066 -0.0166198 4.41382 0.00162742C6.56446 0.00163717 7.98214 1.77263 7.98214 4.50815ZM6.19432 4.50815C6.19432 2.72098 5.30772 1.54602 3.95497 1.54602C2.62599 1.54602 1.73208 2.74573 1.73208 4.50815C1.73208 6.28677 2.62599 7.47791 3.95497 7.47791C5.30773 7.47791 6.19432 6.31152 6.19432 4.50815Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.25%"
                              bottom="0%"
                              left="16.26%"
                              right="72.38%"
                              {...getOverrideProps(overrides, "Vector43681378")}
                            ></Icon>
                            <Icon
                              width="7.98px"
                              height="11.84px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.982135772705078,
                                height: 11.841578483581543,
                              }}
                              paths={[
                                {
                                  d: "M7.98214 4.50815C7.98214 7.25129 6.57272 9.01371 4.44581 9.01371C3.90702 9.04307 3.37119 8.91378 2.89966 8.64065C2.42813 8.36751 2.03997 7.96157 1.77962 7.46931L1.73937 7.46931L1.73937 11.8416L0 11.8416L0 0.0939807L1.68361 0.0939807L1.68361 1.56219L1.7156 1.56219C1.98792 1.07229 2.38264 0.668456 2.85776 0.393658C3.33288 0.11886 3.87063 -0.016622 4.41379 0.0016279C6.5645 0.0016279 7.98214 1.77262 7.98214 4.50815ZM6.19432 4.50815C6.19432 2.72097 5.30772 1.54601 3.95497 1.54601C2.62599 1.54601 1.73208 2.74572 1.73208 4.50815C1.73208 6.28676 2.62599 7.4779 3.95497 7.4779C5.30773 7.4779 6.19432 6.31152 6.19432 4.50815Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.25%"
                              bottom="0%"
                              left="29.53%"
                              right="59.1%"
                              {...getOverrideProps(overrides, "Vector43681379")}
                            ></Icon>
                            <Icon
                              width="8.92px"
                              height="12.51px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 8.917157173156738,
                                height: 12.511198043823242,
                              }}
                              paths={[
                                {
                                  d: "M1.78053 8.93596C1.90941 10.1366 3.0291 10.925 4.55915 10.925C6.02523 10.925 7.08 10.1366 7.08 9.05399C7.08 8.11421 6.44384 7.55151 4.93755 7.16588L3.43126 6.78787C1.29703 6.25086 0.306224 5.21111 0.306224 3.5239C0.306224 1.43489 2.05383 0 4.53538 0C6.99132 0 8.67496 1.43489 8.73163 3.5239L6.97579 3.5239C6.87068 2.31562 5.91187 1.58628 4.51068 1.58628C3.10949 1.58628 2.15069 2.3242 2.15069 3.39823C2.15069 4.25421 2.76309 4.75789 4.26115 5.14348L5.54169 5.47102C7.92635 6.05849 8.91716 7.05637 8.91716 8.82734C8.91716 11.0925 7.18509 12.5112 4.43024 12.5112C1.85271 12.5112 0.112427 11.1258 0 8.93587L1.78053 8.93596Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="0%"
                              bottom="17.85%"
                              left="47.14%"
                              right="40.17%"
                              {...getOverrideProps(overrides, "Vector43681380")}
                            ></Icon>
                            <Icon
                              width="4.59px"
                              height="10.97px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 4.591100692749023,
                                height: 10.96767807006836,
                              }}
                              paths={[
                                {
                                  d: "M2.97969 0L2.97969 2.08902L4.5911 2.08902L4.5911 3.5239L2.97969 3.5239L2.97969 8.39033C2.97969 9.14634 3.30234 9.49864 4.0107 9.49864C4.202 9.49516 4.39299 9.48116 4.58287 9.45671L4.58287 10.883C4.2644 10.945 3.94069 10.9731 3.61676 10.9668C1.90116 10.9668 1.23209 10.2956 1.23209 8.58359L1.23209 3.5239L0 3.5239L0 2.08902L1.23207 2.08902L1.23207 0L2.97969 0Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="9.15%"
                              bottom="18.84%"
                              left="60.93%"
                              right="32.53%"
                              {...getOverrideProps(overrides, "Vector43681381")}
                            ></Icon>
                            <Icon
                              width="8.04px"
                              height="9.05px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 8.038780212402344,
                                height: 9.045406341552734,
                              }}
                              paths={[
                                {
                                  d: "M0 4.5227C0 1.74529 1.57027 0 4.01893 0C6.47581 0 8.03878 1.74525 8.03878 4.5227C8.03878 7.30774 6.48404 9.04541 4.01893 9.04541C1.5547 9.04541 0 7.30773 0 4.5227ZM6.26649 4.5227C6.26649 2.61746 5.42834 1.49297 4.01893 1.49297C2.60951 1.49297 1.77227 2.62603 1.77227 4.5227C1.77227 6.43557 2.60951 7.55149 4.01893 7.55149C5.42834 7.55149 6.26649 6.43557 6.26649 4.5227Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.15%"
                              bottom="18.46%"
                              left="68.79%"
                              right="19.76%"
                              {...getOverrideProps(overrides, "Vector43681382")}
                            ></Icon>
                            <Icon
                              width="4.33px"
                              height="8.92px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 4.333390712738037,
                                height: 8.9212646484375,
                              }}
                              paths={[
                                {
                                  d: "M7.14076e-06 0.0939073L1.65895 0.0939073L1.65895 1.59638L1.69919 1.59638C1.81143 1.12713 2.07533 0.712429 2.44652 0.421991C2.8177 0.131553 3.27356 -0.0169182 3.73745 0.00153407C3.9379 0.000804992 4.13779 0.0234837 4.33339 0.0691422L4.33339 1.76396C4.08034 1.68341 3.81636 1.64643 3.55187 1.65447C3.29918 1.64379 3.04727 1.6902 2.81343 1.79052C2.57959 1.89083 2.36935 2.04267 2.19716 2.23562C2.02497 2.42856 1.8949 2.65804 1.81588 2.90829C1.73686 3.15854 1.71076 3.42364 1.73937 3.6854L1.73937 8.92126L0 8.92126L7.14076e-06 0.0939073Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.25%"
                              bottom="19.17%"
                              left="82.28%"
                              right="11.55%"
                              {...getOverrideProps(overrides, "Vector43681383")}
                            ></Icon>
                            <Icon
                              width="7.74px"
                              height="9.05px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.740817546844482,
                                height: 9.045406341552734,
                              }}
                              paths={[
                                {
                                  d: "M7.64392 6.34321C7.40994 7.94567 5.91186 9.04541 3.99517 9.04541C1.53006 9.04541 0 7.32487 0 4.5646C0 1.79575 1.53829 0 3.92205 0C6.2665 0 7.74082 1.67768 7.74082 4.35417L7.74082 4.97498L1.75583 4.97498L1.75583 5.08447C1.72821 5.40934 1.76725 5.73661 1.87037 6.04456C1.97349 6.3525 2.13833 6.63407 2.35394 6.87055C2.56954 7.10703 2.83097 7.29303 3.12088 7.41617C3.41078 7.53932 3.72252 7.5968 4.03539 7.58481C4.44643 7.62493 4.85891 7.52576 5.2115 7.30205C5.56408 7.07835 5.83793 6.74206 5.9923 6.3432L7.64392 6.34321ZM1.76405 3.70862L6.00052 3.70862C6.01614 3.41651 5.97362 3.1242 5.87565 2.85002C5.77768 2.57584 5.62636 2.32571 5.43119 2.11534C5.23602 1.90497 5.00122 1.73889 4.74152 1.62753C4.48182 1.51617 4.20282 1.46193 3.92205 1.46821C3.6388 1.46645 3.35802 1.52314 3.09591 1.63502C2.83381 1.74689 2.59556 1.91173 2.39493 2.12002C2.1943 2.32832 2.03526 2.57594 1.92698 2.8486C1.81869 3.12126 1.76332 3.41355 1.76405 3.70862Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.15%"
                              bottom="18.46%"
                              left="88.98%"
                              right="0%"
                              {...getOverrideProps(overrides, "Vector43681384")}
                            ></Icon>
                          </View>
                        </View>
                      </View>
                      <View
                        padding="0px 0px 0px 0px"
                        width="64.72px"
                        height="6.2px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="21.09%"
                        bottom="63.01%"
                        left="29.81%"
                        right="12.41%"
                        {...getOverrideProps(overrides, "<Group>43681385")}
                      >
                        <View
                          padding="0px 0px 0px 0px"
                          width="64.72px"
                          height="6.2px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0%"
                          left="0%"
                          right="0%"
                          {...getOverrideProps(overrides, "Group43681386")}
                        >
                          <Icon
                            width="4.66px"
                            height="5.82px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 4.664647102355957,
                              height: 5.824270248413086,
                            }}
                            paths={[
                              {
                                d: "M2.01723 0.00660154C2.38189 -0.0206587 2.74781 0.0367298 3.08861 0.174627C3.4294 0.312524 3.73657 0.527492 3.98795 0.804027C4.23933 1.08056 4.42866 1.41177 4.54229 1.77376C4.65592 2.13575 4.69101 2.51949 4.64503 2.89732C4.64503 4.75591 3.68075 5.82427 2.01723 5.82427L0 5.82427L0 0.00660154L2.01723 0.00660154ZM0.867395 5.00153L1.92034 5.00153C2.18092 5.01775 2.44168 4.97325 2.68366 4.87125C2.92565 4.76925 3.14275 4.61232 3.31917 4.4119C3.49559 4.21147 3.62689 3.97259 3.70351 3.71263C3.78013 3.45268 3.80014 3.1782 3.76208 2.90918C3.79738 2.64121 3.77534 2.36844 3.69754 2.11039C3.61975 1.85234 3.48812 1.61541 3.3121 1.41658C3.13609 1.21775 2.92003 1.06194 2.67941 0.960307C2.4388 0.858676 2.17958 0.813744 1.92034 0.828731L0.867395 0.828731L0.867395 5.00153Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="4.52%"
                            bottom="1.53%"
                            left="0%"
                            right="92.79%"
                            {...getOverrideProps(overrides, "Vector43681387")}
                          ></Icon>
                          <Icon
                            width="3.99px"
                            height="4.56px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.993863344192505,
                              height: 4.559831619262695,
                            }}
                            paths={[
                              {
                                d: "M0.00940176 2.27797C-0.0170942 1.98945 0.0145906 1.69834 0.102421 1.42333C0.190251 1.14831 0.332287 0.895451 0.519423 0.680968C0.70656 0.466486 0.934665 0.295114 1.18911 0.177845C1.44354 0.0605767 1.7187 1.73195e-15 1.99693 0C2.27516 1.73195e-15 2.55032 0.0605767 2.80476 0.177845C3.0592 0.295114 3.2873 0.466486 3.47444 0.680968C3.66158 0.895451 3.80362 1.14831 3.89145 1.42333C3.97928 1.69834 4.01096 1.98945 3.98446 2.27797C4.01146 2.56679 3.98015 2.85829 3.89256 3.13375C3.80496 3.40922 3.66301 3.66254 3.47582 3.87744C3.28864 4.09235 3.06036 4.26408 2.80567 4.3816C2.55097 4.49912 2.27549 4.55983 1.99693 4.55983C1.71837 4.55983 1.44289 4.49912 1.18819 4.3816C0.9335 4.26408 0.705226 4.09235 0.518042 3.87744C0.330857 3.66254 0.188905 3.40922 0.101307 3.13375C0.013709 2.85829 -0.0175971 2.56679 0.00940176 2.27797ZM3.12893 2.27797C3.12893 1.3263 2.71854 0.769771 1.9983 0.769771C1.27532 0.769771 0.868606 1.3263 0.868606 2.27798C0.868606 3.23727 1.27534 3.78951 1.9983 3.78951C2.71856 3.7895 3.12893 3.23345 3.12893 2.27797Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.17%"
                            left="8.68%"
                            right="85.15%"
                            {...getOverrideProps(overrides, "Vector43681388")}
                          ></Icon>
                          <Icon
                            width="5.84px"
                            height="4.39px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 5.8405656814575195,
                              height: 4.390356540679932,
                            }}
                            paths={[
                              {
                                d: "M4.68342 4.39036L3.82059 4.39036L2.94954 1.15686L2.88373 1.15686L2.01633 4.39036L1.16172 4.39036L0 0L0.843641 0L1.59862 3.3501L1.66078 3.3501L2.52726 0L3.3252 0L4.19169 3.3501L4.2575 3.3501L5.00881 0L5.84057 0L4.68342 4.39036Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="27.65%"
                            bottom="1.53%"
                            left="15.76%"
                            right="75.21%"
                            {...getOverrideProps(overrides, "Vector43681389")}
                          ></Icon>
                          <Icon
                            width="3.59px"
                            height="4.48px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.5940535068511963,
                              height: 4.47942590713501,
                            }}
                            paths={[
                              {
                                d: "M7.14076e-06 0.0890694L0.800679 0.0890694L0.800679 0.786515L0.862836 0.786515C0.968275 0.536018 1.14611 0.32603 1.37155 0.185819C1.597 0.0456087 1.85882 -0.0178388 2.12052 0.00432173C2.32559 -0.0117393 2.5315 0.0204704 2.72299 0.098564C2.91449 0.176658 3.08666 0.298634 3.22676 0.455457C3.36685 0.612279 3.47128 0.799929 3.53231 1.0045C3.59333 1.20908 3.60939 1.42533 3.57929 1.63725L3.57929 4.47938L2.74754 4.47938L2.74754 1.85483C2.74754 1.14929 2.45323 0.798421 1.83809 0.798421C1.69886 0.791666 1.55987 0.816351 1.43066 0.870785C1.30144 0.92522 1.18506 1.00812 1.08947 1.11379C0.993889 1.21947 0.921369 1.34542 0.876885 1.48302C0.832402 1.62063 0.817005 1.76662 0.831752 1.911L0.831752 4.47943L0 4.47943L7.14076e-06 0.0890694Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.21%"
                            bottom="1.53%"
                            left="26.3%"
                            right="68.15%"
                            {...getOverrideProps(overrides, "Vector43681390")}
                          ></Icon>
                          <Icon
                            width="0.83px"
                            height="6.1px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 0.8317554593086243,
                              height: 6.104221343994141,
                            }}
                            paths={[
                              {
                                d: "M0 0L0.831755 0L0.831755 6.10422L0 6.10422L0 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="1.53%"
                            left="33.88%"
                            right="64.84%"
                            {...getOverrideProps(overrides, "Vector43681391")}
                          ></Icon>
                          <Icon
                            width="3.99px"
                            height="4.56px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.994274616241455,
                              height: 4.559887409210205,
                            }}
                            paths={[
                              {
                                d: "M0.00937841 2.27805C-0.0170973 1.98951 0.014607 1.69839 0.102458 1.42336C0.190309 1.14833 0.332369 0.895468 0.519525 0.680981C0.706681 0.466493 0.934803 0.295119 1.18926 0.177848C1.44372 0.0605777 1.71889 0 1.99714 0C2.27539 0 2.55056 0.0605777 2.80502 0.177848C3.05948 0.295119 3.2876 0.466493 3.47475 0.680981C3.66191 0.895468 3.80396 1.14833 3.89182 1.42336C3.97967 1.69839 4.01137 1.98951 3.9849 2.27805C4.01186 2.56688 3.98053 2.85839 3.8929 3.13385C3.80528 3.40931 3.6633 3.66263 3.4761 3.87753C3.28889 4.09242 3.0606 4.26415 2.8059 4.38166C2.55119 4.49918 2.27571 4.55989 1.99714 4.55989C1.71857 4.55989 1.44308 4.49918 1.18837 4.38166C0.933669 4.26415 0.705381 4.09242 0.518179 3.87753C0.330977 3.66263 0.189002 3.40931 0.101376 3.13385C0.0137511 2.85839 -0.0175862 2.56688 0.00937841 2.27805ZM3.12891 2.27805C3.12891 1.32638 2.71851 0.769853 1.99828 0.769853C1.27529 0.769853 0.868587 1.32638 0.868587 2.27806C0.868587 3.23735 1.27532 3.78959 1.99828 3.78959C2.71852 3.78958 3.12891 3.23353 3.12891 2.27805Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.17%"
                            left="36.93%"
                            right="56.9%"
                            {...getOverrideProps(overrides, "Vector43681392")}
                          ></Icon>
                          <Icon
                            width="3.54px"
                            height="4.55px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.537245750427246,
                              height: 4.55454683303833,
                            }}
                            paths={[
                              {
                                d: "M0 3.23349C0 2.4432 0.564864 1.98761 1.56753 1.92286L2.70915 1.8543L2.70915 1.47535C2.70915 1.01165 2.41483 0.749814 1.84631 0.749814C1.382 0.749814 1.06027 0.927391 0.967944 1.23779L0.162695 1.23779C0.247698 0.483688 0.928645 0 1.8847 0C2.94131 0 3.53725 0.547951 3.53725 1.47535L3.53725 4.47509L2.73657 4.47509L2.73657 3.8581L2.67076 3.8581C2.53718 4.07942 2.34961 4.25984 2.12721 4.38095C1.90481 4.50206 1.65553 4.5595 1.40484 4.54743C1.22792 4.5666 1.0491 4.54694 0.879913 4.48971C0.710729 4.43248 0.554936 4.33895 0.422576 4.21515C0.290215 4.09136 0.18423 3.94004 0.111446 3.77096C0.0386615 3.60188 0.000697072 3.41879 0 3.23349ZM2.70915 2.85834L2.70915 2.49129L1.67996 2.55984C1.09956 2.60031 0.836318 2.80597 0.836318 3.19302C0.836318 3.58816 1.16536 3.8181 1.61784 3.8181C1.75041 3.83208 1.88433 3.81814 2.01162 3.7771C2.13892 3.73606 2.25699 3.66877 2.35881 3.57922C2.46062 3.48968 2.54411 3.37971 2.6043 3.25587C2.66448 3.13202 2.70014 2.99683 2.70915 2.85834Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.25%"
                            left="44.44%"
                            right="50.09%"
                            {...getOverrideProps(overrides, "Vector43681393")}
                          ></Icon>
                          <Icon
                            width="3.94px"
                            height="6.18px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.9357573986053467,
                              height: 6.177610397338867,
                            }}
                            paths={[
                              {
                                d: "M0 3.90716C0 2.51988 0.684604 1.64104 1.74943 1.64104C2.01282 1.6284 2.27423 1.69412 2.50309 1.83051C2.73196 1.96691 2.91882 2.16835 3.04185 2.41129L3.10401 2.41129L3.10401 0L3.93576 0L3.93576 6.10422L3.13874 6.10422L3.13874 5.41058L3.07293 5.41058C2.9404 5.65188 2.74701 5.85081 2.51373 5.98583C2.28044 6.12085 2.01613 6.18682 1.74944 6.17658C0.677292 6.17662 0 5.29777 0 3.90716ZM0.859205 3.90716C0.859205 4.83836 1.28057 5.3987 1.98528 5.3987C2.68631 5.3987 3.11957 4.83028 3.11957 3.91097C3.11957 2.99595 2.68176 2.41942 1.98528 2.41942C1.28511 2.41942 0.859205 2.98357 0.859205 3.90716Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="0.35%"
                            left="51.6%"
                            right="42.32%"
                            {...getOverrideProps(overrides, "Vector43681394")}
                          ></Icon>
                          <Icon
                            width="3.99px"
                            height="4.56px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.993863344192505,
                              height: 4.559831619262695,
                            }}
                            paths={[
                              {
                                d: "M0.00940225 2.27797C-0.0170937 1.98945 0.0145875 1.69834 0.102418 1.42333C0.190248 1.14831 0.332287 0.895451 0.519424 0.680968C0.70656 0.466486 0.934659 0.295114 1.1891 0.177845C1.44354 0.0605767 1.7187 0 1.99693 0C2.27516 1.73195e-15 2.55032 0.0605767 2.80476 0.177845C3.0592 0.295114 3.2873 0.466486 3.47443 0.680968C3.66157 0.895451 3.80361 1.14831 3.89144 1.42333C3.97927 1.69834 4.01096 1.98945 3.98446 2.27797C4.01146 2.56679 3.98015 2.85829 3.89255 3.13375C3.80496 3.40922 3.663 3.66254 3.47582 3.87744C3.28863 4.09235 3.06036 4.26408 2.80567 4.3816C2.55097 4.49912 2.27549 4.55983 1.99693 4.55983C1.71837 4.55983 1.44289 4.49912 1.18819 4.3816C0.933497 4.26408 0.705223 4.09235 0.518038 3.87744C0.330854 3.66254 0.188902 3.40922 0.101304 3.13375C0.0137059 2.85829 -0.0175966 2.56679 0.00940225 2.27797ZM3.12894 2.27797C3.12894 1.3263 2.71854 0.769771 1.9983 0.769771C1.27532 0.769771 0.868607 1.3263 0.868607 2.27798C0.868607 3.23727 1.27534 3.78951 1.9983 3.78951C2.71855 3.7895 3.12894 3.23345 3.12894 2.27797Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.17%"
                            left="62.98%"
                            right="30.85%"
                            {...getOverrideProps(overrides, "Vector43681395")}
                          ></Icon>
                          <Icon
                            width="3.59px"
                            height="4.48px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.5940544605255127,
                              height: 4.47942590713501,
                            }}
                            paths={[
                              {
                                d: "M0 0.0890694L0.800679 0.0890694L0.800679 0.786515L0.862839 0.786515C0.968279 0.536018 1.14611 0.32603 1.37155 0.185819C1.597 0.0456087 1.85882 -0.0178388 2.12053 0.00432173C2.32559 -0.0117393 2.5315 0.0204704 2.72299 0.098564C2.91449 0.176658 3.08666 0.298634 3.22676 0.455457C3.36686 0.612279 3.47129 0.799929 3.53231 1.0045C3.59334 1.20908 3.60939 1.42533 3.57929 1.63725L3.57929 4.47938L2.74754 4.47938L2.74754 1.85483C2.74754 1.14929 2.45323 0.798421 1.8381 0.798421C1.69887 0.791666 1.55987 0.816351 1.43066 0.870785C1.30144 0.92522 1.18506 1.00812 1.08947 1.11379C0.993889 1.21947 0.921369 1.34542 0.876885 1.48302C0.832402 1.62063 0.817009 1.76662 0.831756 1.911L0.831756 4.47943L0 4.47943L0 0.0890694Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.21%"
                            bottom="1.53%"
                            left="70.86%"
                            right="23.58%"
                            {...getOverrideProps(overrides, "Vector43681396")}
                          ></Icon>
                          <Icon
                            width="2.41px"
                            height="5.51px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 2.41483998298645,
                              height: 5.507696628570557,
                            }}
                            paths={[
                              {
                                d: "M1.50173 0L1.50173 1.11306L2.41484 1.11306L2.41484 1.84289L1.50173 1.84289L1.50173 4.10043C1.50173 4.56032 1.68362 4.76169 2.09767 4.76169C2.20367 4.76134 2.30957 4.75466 2.41484 4.7417L2.41484 5.46342C2.26547 5.49126 2.11413 5.50607 1.96239 5.5077C1.03741 5.5077 0.66906 5.16873 0.66906 4.32227L0.66906 1.84285L0 1.84285L0 1.11302L0.66906 1.11302L0.66906 0L1.50173 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="10.01%"
                            bottom="1.14%"
                            left="81.34%"
                            right="14.93%"
                            {...getOverrideProps(overrides, "Vector43681397")}
                          ></Icon>
                          <Icon
                            width="3.64px"
                            height="6.1px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.6391327381134033,
                              height: 6.104230880737305,
                            }}
                            paths={[
                              {
                                d: "M0 0L0.824443 0L0.824443 2.41941L0.890245 2.41941C1.00081 2.16658 1.18353 1.9554 1.41349 1.81466C1.64345 1.67392 1.90947 1.61047 2.17531 1.63294C2.37931 1.62138 2.58325 1.65686 2.77257 1.73685C2.96189 1.81684 3.13194 1.93937 3.27057 2.0957C3.40921 2.25203 3.51301 2.4383 3.57459 2.64123C3.63616 2.84416 3.65397 3.05875 3.62677 3.26968L3.62677 6.10423L2.79419 6.10423L2.79419 3.48343C2.79419 2.78217 2.48064 2.42702 1.89297 2.42702C1.75001 2.41481 1.60621 2.43528 1.47165 2.48701C1.33708 2.53873 1.21499 2.62046 1.11391 2.72648C1.01283 2.8325 0.935213 2.96025 0.886482 3.10078C0.837752 3.24132 0.819086 3.39124 0.831791 3.54008L0.831791 6.10421L3.57038e-05 6.10421L0 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="1.53%"
                            left="86.82%"
                            right="7.55%"
                            {...getOverrideProps(overrides, "Vector43681398")}
                          ></Icon>
                          <Icon
                            width="3.84px"
                            height="4.57px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.8413000106811523,
                              height: 4.570298671722412,
                            }}
                            paths={[
                              {
                                d: "M3.79104 3.28974C3.67803 3.69099 3.43318 4.03808 3.09934 4.27027C2.76549 4.50247 2.36391 4.60499 1.96483 4.5599C1.68717 4.56754 1.41123 4.51214 1.15618 4.39755C0.901133 4.28296 0.673077 4.11192 0.487843 3.89631C0.302609 3.68071 0.164637 3.42569 0.0834974 3.14896C0.0023583 2.87224 -0.0199983 2.58043 0.0179738 2.2938C-0.0190009 2.00629 0.00374937 1.71385 0.0846971 1.4363C0.165645 1.15874 0.302899 0.902543 0.487157 0.685047C0.671416 0.46755 0.898381 0.293833 1.15269 0.175656C1.407 0.0574784 1.68271 -0.00239994 1.96117 7.36287e-05C3.13385 7.36287e-05 3.8413 0.834673 3.8413 2.21332L3.8413 2.51566L0.865261 2.51566L0.865261 2.56422C0.852236 2.72533 0.87168 2.8875 0.922337 3.04033C0.972993 3.19316 1.05375 3.33328 1.15942 3.4517C1.2651 3.57012 1.39337 3.66423 1.536 3.72799C1.67863 3.79175 1.83247 3.82376 1.98767 3.82197C2.18663 3.84685 2.3882 3.80953 2.56675 3.71475C2.74529 3.61997 2.89274 3.47202 2.99035 3.28971L3.79104 3.28974ZM0.865239 1.87485L2.99398 1.87485C3.00447 1.7275 2.98524 1.5795 2.93755 1.44035C2.88986 1.3012 2.81475 1.17398 2.71705 1.06687C2.61935 0.959757 2.50122 0.875122 2.37025 0.818401C2.23928 0.76168 2.09837 0.734128 1.95658 0.737514C1.81272 0.735632 1.66996 0.763768 1.53671 0.820264C1.40345 0.87676 1.28241 0.960473 1.1807 1.06647C1.079 1.17246 0.998691 1.2986 0.944516 1.43743C0.890341 1.57627 0.863369 1.725 0.865239 1.87485Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0%"
                            left="94.06%"
                            right="0%"
                            {...getOverrideProps(overrides, "Vector43681399")}
                          ></Icon>
                        </View>
                      </View>
                    </View>
                  </View>
                </Flex>
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="39.3px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 55743681400")}
                >
                  <Image
                    width="140px"
                    height="39px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={playstoresrc}
                    onClick={() => {
                      googleplaybadgeTwoFourThreeSixEightOneFourZeroOneOnClick();
                    }}
                    {...getOverrideProps(
                      overrides,
                      "google-play-badge 243681401"
                    )}
                  ></Image>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Contact4260730")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="120px 120px 120px 120px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left42591053")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage42591054")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message42591055")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Beta"
                {...getOverrideProps(overrides, "Heading4492975")}
              ></Text>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4492974")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rundezvous is currently in Beta testing in the following countries:"
                  {...getOverrideProps(overrides, "Body4492976")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4492977")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="United Kingdom, Belgium"
                  {...getOverrideProps(overrides, "Body4492978")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4492971")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="If your country is not in the list, you can still join the waiting list and we will email you when we have enough people to launch in your country."
                  {...getOverrideProps(overrides, "Body4492973")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4492979")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Just create an account and get started and give us your details using the buttons above and we’ll take care of the rest!"
                  {...getOverrideProps(overrides, "Body4492980")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 20px 30px 20px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Right42591051")}
        >
          <Image
            width="720px"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={image4src}
            {...getOverrideProps(overrides, "image42591052")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Contact4493981")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="120px 120px 120px 120px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left4493982")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage4493983")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message4493984")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={PricingTitle}
                {...getOverrideProps(overrides, "Heading4493985")}
              ></Text>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4493986")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={Pricing1}
                  {...getOverrideProps(overrides, "Body4493987")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4493988")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={Pricing2}
                  {...getOverrideProps(overrides, "Body4493989")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4493990")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="After this the price is £4-£5 per month ($4-$5 / €4-€5) depending on the platform you sign-up though (browser and Stripe has the lowest fees)."
                  {...getOverrideProps(overrides, "Body4493991")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4493992")}
              ></Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 20px 30px 20px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Right4493994")}
        >
          <Image
            width="720px"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={pricingsrc}
            {...getOverrideProps(overrides, "image4493995")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="40px 40px 40px 40px"
        backgroundColor={secondaryColor}
        display="flex"
        {...getOverrideProps(overrides, "Footer")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 313")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 434")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 555")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="32px"
                fontWeight="500"
                color="rgba(48,64,80,1)"
                lineHeight="40px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={titlestr}
                {...getOverrideProps(overrides, "Rundezvous")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={tmstr}
                {...getOverrideProps(overrides, "TM")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 405")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 403")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Navigation"
              {...getOverrideProps(overrides, "Navigation")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color={buttoncolor}
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Home"
              onClick={() => {
                homeOnClick();
              }}
              {...getOverrideProps(overrides, "Home")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color={buttoncolor}
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms"
              onClick={() => {
                termsFourTwoFiveNineOneOneOneSixOnClick();
              }}
              {...getOverrideProps(overrides, "Terms42591116")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color={buttoncolor}
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Login"
              onClick={() => {
                termsFourTwoFiveNineOneOneFourSixOnClick();
              }}
              {...getOverrideProps(overrides, "Terms42591146")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color={buttoncolor}
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Support"
              onClick={() => {
                supportOnClick();
              }}
              {...getOverrideProps(overrides, "Support")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 433")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "LogoWithText")}
          >
            <Image
              width="197px"
              height="39px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={logosrc}
              {...getOverrideProps(
                overrides,
                "long+logo+overlapped+(1) 14264729"
              )}
            ></Image>
          </Flex>
          <Text
            //fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(102,112,133,1)"
            lineHeight="21px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={copyrighttextstr}
            {...getOverrideProps(
              overrides,
              "\u00A9 2024 Rundezvous Ltd. All rights reserved."
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
