/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Flex, Image } from "@aws-amplify/ui-react";
export default function MobileHeaderBar(props) {
  const {
    logoURL,
    navigateHomeURL,
    burgerHandler,
    burgerURL,
    overrides,
    ...rest
  } = props;
  const logoOnClick = useNavigateAction({ type: "url", url: navigateHomeURL });
  return (
    <Flex
      gap="0"
      direction="row"
      width="400px"
      height="78px"
      justifyContent="center"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(250,250,250,1)"
      {...getOverrideProps(overrides, "MobileHeaderBar")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="70px"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0.2)"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05000000074505806)"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "NavBar")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 445")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-end"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="5px 5px 5px 5px"
            {...getOverrideProps(overrides, "Frame 468")}
          >
            <Image
              width="310px"
              height="60px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={logoURL}
              onClick={() => {
                logoOnClick();
              }}
              {...getOverrideProps(overrides, "Logo")}
            ></Image>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="80px"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="20px 20px 20px 20px"
            {...getOverrideProps(overrides, "Frame 467")}
          >
            <Image
              width="30px"
              height="30px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={burgerURL}
              onClick={burgerHandler}
              {...getOverrideProps(overrides, "menu")}
            ></Image>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
