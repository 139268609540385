import { get } from 'aws-amplify/api';

async function getSchedule(email) {
    var pk = 'member-' + email
    try{
        var result = get({  apiName: 'membereventsapi',
                            path: '/schedule/' + pk, 
                            options: {}});
        const { body } = await result.response;
        const json = await body.json();
        // console.log('GET call succeeded: ', json.body);
        return JSON.parse(json.body);

    } catch (e) {
        console.log('GET call failed: ', JSON.parse(e.response.body));
        return;
    }
}


export default getSchedule;