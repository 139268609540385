import { get } from 'aws-amplify/api';

async function getArchiveAttendance(email) {
    var pk = 'member-' + email
    try{
        var result = get({apiName: 'membereventsapi', path: '/archiveAttendance/' + pk, options: {}});
        const { body } = await result.response;
        const json = await body.json();
        const parsed = JSON.parse(json.body);
        // console.log(parsed.Count);
        if(parsed.Count > 0){
            return parsed.Items;
        }else{
            console.log("Got no archive attendances.")
            return [];
        }
    }catch (e) {
        console.log('GET attendance call failed: ', JSON.parse(e.response.body));
        return;
    }
    
}


export default getArchiveAttendance;