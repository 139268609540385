// import { getCurrentUser } from 'aws-amplify/auth';
import { put } from 'aws-amplify/api';


function generateCode(length) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n)).toUpperCase();
    }
    return retVal;
  }

async function putMember(item, callback, error_callback) {
    item.pk = 'member-'+item.email;
    item.sk ='member-'+item.email;
    item.num_runs = Number(item.num_runs)
    item.subscription_platform = "STRIPE";
    item.my_referral_code = generateCode(10);
    item.password = '';
    try{
        var restOperation = put({apiName: 'membereventsapi', path: '/member', options: {body:item}});
        const { body } = await restOperation.response;
        const response = await body.json();
        console.log( response );
        return callback(response);

    } catch (e) {
        console.log("Caught an error");
        console.log(JSON.parse(e.response.body));
        var ebody = JSON.parse(e.response.body);
        if(ebody?.body?.error?.code === "BadWords"){
            // restOperation.error = "BadWords";
            console.log('POST call failed: ', JSON.parse(e.response.body));
            return error_callback("w3wError");
        }
        console.log('POST call failed: ', JSON.parse(e.response.body));
        return error_callback("unknown");
    }
}


export default putMember;