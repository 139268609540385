/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function MobileHomePage(props) {
  const {
    logosrc,
    image1src,
    image2src,
    image3src,
    image4src,
    homeurl,
    termsurl,
    loginurl,
    contacturl,
    widthProp = '"100%"',
    supporturl,
    playstoresrc,
    appstoresrc,
    playstoreurl,
    appstoreurl,
    betasrc,
    signupurl,
    imageFrame,
    PricingTitle,
    Pricing1,
    Pricing2,
    Pricing3,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        "1": {},
        "342831046": {},
        "342831154": {},
        "long+logo+overlapped+(1) 142831010": {},
        "Frame 517": {},
        Button42831012: {},
        "Frame 321": {},
        Header: {},
        image42831015: {},
        ImageFrame: {},
        Heading42831019: {},
        Heading43681319: {},
        "Frame 559": {},
        Body42831020: {},
        Vector43681285: {},
        Vector43681286: {},
        Group43681284: {},
        "<Path>43681290": {},
        "<Path>43681291": {},
        "<Group>43681289": {},
        "<Group>43681288": {},
        Vector43681293: {},
        Vector43681294: {},
        Vector43681295: {},
        Vector43681296: {},
        Vector43681297: {},
        Vector43681298: {},
        Vector43681299: {},
        Vector43681300: {},
        Group43681292: {},
        "<Group>43681287": {},
        Group43681283: {},
        Vector43681303: {},
        Vector43681304: {},
        Vector43681305: {},
        Vector43681306: {},
        Vector43681307: {},
        Vector43681308: {},
        Vector43681309: {},
        Vector43681310: {},
        Vector43681311: {},
        Vector43681312: {},
        Vector43681313: {},
        Vector43681314: {},
        Vector43681315: {},
        Group43681302: {},
        "<Group>43681301": {},
        Group43681282: {},
        "appstoreblack 143681281": {},
        "Frame 55643681280": {},
        "google-play-badge 243681317": {},
        "Frame 55743681316": {},
        "Frame 55843681279": {},
        Message42831018: {},
        HeroMessage42831017: {},
        Left42831016: {},
        Def: {},
        Eyebrow: {},
        Heading42831026: {},
        Body42831027: {},
        Message42831024: {},
        Button42831028: {},
        HeroMessage42831023: {},
        Left42831022: {},
        What: {},
        "Frame 43042831034": {},
        "Input your weekly schedule": {},
        "Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace. The more flexible you can be with your schedule the more runs you\u2019ll get matched with. All changes need to be done by 18:00 on Wednesday evening.":
          {},
        "Frame 37442831033": {},
        "Frame 13642831032": {},
        "Frame 43042831045": {},
        "Offer you running events.": {},
        "We then use our algorithm to take in this information and create dynamic running events which best suit yours and other people\u2019s schedules. These will be located as close to your home location as possible - the more people who sign-up in your area, the more flexible this will become. You will receive a list of suggested runs for the following week which you can accept or reject at that point by 18:00 on Friday.":
          {},
        "Frame 37442831044": {},
        "Frame 393": {},
        "Frame 43042831153": {},
        "If enough people sign-up - Game on!": {},
        "If at least 3 people accept the runs you accepted, you will receive a confirmation email that the run is going ahead with full details of meeting point and time. You just turn up then and there, meet your group and head off for a casual run. Don\u2019t forget to share group pictures on your social media with #Rundezvous!":
          {},
        "Frame 37442831152": {},
        "Frame 394": {},
        How42831031: {},
        "Available on mobile": {},
        Vector43681244: {},
        Vector43681245: {},
        Group43681243: {},
        "<Path>43681249": {},
        "<Path>43681250": {},
        "<Group>43681248": {},
        "<Group>43681247": {},
        Vector43681252: {},
        Vector43681253: {},
        Vector43681254: {},
        Vector43681255: {},
        Vector43681256: {},
        Vector43681257: {},
        Vector43681258: {},
        Vector43681259: {},
        Group43681251: {},
        "<Group>43681246": {},
        Group43681242: {},
        Vector43681262: {},
        Vector43681263: {},
        Vector43681264: {},
        Vector43681265: {},
        Vector43681266: {},
        Vector43681267: {},
        Vector43681268: {},
        Vector43681269: {},
        Vector43681270: {},
        Vector43681271: {},
        Vector43681272: {},
        Vector43681273: {},
        Vector43681274: {},
        Group43681261: {},
        "<Group>43681260": {},
        Group43681241: {},
        "appstoreblack 143681240": {},
        "Frame 55643681275": {},
        "google-play-badge 243681278": {},
        "Frame 55743681276": {},
        "Frame 55843681277": {},
        "Frame 37443681222": {},
        "Frame 13643681221": {},
        How43681220: {},
        Benefits: {},
        "Why run in a group?": {},
        "Frame 432": {},
        "Physical Health.": {},
        "Frame 42942831056": {},
        "Regular running is one of the best ways to stay physically healthy.":
          {},
        "Frame 13842831058": {},
        "Low levels of physical activity Studies\u00A0have shown running a bit and often halves your heart attack risk.":
          {},
        "Frame 13942831060": {},
        "One of the biggest challenges is staying consistent which is where Rundezvous comes in.":
          {},
        "Frame 137": {},
        "Frame 39042831055": {},
        "Frame 518": {},
        "Mental Health.": {},
        "Frame 42942831066": {},
        "Running has innate mental health benefits, as does socialising with others.":
          {},
        "Frame 13842831068": {},
        "Rundezvous gives its users the opportunity to join these two activities together.":
          {},
        "Frame 13942831070": {},
        "Frame 39042831065": {},
        "Frame 519": {},
        "Make Connections.": {},
        "Frame 42942831074": {},
        "In an ever increasingly online and virtual world, connections IRL (In Real Life) are more and more difficult to come by.":
          {},
        "Frame 13842831076": {},
        "Rundezvous makes these connections for you and groups you with like-minded people who want to go for a casual run and maybe have a chat too.":
          {},
        "Frame 13942831078": {},
        "Frame 391": {},
        "Frame 520": {},
        "Frame 63": {},
        Why: {},
        image42831082: {},
        Right42831081: {},
        Heading42831086: {},
        Body42831087: {},
        Message42831085: {},
        Button42831088: {},
        HeroMessage42831084: {},
        Left42831083: {},
        Action: {},
        image42831096: {},
        Right42831095: {},
        Heading4493998: {},
        Body44931000: {},
        Message4493999: {},
        Body44931002: {},
        Message44931001: {},
        Body44931004: {},
        Message44931003: {},
        Message44931005: {},
        Message4493997: {},
        HeroMessage4493996: {},
        HeroMessage42831091: {},
        Left42831090: {},
        Pricing: {},
        image44931008: {},
        Right44931007: {},
        Heading44931022: {},
        Body44931024: {},
        Message44931023: {},
        Body44931026: {},
        Message44931025: {},
        Body44931028: {},
        Message44931027: {},
        Body44931030: {},
        Message44931029: {},
        Message44931021: {},
        HeroMessage44931010: {},
        Left44931009: {},
        Beta: {},
        Rundezvous: {},
        "Frame 434": {},
        "Frame 313": {},
        Navigation: {},
        Home: {},
        Terms42831105: {},
        Terms42831106: {},
        Support: {},
        "Frame 403": {},
        "Frame 405": {},
        "long+logo+overlapped+(1) 142831109": {},
        LogoWithText: {},
        "\u00A9 2024 Rundezvous Ltd. All rights reserved.": {},
        "Frame 433": {},
        Footer: {},
        MobileHomePage: {},
      },
      variantValues: {},
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const longPluslogoPlusoverlappedPlusOpenParenthesisOneCloseParenthesisOneFourTwoEightThreeOneZeroOneZeroOnClick =
    useNavigateAction({ type: "url", url: homeurl });
  const buttonFourTwoEightThreeOneZeroOneTwoOnClick = useNavigateAction({
    type: "url",
    url: loginurl,
  });
  const frameFiveFiveSixFourThreeSixEightOneTwoEightZeroOnClick =
    useNavigateAction({ type: "url", url: appstoreurl });
  const vectorFourThreeSixEightOneTwoNineFourOnClick = useNavigateAction({
    type: "url",
    url: appstoreurl,
  });
  const googleplaybadgeTwoFourThreeSixEightOneThreeOneSevenOnClick =
    useNavigateAction({ type: "url", url: playstoreurl });
  const buttonFourTwoEightThreeOneZeroTwoEightOnClick = useNavigateAction({
    type: "url",
    url: signupurl,
  });
  const frameFiveFiveSixFourThreeSixEightOneTwoSevenFiveOnClick =
    useNavigateAction({ type: "url", url: appstoreurl });
  const vectorFourThreeSixEightOneTwoFourFiveOnClick = useNavigateAction({
    type: "url",
    url: appstoreurl,
  });
  const googleplaybadgeTwoFourThreeSixEightOneTwoSevenEightOnClick =
    useNavigateAction({ type: "url", url: playstoreurl });
  const buttonFourTwoEightThreeOneZeroEightEightOnClick = useNavigateAction({
    type: "url",
    url: signupurl,
  });
  const homeOnClick = useNavigateAction({ type: "url", url: homeurl });
  const termsFourTwoEightThreeOneOneZeroFiveOnClick = useNavigateAction({
    type: "url",
    url: termsurl,
  });
  const termsFourTwoEightThreeOneOneZeroSixOnClick = useNavigateAction({
    type: "url",
    url: loginurl,
  });
  const supportOnClick = useNavigateAction({ type: "url", url: supporturl });
  return (
    <Flex
      gap="0"
      direction="column"
      width="350px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "MobileHomePage")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
        padding="10px 20px 10px 20px"
        backgroundColor="rgba(125,26,183,1)"
        display="flex"
        {...getOverrideProps(overrides, "Header")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 517")}
        >
          <Image
            width="206.43px"
            height="35px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={logosrc}
            onClick={() => {
              longPluslogoPlusoverlappedPlusOpenParenthesisOneCloseParenthesisOneFourTwoEightThreeOneZeroOneZeroOnClick();
            }}
            {...getOverrideProps(
              overrides,
              "long+logo+overlapped+(1) 142831010"
            )}
          ></Image>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 321")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor="rgba(84,18,125,1)"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Log in"
            onClick={() => {
              buttonFourTwoEightThreeOneZeroOneTwoOnClick();
            }}
            {...getOverrideProps(overrides, "Button42831012")}
          ></Button>
        </Flex>
      </Flex>
      <View
        width={widthProp}
        height="300px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Def")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="350px"
          height="300px"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          display="flex"
          children={imageFrame}
          {...getOverrideProps(overrides, "ImageFrame")}
        ></Flex>
        <Flex
          gap="10px"
          direction="column"
          width={widthProp}
          height="300px"
          justifyContent="center"
          alignItems="flex-start"
          overflow="hidden"
          position="absolute"
          top="0px"
          left="0px"
          padding="120px 20px 120px 20px"
          display="flex"
          {...getOverrideProps(overrides, "Left42831016")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage42831017")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              backgroundColor="rgba(0,0,0,0.5)"
              display="flex"
              {...getOverrideProps(overrides, "Message42831018")}
            >
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 559")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="40px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="50px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rundezvous"
                  {...getOverrideProps(overrides, "Heading42831019")}
                ></Text>
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="600"
                  color="rgba(255,255,255,1)"
                  lineHeight="30px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="TM"
                  {...getOverrideProps(overrides, "Heading43681319")}
                ></Text>
              </Flex>
              <Text
                //fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="A dynamic scheduling service for sociable runs in your area with people like you."
                {...getOverrideProps(overrides, "Body42831020")}
              ></Text>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 55843681279")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="103px"
                  height="40px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  onClick={() => {
                    frameFiveFiveSixFourThreeSixEightOneTwoEightZeroOnClick();
                  }}
                  {...getOverrideProps(overrides, "Frame 55643681280")}
                >
                  <View
                    width="103px"
                    height="40px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "appstoreblack 143681281")}
                  >
                    <View
                      padding="0px 0px 0px 0px"
                      width="103px"
                      height="40px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="0%"
                      bottom="0%"
                      left="0%"
                      right="0%"
                      {...getOverrideProps(overrides, "Group43681282")}
                    >
                      <View
                        padding="0px 0px 0px 0px"
                        width="103px"
                        height="40px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="0%"
                        bottom="0%"
                        left="0%"
                        right="0%"
                        {...getOverrideProps(overrides, "Group43681283")}
                      >
                        <View
                          padding="0px 0px 0px 0px"
                          width="103px"
                          height="40px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0%"
                          left="0%"
                          right="0%"
                          {...getOverrideProps(overrides, "Group43681284")}
                        >
                          <Icon
                            width="103px"
                            height="40px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 103,
                              height: 40.00012969970703,
                            }}
                            paths={[
                              {
                                d: "M94.7977 0.00013L8.20691 0.00013C7.89127 0.00013 7.57943 0.00013 7.26463 0.00213C7.00111 0.00413 6.73969 0.00994 6.47365 0.01483C5.89567 0.0227318 5.31905 0.0818063 4.7488 0.19154C4.17936 0.303663 3.62775 0.515046 3.11264 0.81854C2.59815 1.1246 2.12805 1.5223 1.71939 1.9972C1.30859 2.47077 0.966152 3.01815 0.70525 3.61829C0.443627 4.21724 0.262217 4.85907 0.167285 5.52161C0.0714511 6.18332 0.0198818 6.85265 0.0130403 7.52361C0.00505256 7.83021 0.00420903 8.13783 0 8.44447L0 31.5587C0.00420903 31.8692 0.00505256 32.17 0.0130403 32.4806C0.0198839 33.1516 0.0714532 33.8209 0.167285 34.4825C0.261955 35.1455 0.443376 35.7877 0.70525 36.3868C0.966034 36.985 1.30852 37.5302 1.71939 38.0011C2.1265 38.4781 2.59691 38.8761 3.11264 39.1798C3.62775 39.4841 4.17929 39.6968 4.7488 39.8106C5.31915 39.9195 5.89571 39.9786 6.47365 39.9874C6.73969 39.9942 7.00111 39.9981 7.26463 39.9981C7.57942 40.0001 7.89129 40.0001 8.20691 40.0001L94.7977 40.0001C95.1071 40.0001 95.4214 40.0001 95.7308 39.9981C95.993 39.9981 96.262 39.9942 96.5243 39.9874C97.1011 39.9791 97.6766 39.92 98.2458 39.8106C98.8171 39.696 99.3706 39.4834 99.8882 39.1798C100.404 38.8759 100.873 38.478 101.28 38.0011C101.69 37.5284 102.033 36.9836 102.297 36.3868C102.557 35.7872 102.737 35.1451 102.83 34.4825C102.926 33.8208 102.98 33.1516 102.99 32.4806C102.993 32.17 102.993 31.8692 102.993 31.5587C103 31.1954 103 30.8341 103 30.4649L103 9.53626C103 9.17005 103 8.80677 102.993 8.44447C102.993 8.13783 102.993 7.83021 102.99 7.52357C102.98 6.85255 102.926 6.18337 102.83 5.52157C102.737 4.85941 102.557 4.21763 102.297 3.61825C101.766 2.41533 100.923 1.43616 99.8882 0.81845C99.3706 0.515697 98.8171 0.30437 98.2458 0.19145C97.6767 0.0812328 97.1012 0.0221378 96.5243 0.01469C96.262 0.00981 95.993 0.00395 95.7308 0.002C95.4214 0 95.1071 0.00013 94.7977 0.00013Z",
                                fill: "rgba(166,166,166,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="0%"
                            left="0%"
                            right="0%"
                            {...getOverrideProps(overrides, "Vector43681285")}
                          ></Icon>
                          <Icon
                            width="101.5px"
                            height="38.25px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 101.49713134765625,
                              height: 38.25199890136719,
                            }}
                            paths={[
                              {
                                d: "M6.51739 38.25C6.25514 38.25 5.99922 38.2461 5.73903 38.2393C5.20002 38.2311 4.66226 38.1766 4.13018 38.0762C3.63404 37.9769 3.15341 37.7923 2.70415 37.5283C2.25901 37.2665 1.85301 36.9233 1.5017 36.5117C1.1453 36.105 0.848578 35.6332 0.623298 35.1152C0.395537 34.5938 0.237912 34.0349 0.155915 33.458C0.0673655 32.8381 0.0194566 32.2113 0.0126013 31.583C0.00714419 31.3721 0 30.6699 0 30.6699L0 7.56934C0 7.56934 0.007609 6.87793 0.0126443 6.6748C0.0192082 6.04748 0.0668386 5.42165 0.155123 4.80273C0.237271 4.22425 0.395018 3.66375 0.622902 3.14063C0.847354 2.62294 1.14242 2.15086 1.49668 1.74268C1.85053 1.33062 2.25783 0.985604 2.70373 0.72021C3.15196 0.457094 3.63168 0.273733 4.12681 0.17627C4.66065 0.0748361 5.20026 0.0199961 5.74115 0.01221L6.51781 0L94.9751 0L95.7611 0.0127C96.297 0.0200992 96.8317 0.07445 97.3607 0.17529C97.8608 0.273975 98.3455 0.458616 98.7989 0.72314C99.6922 1.25799 100.419 2.10416 100.878 3.14307C101.102 3.66258 101.257 4.21851 101.338 4.79199C101.428 5.41599 101.478 6.04674 101.488 6.6792C101.49 6.9624 101.49 7.2666 101.49 7.56934C101.497 7.94434 101.497 8.30127 101.497 8.66113L101.497 29.5898C101.497 29.9531 101.497 30.3076 101.49 30.665C101.49 30.9902 101.49 31.2881 101.487 31.5947C101.477 32.2159 101.428 32.8354 101.34 33.4482C101.26 34.0293 101.103 34.5925 100.875 35.1182C100.648 35.6306 100.353 36.0983 100.001 36.5039C99.6493 36.9177 99.2426 37.2629 98.7964 37.5264C98.3443 37.7924 97.8603 37.9777 97.3607 38.0762C96.8286 38.1772 96.2908 38.2317 95.7518 38.2393C95.4996 38.2461 95.2357 38.25 94.9793 38.25L94.0463 38.252L6.51739 38.25Z",
                                fill: "rgba(0,0,0,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="2.19%"
                            bottom="2.18%"
                            left="0.73%"
                            right="0.73%"
                            {...getOverrideProps(overrides, "Vector43681286")}
                          ></Icon>
                        </View>
                        <View
                          padding="0px 0px 0px 0px"
                          width="85.66px"
                          height="24.77px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="21.8%"
                          bottom="16.27%"
                          left="8.33%"
                          right="8.5%"
                          {...getOverrideProps(overrides, "<Group>43681287")}
                        >
                          <View
                            padding="0px 0px 0px 0px"
                            width="15.25px"
                            height="21.78px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="12.09%"
                            left="0%"
                            right="82.2%"
                            {...getOverrideProps(overrides, "<Group>43681288")}
                          >
                            <View
                              padding="0px 0px 0px 0px"
                              width="15.25px"
                              height="21.78px"
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="0%"
                              bottom="0%"
                              left="0%"
                              right="0%"
                              {...getOverrideProps(
                                overrides,
                                "<Group>43681289"
                              )}
                            >
                              <Icon
                                width="15.25px"
                                height="16.52px"
                                viewBox={{
                                  minX: 0,
                                  minY: 0,
                                  width: 15.249396324157715,
                                  height: 16.523231506347656,
                                }}
                                paths={[
                                  {
                                    d: "M12.7367 6.32767C12.7459 5.49301 12.9368 4.67463 13.2914 3.94868C13.6461 3.22273 14.153 2.61279 14.7651 2.17561C14.3762 1.53045 13.8633 0.999512 13.2669 0.624959C12.6706 0.250407 12.0073 0.042563 11.3297 0.0179334C9.88432 -0.158327 8.48307 1.02276 7.74652 1.02276C6.99572 1.02276 5.8617 0.0354333 4.64053 0.0646233C3.85065 0.0942714 3.08085 0.361125 2.40613 0.839183C1.73142 1.31724 1.17479 1.9902 0.7905 2.79249C-0.874177 6.14091 0.367522 11.062 1.96214 13.7686C2.75997 15.0939 3.69239 16.5744 4.91245 16.5219C6.10635 16.4643 6.55225 15.6374 7.99339 15.6374C9.42116 15.6374 9.83951 16.5219 11.0843 16.4885C12.3654 16.4643 13.1726 15.1573 13.9424 13.8194C14.5157 12.875 14.9568 11.8313 15.2494 10.7268C14.5051 10.3611 13.8699 9.74894 13.4231 8.96664C12.9763 8.18434 12.7376 7.26654 12.7367 6.32767Z",
                                    fill: "rgba(255,255,255,1)",
                                    fillRule: "nonzero",
                                  },
                                ]}
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="24.12%"
                                bottom="0%"
                                left="0%"
                                right="0%"
                                {...getOverrideProps(
                                  overrides,
                                  "<Path>43681290"
                                )}
                              ></Icon>
                              <Icon
                                width="3.77px"
                                height="5.02px"
                                viewBox={{
                                  minX: 0,
                                  minY: 0,
                                  width: 3.766018867492676,
                                  height: 5.021217346191406,
                                }}
                                paths={[
                                  {
                                    d: "M2.79445 3.49062C3.49297 2.51641 3.8371 1.26423 3.75377 0C2.68659 0.13022 1.70082 0.722779 0.992864 1.65961C0.646723 2.11728 0.381617 2.64971 0.212701 3.22648C0.0437837 3.80325 -0.0256308 4.41304 0.00842416 5.021C0.542205 5.02739 1.07027 4.89297 1.55286 4.62788C2.03545 4.3628 2.45997 3.97395 2.79445 3.49062Z",
                                    fill: "rgba(255,255,255,1)",
                                    fillRule: "nonzero",
                                  },
                                ]}
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                position="absolute"
                                top="0%"
                                bottom="76.94%"
                                left="49.78%"
                                right="25.52%"
                                {...getOverrideProps(
                                  overrides,
                                  "<Path>43681291"
                                )}
                              ></Icon>
                            </View>
                          </View>
                          <View
                            padding="0px 0px 0px 0px"
                            width="64.61px"
                            height="15.62px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="36.94%"
                            bottom="0%"
                            left="24.57%"
                            right="0%"
                            {...getOverrideProps(overrides, "Group43681292")}
                          >
                            <Icon
                              width="9.51px"
                              height="12.42px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 9.511037826538086,
                                height: 12.417999267578125,
                              }}
                              paths={[
                                {
                                  d: "M6.77835 9.06155L2.70411 9.06155L1.72569 12.418L0 12.418L3.85906 0L5.65198 0L9.51104 12.418L7.75592 12.418L6.77835 9.06155ZM3.12607 7.51272L6.35558 7.51272L4.76354 2.06545L4.71899 2.06545L3.12607 7.51272Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="1.33%"
                              bottom="19.17%"
                              left="0%"
                              right="85.28%"
                              {...getOverrideProps(overrides, "Vector43681293")}
                            ></Icon>
                            <Icon
                              width="7.34px"
                              height="12.15px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.340717315673828,
                                height: 12.145217895507812,
                              }}
                              paths={[
                                {
                                  d: "M7.34072 4.62375C7.34072 7.43723 6.04456 9.24484 4.08855 9.24484C3.59306 9.27495 3.10029 9.14235 2.66665 8.86221C2.23302 8.58207 1.87605 8.16572 1.63662 7.66084L1.5996 7.66084L1.5996 12.1452L0 12.1452L0 0.0963995L1.54835 0.0963995L1.54835 1.60226L1.57777 1.60226C1.82821 1.09979 2.19121 0.685603 2.62815 0.403757C3.06509 0.121911 3.55963 -0.0170459 4.05913 0.00166915C6.03696 0.00167915 7.34072 1.81808 7.34072 4.62375ZM5.69656 4.62375C5.69656 2.79075 4.8812 1.58566 3.63716 1.58566C2.41498 1.58566 1.5929 2.81613 1.5929 4.62375C1.5929 6.44797 2.41498 7.66965 3.63716 7.66965C4.88121 7.66965 5.69656 6.47336 5.69656 4.62375Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.25%"
                              bottom="0%"
                              left="16.26%"
                              right="72.38%"
                              onClick={() => {
                                vectorFourThreeSixEightOneTwoNineFourOnClick();
                              }}
                              {...getOverrideProps(overrides, "Vector43681294")}
                            ></Icon>
                            <Icon
                              width="7.34px"
                              height="12.15px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.340714454650879,
                                height: 12.145209312438965,
                              }}
                              paths={[
                                {
                                  d: "M7.34071 4.62374C7.34071 7.43722 6.04456 9.24483 4.08856 9.24483C3.59306 9.27494 3.10029 9.14234 2.66665 8.8622C2.23302 8.58206 1.87605 8.16572 1.63662 7.66083L1.5996 7.66083L1.5996 12.1452L0 12.1452L0 0.0963905L1.54832 0.0963905L1.54832 1.60225L1.57774 1.60225C1.82818 1.09979 2.19118 0.685596 2.62812 0.403752C3.06506 0.121908 3.5596 -0.0170482 4.05911 0.00166964C6.03699 0.00166964 7.34071 1.81807 7.34071 4.62374ZM5.69656 4.62374C5.69656 2.79074 4.8812 1.58565 3.63716 1.58565C2.41498 1.58565 1.5929 2.81612 1.5929 4.62374C1.5929 6.44796 2.41498 7.66964 3.63716 7.66964C4.88121 7.66964 5.69656 6.47335 5.69656 4.62374Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.25%"
                              bottom="0%"
                              left="29.53%"
                              right="59.1%"
                              {...getOverrideProps(overrides, "Vector43681295")}
                            ></Icon>
                            <Icon
                              width="8.2px"
                              height="12.83px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 8.200599670410156,
                                height: 12.831997871398926,
                              }}
                              paths={[
                                {
                                  d: "M1.63746 9.16509C1.75598 10.3965 2.78569 11.2051 4.19279 11.2051C5.54106 11.2051 6.51108 10.3965 6.51108 9.28614C6.51108 8.32227 5.92603 7.74514 4.54078 7.34962L3.15553 6.96192C1.1928 6.41114 0.281617 5.34473 0.281617 3.61426C0.281617 1.47168 1.88879 0 4.17093 0C6.42952 0 7.97787 1.47168 8.02998 3.61426L6.41523 3.61426C6.31857 2.375 5.43681 1.62696 4.14822 1.62696C2.85962 1.62696 1.97787 2.3838 1.97787 3.48536C1.97787 4.36329 2.54105 4.87989 3.91874 5.27536L5.09637 5.6113C7.28941 6.21384 8.2006 7.2373 8.2006 9.05368C8.2006 11.3769 6.60772 12.832 4.07424 12.832C1.70383 12.832 0.103392 11.4111 0 9.165L1.63746 9.16509Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="0%"
                              bottom="17.85%"
                              left="47.14%"
                              right="40.17%"
                              {...getOverrideProps(overrides, "Vector43681296")}
                            ></Icon>
                            <Icon
                              width="4.22px"
                              height="11.25px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 4.222172737121582,
                                height: 11.248900413513184,
                              }}
                              paths={[
                                {
                                  d: "M2.74025 0L2.74025 2.14258L4.22217 2.14258L4.22217 3.61426L2.74025 3.61426L2.74025 8.60547C2.74025 9.38086 3.03698 9.74219 3.68841 9.74219C3.86434 9.73863 4.03998 9.72427 4.21461 9.69919L4.21461 11.1621C3.92172 11.2257 3.62403 11.2545 3.32613 11.248C1.74838 11.248 1.13308 10.5595 1.13308 8.80368L1.13308 3.61426L0 3.61426L0 2.14258L1.13306 2.14258L1.13306 0L2.74025 0Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="9.15%"
                              bottom="18.84%"
                              left="60.93%"
                              right="32.53%"
                              {...getOverrideProps(overrides, "Vector43681297")}
                            ></Icon>
                            <Icon
                              width="7.39px"
                              height="9.28px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.392806529998779,
                                height: 9.277339935302734,
                              }}
                              paths={[
                                {
                                  d: "M0 4.63867C0 1.79004 1.44409 0 3.69598 0C5.95543 0 7.39281 1.79 7.39281 4.63867C7.39281 7.49512 5.963 9.27734 3.69598 9.27734C1.42977 9.27734 0 7.49511 0 4.63867ZM5.76294 4.63867C5.76294 2.68457 4.99213 1.53125 3.69598 1.53125C2.39982 1.53125 1.62986 2.69336 1.62986 4.63867C1.62986 6.60058 2.39982 7.74512 3.69598 7.74512C4.99213 7.74512 5.76294 6.60058 5.76294 4.63867Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.15%"
                              bottom="18.46%"
                              left="68.79%"
                              right="19.76%"
                              {...getOverrideProps(overrides, "Vector43681298")}
                            ></Icon>
                            <Icon
                              width="3.99px"
                              height="9.15px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 3.9851717948913574,
                                height: 9.150014877319336,
                              }}
                              paths={[
                                {
                                  d: "M6.56695e-06 0.0963152L1.52564 0.0963152L1.52564 1.63731L1.56265 1.63731C1.66587 1.15603 1.90856 0.730697 2.24992 0.432812C2.59128 0.134926 3.0105 -0.017352 3.43712 0.0015734C3.62146 0.000825633 3.80529 0.0240858 3.98517 0.0709151L3.98517 1.80919C3.75245 1.72658 3.50969 1.68864 3.26645 1.69689C3.03407 1.68594 2.8024 1.73354 2.58735 1.83643C2.3723 1.93932 2.17896 2.09505 2.0206 2.29294C1.86225 2.49083 1.74263 2.72619 1.66996 2.98286C1.59729 3.23953 1.57329 3.51143 1.5996 3.77989L1.5996 9.15001L0 9.15001L6.56695e-06 0.0963152Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.25%"
                              bottom="19.17%"
                              left="82.28%"
                              right="11.55%"
                              {...getOverrideProps(overrides, "Vector43681299")}
                            ></Icon>
                            <Icon
                              width="7.12px"
                              height="9.28px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 7.1187872886657715,
                                height: 9.277339935302734,
                              }}
                              paths={[
                                {
                                  d: "M7.02968 6.50586C6.81449 8.14941 5.4368 9.27734 3.67413 9.27734C1.40711 9.27734 0 7.51269 0 4.68164C0 1.8418 1.41467 0 3.60688 0C5.76294 0 7.11879 1.7207 7.11879 4.46582L7.11879 5.10254L1.61474 5.10254L1.61474 5.21484C1.58934 5.54804 1.62524 5.88371 1.72007 6.19955C1.81491 6.51539 1.9665 6.80417 2.16478 7.04672C2.36306 7.28927 2.60348 7.48003 2.87009 7.60633C3.1367 7.73264 3.42339 7.79159 3.71112 7.77929C4.08912 7.82044 4.46846 7.71873 4.79272 7.48929C5.11697 7.25985 5.36881 6.91493 5.51078 6.50585L7.02968 6.50586ZM1.6223 3.80371L5.51834 3.80371C5.5327 3.50411 5.4936 3.2043 5.4035 2.92309C5.3134 2.64188 5.17424 2.38535 4.99475 2.16958C4.81527 1.95381 4.59934 1.78348 4.36051 1.66926C4.12167 1.55505 3.8651 1.49941 3.60688 1.50586C3.34639 1.50405 3.08818 1.5622 2.84713 1.67694C2.60609 1.79168 2.38699 1.96075 2.20248 2.17438C2.01797 2.38802 1.87171 2.64199 1.77213 2.92164C1.67255 3.20129 1.62163 3.50107 1.6223 3.80371Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="22.15%"
                              bottom="18.46%"
                              left="88.98%"
                              right="0%"
                              {...getOverrideProps(overrides, "Vector43681300")}
                            ></Icon>
                          </View>
                        </View>
                      </View>
                      <View
                        padding="0px 0px 0px 0px"
                        width="59.52px"
                        height="6.36px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="21.09%"
                        bottom="63.01%"
                        left="29.81%"
                        right="12.41%"
                        {...getOverrideProps(overrides, "<Group>43681301")}
                      >
                        <View
                          padding="0px 0px 0px 0px"
                          width="59.52px"
                          height="6.36px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0%"
                          left="0%"
                          right="0%"
                          {...getOverrideProps(overrides, "Group43681302")}
                        >
                          <Icon
                            width="4.29px"
                            height="5.97px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 4.289809703826904,
                              height: 5.9736104011535645,
                            }}
                            paths={[
                              {
                                d: "M1.85513 0.00677081C2.19049 -0.0211884 2.52701 0.0376716 2.84041 0.179105C3.15382 0.320537 3.43631 0.541018 3.66749 0.824643C3.89867 1.10827 4.07279 1.44797 4.17729 1.81924C4.28179 2.19051 4.31405 2.5841 4.27177 2.97161C4.27177 4.87786 3.38497 5.97361 1.85513 5.97361L0 5.97361L0 0.00677081L1.85513 0.00677081ZM0.797694 5.12977L1.76603 5.12977C2.00567 5.14641 2.24548 5.10076 2.46801 4.99615C2.69055 4.89154 2.89021 4.73059 3.05245 4.52502C3.2147 4.31946 3.33544 4.07445 3.4059 3.80783C3.47637 3.54121 3.49477 3.25969 3.45977 2.98377C3.49223 2.70894 3.47196 2.42917 3.40042 2.1645C3.32887 1.89984 3.20783 1.65683 3.04595 1.4529C2.88408 1.24897 2.68538 1.08917 2.4641 0.98493C2.24282 0.880693 2.00444 0.83461 1.76603 0.849981L0.797694 0.849981L0.797694 5.12977Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="4.52%"
                            bottom="1.53%"
                            left="0%"
                            right="92.79%"
                            {...getOverrideProps(overrides, "Vector43681303")}
                          ></Icon>
                          <Icon
                            width="3.67px"
                            height="4.68px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.6729280948638916,
                              height: 4.676750183105469,
                            }}
                            paths={[
                              {
                                d: "M0.00864627 2.33638C-0.0157206 2.04046 0.0134181 1.74189 0.0941906 1.45982C0.174963 1.17775 0.305585 0.918411 0.477684 0.698429C0.649783 0.478447 0.859558 0.302681 1.09355 0.182405C1.32755 0.06213 1.58059 1.77636e-15 1.83646 0C2.09234 1.77636e-15 2.34539 0.06213 2.57938 0.182405C2.81337 0.302681 3.02315 0.478447 3.19524 0.698429C3.36734 0.918411 3.49797 1.17775 3.57874 1.45982C3.65951 1.74189 3.68865 2.04046 3.66428 2.33638C3.68911 2.6326 3.66032 2.93158 3.57976 3.21411C3.4992 3.49663 3.36866 3.75645 3.19652 3.97686C3.02437 4.19728 2.81444 4.37341 2.58021 4.49395C2.34599 4.61448 2.09264 4.67675 1.83646 4.67675C1.58029 4.67675 1.32694 4.61448 1.09271 4.49395C0.858486 4.37341 0.648556 4.19728 0.476413 3.97686C0.304271 3.75645 0.173725 3.49663 0.0931662 3.21411C0.0126073 2.93158 -0.016183 2.6326 0.00864627 2.33638ZM2.8775 2.33638C2.8775 1.36031 2.50008 0.789509 1.83772 0.789509C1.17284 0.789509 0.798808 1.36031 0.798808 2.33639C0.798808 3.32028 1.17286 3.88668 1.83772 3.88668C2.5001 3.88667 2.8775 3.31636 2.8775 2.33638Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.17%"
                            left="8.68%"
                            right="85.15%"
                            {...getOverrideProps(overrides, "Vector43681304")}
                          ></Icon>
                          <Icon
                            width="5.37px"
                            height="4.5px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 5.37123441696167,
                              height: 4.5029296875,
                            }}
                            paths={[
                              {
                                d: "M4.30707 4.50293L3.51358 4.50293L2.71252 1.18652L2.652 1.18652L1.8543 4.50293L1.06836 4.50293L0 0L0.775849 0L1.47016 3.436L1.52732 3.436L2.32418 0L3.058 0L3.85485 3.436L3.91537 3.436L4.60632 0L5.37123 0L4.30707 4.50293Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="27.65%"
                            bottom="1.53%"
                            left="15.76%"
                            right="75.21%"
                            {...getOverrideProps(overrides, "Vector43681305")}
                          ></Icon>
                          <Icon
                            width="3.31px"
                            height="4.59px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.3052456378936768,
                              height: 4.594283103942871,
                            }}
                            paths={[
                              {
                                d: "M6.56695e-06 0.0913533L0.736339 0.0913533L0.736339 0.806682L0.793501 0.806682C0.890467 0.549762 1.05401 0.33439 1.26134 0.190584C1.46867 0.0467781 1.70945 -0.0182962 1.95012 0.00443254C2.13871 -0.0120404 2.32807 0.0209952 2.50418 0.101091C2.68029 0.181187 2.83862 0.306292 2.96746 0.467135C3.0963 0.627979 3.19234 0.82044 3.24846 1.03026C3.30458 1.24008 3.31935 1.46188 3.29167 1.67923L3.29167 4.59423L2.52675 4.59423L2.52675 1.90239C2.52675 1.17876 2.25609 0.818893 1.69039 0.818893C1.56235 0.811965 1.43453 0.837283 1.31569 0.893113C1.19686 0.948943 1.08983 1.03397 1.00193 1.14235C0.914023 1.25074 0.84733 1.37992 0.806421 1.52105C0.765512 1.66218 0.751353 1.81192 0.764915 1.96L0.764915 4.59428L0 4.59428L6.56695e-06 0.0913533Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.21%"
                            bottom="1.53%"
                            left="26.3%"
                            right="68.15%"
                            {...getOverrideProps(overrides, "Vector43681306")}
                          ></Icon>
                          <Icon
                            width="0.76px"
                            height="6.26px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 0.7649180293083191,
                              height: 6.260739803314209,
                            }}
                            paths={[
                              {
                                d: "M0 0L0.764918 0L0.764918 6.26074L0 6.26074L0 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="1.53%"
                            left="33.88%"
                            right="64.84%"
                            {...getOverrideProps(overrides, "Vector43681307")}
                          ></Icon>
                          <Icon
                            width="3.67px"
                            height="4.68px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.6733062267303467,
                              height: 4.676807403564453,
                            }}
                            paths={[
                              {
                                d: "M0.00862479 2.33646C-0.0157234 2.04052 0.0134332 1.74194 0.094225 1.45986C0.175017 1.17778 0.305661 0.918429 0.477777 0.698442C0.649894 0.478454 0.859685 0.302686 1.09369 0.182408C1.3277 0.062131 1.58077 0 1.83666 0C2.09254 0 2.34561 0.062131 2.57962 0.182408C2.81363 0.302686 3.02342 0.478454 3.19553 0.698442C3.36765 0.918429 3.49829 1.17778 3.57908 1.45986C3.65987 1.74194 3.68903 2.04052 3.66468 2.33646C3.68948 2.6327 3.66066 2.93168 3.58008 3.2142C3.4995 3.49673 3.36893 3.75654 3.19677 3.97695C3.02461 4.19736 2.81466 4.37348 2.58043 4.49401C2.34619 4.61454 2.09284 4.67681 1.83666 4.67681C1.58047 4.67681 1.32712 4.61454 1.09288 4.49401C0.858642 4.37348 0.648699 4.19736 0.47654 3.97695C0.30438 3.75654 0.173814 3.49673 0.0932301 3.2142C0.0126461 2.93168 -0.0161731 2.6327 0.00862479 2.33646ZM2.87748 2.33646C2.87748 1.36039 2.50006 0.789593 1.8377 0.789593C1.17281 0.789593 0.79879 1.36039 0.79879 2.33647C0.79879 3.32036 1.17284 3.88676 1.8377 3.88676C2.50007 3.88675 2.87748 3.31644 2.87748 2.33646Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.17%"
                            left="36.93%"
                            right="56.9%"
                            {...getOverrideProps(overrides, "Vector43681308")}
                          ></Icon>
                          <Icon
                            width="3.25px"
                            height="4.67px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.253002882003784,
                              height: 4.671329975128174,
                            }}
                            paths={[
                              {
                                d: "M0 3.3164C0 2.50585 0.519473 2.03857 1.44157 1.97216L2.49145 1.90185L2.49145 1.51318C2.49145 1.03759 2.22078 0.76904 1.69795 0.76904C1.27094 0.76904 0.975066 0.95117 0.890163 1.26953L0.149621 1.26953C0.227794 0.49609 0.854022 0 1.73325 0C2.70495 0 3.253 0.562001 3.253 1.51318L3.253 4.58984L2.51666 4.58984L2.51666 3.95703L2.45614 3.95703C2.3333 4.18402 2.16081 4.36907 1.95627 4.49328C1.75174 4.61749 1.5225 4.67641 1.29195 4.66403C1.12925 4.68369 0.964795 4.66353 0.809205 4.60483C0.653616 4.54613 0.510343 4.4502 0.388619 4.32323C0.266895 4.19626 0.169426 4.04107 0.10249 3.86765C0.0355548 3.69424 0.000641058 3.50645 0 3.3164ZM2.49145 2.93163L2.49145 2.55517L1.54497 2.62548C1.0112 2.66698 0.769114 2.87792 0.769114 3.27489C0.769114 3.68016 1.07172 3.916 1.48783 3.916C1.60975 3.93034 1.73291 3.91604 1.84997 3.87395C1.96704 3.83186 2.07562 3.76284 2.16926 3.671C2.2629 3.57915 2.33967 3.46637 2.39502 3.33935C2.45037 3.21233 2.48317 3.07367 2.49145 2.93163Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.25%"
                            left="44.44%"
                            right="50.09%"
                            {...getOverrideProps(overrides, "Vector43681309")}
                          ></Icon>
                          <Icon
                            width="3.62px"
                            height="6.34px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.6194911003112793,
                              height: 6.336010456085205,
                            }}
                            paths={[
                              {
                                d: "M0 4.00734C0 2.58449 0.629591 1.68312 1.60885 1.68312C1.85108 1.67015 2.09148 1.73756 2.30195 1.87745C2.51242 2.01735 2.68427 2.22395 2.79741 2.47312L2.85458 2.47312L2.85458 0L3.61949 0L3.61949 6.26074L2.88652 6.26074L2.88652 5.54931L2.826 5.54931C2.70411 5.7968 2.52627 6.00083 2.31173 6.13932C2.09719 6.2778 1.85412 6.34545 1.60886 6.33495C0.622867 6.335 0 5.43361 0 4.00734ZM0.790161 4.00734C0.790161 4.96242 1.17767 5.53713 1.82575 5.53713C2.47045 5.53713 2.86889 4.95413 2.86889 4.01125C2.86889 3.07277 2.46626 2.48146 1.82575 2.48146C1.18184 2.48146 0.790161 3.06007 0.790161 4.00734Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="0.35%"
                            left="51.6%"
                            right="42.32%"
                            {...getOverrideProps(overrides, "Vector43681310")}
                          ></Icon>
                          <Icon
                            width="3.67px"
                            height="4.68px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.6729280948638916,
                              height: 4.676750183105469,
                            }}
                            paths={[
                              {
                                d: "M0.00864672 2.33638C-0.0157201 2.04046 0.0134153 1.74189 0.0941878 1.45982C0.17496 1.17775 0.305586 0.918411 0.477684 0.698429C0.649783 0.478447 0.859552 0.302681 1.09355 0.182405C1.32754 0.06213 1.58059 0 1.83646 0C2.09234 1.77636e-15 2.34538 0.06213 2.57938 0.182405C2.81337 0.302681 3.02314 0.478447 3.19524 0.698429C3.36734 0.918411 3.49796 1.17775 3.57873 1.45982C3.65951 1.74189 3.68865 2.04046 3.66428 2.33638C3.68911 2.6326 3.66032 2.93158 3.57976 3.21411C3.4992 3.49663 3.36865 3.75645 3.19651 3.97686C3.02437 4.19728 2.81444 4.37341 2.58021 4.49395C2.34598 4.61448 2.09264 4.67675 1.83646 4.67675C1.58029 4.67675 1.32694 4.61448 1.09271 4.49395C0.858483 4.37341 0.648553 4.19728 0.47641 3.97686C0.304268 3.75645 0.173722 3.49663 0.0931633 3.21411C0.0126045 2.93158 -0.0161826 2.6326 0.00864672 2.33638ZM2.8775 2.33638C2.8775 1.36031 2.50008 0.789509 1.83773 0.789509C1.17284 0.789509 0.798808 1.36031 0.798808 2.33639C0.798808 3.32028 1.17286 3.88668 1.83773 3.88668C2.50009 3.88667 2.8775 3.31636 2.8775 2.33638Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0.17%"
                            left="62.98%"
                            right="30.85%"
                            {...getOverrideProps(overrides, "Vector43681311")}
                          ></Icon>
                          <Icon
                            width="3.31px"
                            height="4.59px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.305246591567993,
                              height: 4.594283103942871,
                            }}
                            paths={[
                              {
                                d: "M0 0.0913533L0.736339 0.0913533L0.736339 0.806682L0.793504 0.806682C0.89047 0.549762 1.05401 0.33439 1.26134 0.190584C1.46867 0.0467781 1.70945 -0.0182962 1.95013 0.00443254C2.13872 -0.0120404 2.32807 0.0209952 2.50418 0.101091C2.68029 0.181187 2.83863 0.306292 2.96747 0.467135C3.0963 0.627979 3.19234 0.82044 3.24847 1.03026C3.30459 1.24008 3.31935 1.46188 3.29167 1.67923L3.29167 4.59423L2.52676 4.59423L2.52676 1.90239C2.52676 1.17876 2.2561 0.818893 1.69039 0.818893C1.56235 0.811965 1.43453 0.837283 1.31569 0.893113C1.19686 0.948943 1.08983 1.03397 1.00193 1.14235C0.914023 1.25074 0.84733 1.37992 0.806421 1.52105C0.765512 1.66218 0.751356 1.81192 0.764918 1.96L0.764918 4.59428L0 4.59428L0 0.0913533Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.21%"
                            bottom="1.53%"
                            left="70.86%"
                            right="23.58%"
                            {...getOverrideProps(overrides, "Vector43681312")}
                          ></Icon>
                          <Icon
                            width="2.22px"
                            height="5.65px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 2.220790386199951,
                              height: 5.648919582366943,
                            }}
                            paths={[
                              {
                                d: "M1.38106 0L1.38106 1.1416L2.22079 1.1416L2.22079 1.89014L1.38106 1.89014L1.38106 4.20557C1.38106 4.67725 1.54833 4.88379 1.92911 4.88379C2.02659 4.88343 2.12398 4.87658 2.22079 4.86328L2.22079 5.60351C2.08342 5.63206 1.94424 5.64725 1.8047 5.64892C0.954047 5.64892 0.615297 5.30126 0.615297 4.4331L0.615297 1.8901L0 1.8901L0 1.14156L0.615297 1.14156L0.615297 0L1.38106 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="10.01%"
                            bottom="1.14%"
                            left="81.34%"
                            right="14.93%"
                            {...getOverrideProps(overrides, "Vector43681313")}
                          ></Icon>
                          <Icon
                            width="3.35px"
                            height="6.26px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.3467023372650146,
                              height: 6.260749816894531,
                            }}
                            paths={[
                              {
                                d: "M0 0L0.758193 0L0.758193 2.48145L0.818708 2.48145C0.920392 2.22213 1.08843 2.00554 1.29991 1.86119C1.51139 1.71684 1.75603 1.65176 2.00051 1.67481C2.18812 1.66295 2.37566 1.69934 2.54977 1.78138C2.72388 1.86342 2.88027 1.9891 3.00776 2.14943C3.13525 2.30977 3.23072 2.50082 3.28734 2.70896C3.34397 2.91709 3.36035 3.13718 3.33533 3.35352L3.33533 6.26075L2.56965 6.26075L2.56965 3.57275C2.56965 2.85351 2.2813 2.48925 1.74085 2.48925C1.60938 2.47672 1.47714 2.49772 1.35339 2.55077C1.22963 2.60383 1.11735 2.68766 1.0244 2.79639C0.931443 2.90513 0.860062 3.03615 0.815247 3.18029C0.770432 3.32443 0.753266 3.4782 0.764951 3.63085L0.764951 6.26073L3.28347e-05 6.26073L0 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="1.53%"
                            left="86.82%"
                            right="7.55%"
                            {...getOverrideProps(overrides, "Vector43681314")}
                          ></Icon>
                          <Icon
                            width="3.53px"
                            height="4.69px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.5326240062713623,
                              height: 4.687486171722412,
                            }}
                            paths={[
                              {
                                d: "M3.4864 3.3741C3.38247 3.78563 3.1573 4.14162 2.85029 4.37977C2.54327 4.61792 2.17395 4.72307 1.80694 4.67683C1.55159 4.68466 1.29783 4.62784 1.06327 4.51031C0.828721 4.39278 0.618991 4.21736 0.448641 3.99622C0.278292 3.77508 0.151407 3.51353 0.0767878 3.2297C0.00216879 2.94588 -0.0183913 2.6466 0.0165295 2.35262C-0.0174741 2.05773 0.00344808 1.7578 0.0778911 1.47313C0.152334 1.18845 0.278559 0.925685 0.448011 0.702612C0.617463 0.479539 0.82619 0.301367 1.06006 0.18016C1.29394 0.0589522 1.54749 -0.00246148 1.80357 7.55166e-05C2.88202 7.55166e-05 3.53262 0.856075 3.53262 2.27008L3.53262 2.58017L0.795731 2.58017L0.795731 2.62997C0.783753 2.79521 0.801634 2.96154 0.84822 3.11829C0.894806 3.27504 0.969071 3.41875 1.06626 3.54021C1.16344 3.66166 1.2814 3.75818 1.41257 3.82358C1.54374 3.88898 1.68522 3.92181 1.82795 3.91997C2.01092 3.94548 2.19629 3.90721 2.36049 3.81C2.52469 3.71279 2.66029 3.56105 2.75005 3.37407L3.4864 3.3741ZM0.795711 1.92293L2.7534 1.92293C2.76304 1.7718 2.74536 1.62 2.7015 1.47728C2.65764 1.33456 2.58857 1.20408 2.49872 1.09422C2.40887 0.984367 2.30023 0.897562 2.17979 0.839386C2.05934 0.781211 1.92976 0.752952 1.79935 0.756425C1.66706 0.754494 1.53576 0.783352 1.41322 0.841296C1.29068 0.899241 1.17936 0.985101 1.08583 1.09381C0.992292 1.20253 0.918439 1.33189 0.868617 1.47429C0.818795 1.61669 0.793991 1.76923 0.795711 1.92293Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="26.28%"
                            bottom="0%"
                            left="94.06%"
                            right="0%"
                            {...getOverrideProps(overrides, "Vector43681315")}
                          ></Icon>
                        </View>
                      </View>
                    </View>
                  </View>
                </Flex>
                <Flex
                  gap="10px"
                  direction="column"
                  width="123px"
                  height="39px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 55743681316")}
                >
                  <Image
                    width="132px"
                    height="39px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={playstoresrc}
                    onClick={() => {
                      googleplaybadgeTwoFourThreeSixEightOneThreeOneSevenOnClick();
                    }}
                    {...getOverrideProps(
                      overrides,
                      "google-play-badge 243681317"
                    )}
                  ></Image>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </View>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "What")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="20px 20px 20px 20px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left42831022")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage42831023")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message42831024")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(177,85,231,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What is Rundezvous"
                {...getOverrideProps(overrides, "Eyebrow")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Running in groups is fun, great for your mental and physical health and lets you meet new people."
                {...getOverrideProps(overrides, "Heading42831026")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Our mission is to make running in groups fast, easy and dynamic. Allow people to find people in their area with similar schedules to run with."
                {...getOverrideProps(overrides, "Body42831027")}
              ></Text>
            </Flex>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Get started"
              onClick={() => {
                buttonFourTwoEightThreeOneZeroTwoEightOnClick();
              }}
              {...getOverrideProps(overrides, "Button42831028")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="30px 20px 30px 20px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "How42831031")}
      >
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 0px 24px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 13642831032")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37442831033")}
          >
            <Flex
              gap="0"
              direction="row"
              width="50px"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="8px 8px 8px 8px"
              backgroundColor="rgba(223,188,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 43042831034")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="1"
                {...getOverrideProps(overrides, "1")}
              ></Text>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Input your weekly schedule"
              {...getOverrideProps(overrides, "Input your weekly schedule")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace.  The more flexible you can be with your schedule the more runs you’ll get matched with. All changes need to be done by 18:00 on Wednesday evening."
              {...getOverrideProps(
                overrides,
                "Sign up for our service for free, tell us roughly where you are, when you are free each week and your sociable running pace. The more flexible you can be with your schedule the more runs you\u2019ll get matched with. All changes need to be done by 18:00 on Wednesday evening."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 393")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            borderRadius="8px"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37442831044")}
          >
            <Flex
              gap="0"
              direction="row"
              width="50px"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="8px 8px 8px 8px"
              backgroundColor="rgba(223,188,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 43042831045")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="2"
                {...getOverrideProps(overrides, "342831046")}
              ></Text>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Offer you running events."
              {...getOverrideProps(overrides, "Offer you running events.")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="We then use our algorithm to take in this information and create dynamic running events which best suit yours and other people’s schedules. These will be located as close to your home location as possible - the more people who sign-up in your area, the more flexible this will become. You will receive a list of suggested runs for the following week which you can accept or reject at that point by 18:00 on Friday."
              {...getOverrideProps(
                overrides,
                "We then use our algorithm to take in this information and create dynamic running events which best suit yours and other people\u2019s schedules. These will be located as close to your home location as possible - the more people who sign-up in your area, the more flexible this will become. You will receive a list of suggested runs for the following week which you can accept or reject at that point by 18:00 on Friday."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 0px 20px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 394")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            borderRadius="8px"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37442831152")}
          >
            <Flex
              gap="0"
              direction="row"
              width="50px"
              height="50px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="8px 8px 8px 8px"
              backgroundColor="rgba(223,188,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 43042831153")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="3"
                {...getOverrideProps(overrides, "342831154")}
              ></Text>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="If enough people sign-up - Game on!"
              {...getOverrideProps(
                overrides,
                "If enough people sign-up - Game on!"
              )}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="If at least 3 people accept the runs you accepted, you will receive a confirmation email that the run is going ahead with full details of meeting point and time. You just turn up then and there, meet your group and head off for a casual run. Don’t forget to share group pictures on your social media with #Rundezvous!"
              {...getOverrideProps(
                overrides,
                "If at least 3 people accept the runs you accepted, you will receive a confirmation email that the run is going ahead with full details of meeting point and time. You just turn up then and there, meet your group and head off for a casual run. Don\u2019t forget to share group pictures on your social media with #Rundezvous!"
              )}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="30px 20px 30px 20px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "How43681220")}
      >
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 0px 24px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 13643681221")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
            padding="40px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37443681222")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Available on mobile"
              {...getOverrideProps(overrides, "Available on mobile")}
            ></Text>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 55843681277")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                onClick={() => {
                  frameFiveFiveSixFourThreeSixEightOneTwoSevenFiveOnClick();
                }}
                {...getOverrideProps(overrides, "Frame 55643681275")}
              >
                <View
                  width="80px"
                  height="30px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "appstoreblack 143681240")}
                >
                  <View
                    padding="0px 0px 0px 0px"
                    width="80px"
                    height="30px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Group43681241")}
                  >
                    <View
                      padding="0px 0px 0px 0px"
                      width="80px"
                      height="30px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="0%"
                      bottom="0%"
                      left="0%"
                      right="0%"
                      {...getOverrideProps(overrides, "Group43681242")}
                    >
                      <View
                        padding="0px 0px 0px 0px"
                        width="80px"
                        height="30px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="0%"
                        bottom="0%"
                        left="0%"
                        right="0%"
                        {...getOverrideProps(overrides, "Group43681243")}
                      >
                        <Icon
                          width="80px"
                          height="30px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 80,
                            height: 30.000097274780273,
                          }}
                          paths={[
                            {
                              d: "M73.6293 9.75e-05L6.3743 9.75e-05C6.12914 9.75e-05 5.88693 9.75e-05 5.64243 0.0015975C5.43776 0.0030975 5.23471 0.007455 5.02808 0.0111225C4.57916 0.0170489 4.1313 0.0613547 3.68839 0.143655C3.2461 0.227747 2.81767 0.386284 2.41758 0.613905C2.01798 0.843451 1.65286 1.14172 1.33545 1.4979C1.01638 1.85308 0.750409 2.26361 0.547767 2.71372C0.344565 3.16293 0.203664 3.6443 0.12993 4.14121C0.055496 4.63749 0.0154421 5.13949 0.0101284 5.64271C0.00392432 5.87266 0.00326915 6.10337 0 6.33335L0 23.669C0.00326915 23.9019 0.00392432 24.1275 0.0101284 24.3605C0.0154438 24.8637 0.0554976 25.3656 0.12993 25.8619C0.20346 26.3591 0.344369 26.8407 0.547767 27.2901C0.750317 27.7388 1.01633 28.1476 1.33545 28.5008C1.65165 28.8586 2.01701 29.1571 2.41758 29.3849C2.81767 29.6131 3.24605 29.7726 3.68839 29.858C4.13138 29.9396 4.57919 29.9839 5.02808 29.9906C5.23471 29.9957 5.43776 29.9986 5.64243 29.9986C5.88693 30.0001 6.12916 30.0001 6.3743 30.0001L73.6293 30.0001C73.8696 30.0001 74.1137 30.0001 74.354 29.9986C74.5577 29.9986 74.7666 29.9957 74.9703 29.9906C75.4184 29.9843 75.8653 29.94 76.3074 29.858C76.7512 29.772 77.1811 29.6125 77.5831 29.3849C77.9833 29.1569 78.3483 28.8585 78.6643 28.5008C78.9826 28.1463 79.2491 27.7377 79.4542 27.2901C79.6561 26.8404 79.7956 26.3588 79.8681 25.8619C79.9426 25.3656 79.9841 24.8637 79.9922 24.3605C79.9948 24.1275 79.9948 23.9019 79.9948 23.669C80 23.3966 80 23.1256 80 22.8487L80 7.15219C80 6.87754 80 6.60508 79.9948 6.33335C79.9948 6.10337 79.9948 5.87266 79.9922 5.64268C79.9841 5.13941 79.9426 4.63752 79.8681 4.14118C79.7954 3.64456 79.6559 3.16322 79.4542 2.71369C79.0415 1.81149 78.3871 1.07712 77.5831 0.613837C77.1811 0.386773 76.7511 0.228278 76.3074 0.143587C75.8654 0.0609246 75.4184 0.0166033 74.9703 0.0110175C74.7666 0.0073575 74.5577 0.0029625 74.354 0.0015C74.1137 0 73.8696 9.75e-05 73.6293 9.75e-05Z",
                              fill: "rgba(166,166,166,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0%"
                          left="0%"
                          right="0%"
                          {...getOverrideProps(overrides, "Vector43681244")}
                        ></Icon>
                        <Icon
                          width="78.83px"
                          height="28.69px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 78.83272552490234,
                            height: 28.68899917602539,
                          }}
                          paths={[
                            {
                              d: "M5.06205 28.6875C4.85836 28.6875 4.65959 28.6846 4.4575 28.6795C4.03885 28.6734 3.62118 28.6325 3.20791 28.5571C2.82255 28.4827 2.44925 28.3442 2.10031 28.1462C1.75457 27.9499 1.43923 27.6925 1.16637 27.3838C0.889551 27.0787 0.65909 26.7249 0.484115 26.3364C0.307213 25.9453 0.184786 25.5262 0.121099 25.0935C0.0523227 24.6286 0.0151119 24.1585 0.00978742 23.6873C0.00554889 23.5291 0 23.0024 0 23.0024L0 5.677C0 5.677 0.00590991 5.15845 0.00982085 5.0061C0.014919 4.53561 0.0519135 4.06624 0.120484 3.60205C0.184288 3.16819 0.30681 2.74781 0.483808 2.35547C0.658139 1.96721 0.88732 1.61315 1.16247 1.30701C1.43731 0.997966 1.75366 0.739203 2.09999 0.540157C2.44812 0.34282 2.82072 0.205299 3.20529 0.132203C3.61992 0.0561271 4.03904 0.0149971 4.45914 0.00915751L5.06238 0L73.7671 0L74.3775 0.00952502C74.7938 0.0150744 75.2091 0.0558375 75.6199 0.131467C76.0084 0.205481 76.3849 0.343962 76.737 0.542355C77.4308 0.94349 77.9954 1.57812 78.3515 2.3573C78.5257 2.74693 78.6463 3.16388 78.7093 3.59399C78.7787 4.06199 78.8175 4.53505 78.8255 5.0094C78.8274 5.2218 78.8274 5.44995 78.8274 5.677C78.8327 5.95825 78.8327 6.22595 78.8327 6.49585L78.8327 22.1924C78.8327 22.4648 78.8327 22.7307 78.8274 22.9988C78.8274 23.2427 78.8274 23.4661 78.8248 23.696C78.817 24.1619 78.7788 24.6265 78.7106 25.0862C78.6482 25.5219 78.5264 25.9444 78.3496 26.3386C78.1734 26.723 77.9442 27.0737 77.6706 27.3779C77.3975 27.6883 77.0816 27.9472 76.7351 28.1448C76.3839 28.3443 76.008 28.4833 75.6199 28.5571C75.2067 28.6329 74.789 28.6738 74.3703 28.6795C74.1745 28.6846 73.9694 28.6875 73.7704 28.6875L73.0457 28.689L5.06205 28.6875Z",
                              fill: "rgba(0,0,0,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="2.19%"
                          bottom="2.18%"
                          left="0.73%"
                          right="0.73%"
                          onClick={() => {
                            vectorFourThreeSixEightOneTwoFourFiveOnClick();
                          }}
                          {...getOverrideProps(overrides, "Vector43681245")}
                        ></Icon>
                      </View>
                      <View
                        padding="0px 0px 0px 0px"
                        width="66.53px"
                        height="18.58px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="21.8%"
                        bottom="16.27%"
                        left="8.33%"
                        right="8.5%"
                        {...getOverrideProps(overrides, "<Group>43681246")}
                      >
                        <View
                          padding="0px 0px 0px 0px"
                          width="11.84px"
                          height="16.33px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="12.09%"
                          left="0%"
                          right="82.2%"
                          {...getOverrideProps(overrides, "<Group>43681247")}
                        >
                          <View
                            padding="0px 0px 0px 0px"
                            width="11.84px"
                            height="16.33px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="0%"
                            left="0%"
                            right="0%"
                            {...getOverrideProps(overrides, "<Group>43681248")}
                          >
                            <Icon
                              width="11.84px"
                              height="12.39px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 11.844191551208496,
                                height: 12.392423629760742,
                              }}
                              paths={[
                                {
                                  d: "M9.89257 4.74576C9.89976 4.11976 10.048 3.50598 10.3234 2.96151C10.5989 2.41704 10.9926 1.95959 11.468 1.63171C11.166 1.14784 10.7676 0.749634 10.3044 0.46872C9.84124 0.187805 9.32605 0.0319223 8.79978 0.01345C7.67714 -0.118745 6.58879 0.767073 6.01672 0.767073C5.43357 0.767073 4.55277 0.026575 3.6043 0.0484675C2.9908 0.0707036 2.39289 0.270844 1.86884 0.629387C1.34479 0.987931 0.912461 1.49265 0.613981 2.09437C-0.678972 4.60568 0.285454 8.29647 1.52399 10.3264C2.14366 11.3204 2.86788 12.4308 3.8155 12.3914C4.7428 12.3483 5.08913 11.728 6.20846 11.728C7.31741 11.728 7.64234 12.3914 8.60918 12.3664C9.60422 12.3482 10.2311 11.3679 10.8291 10.3645C11.2743 9.65624 11.6169 8.87345 11.8442 8.04513C11.2661 7.77084 10.7728 7.3117 10.4257 6.72498C10.0787 6.13826 9.89324 5.44991 9.89257 4.74576Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="24.12%"
                              bottom="0%"
                              left="0%"
                              right="0%"
                              {...getOverrideProps(overrides, "<Path>43681249")}
                            ></Icon>
                            <Icon
                              width="2.93px"
                              height="3.77px"
                              viewBox={{
                                minX: 0,
                                minY: 0,
                                width: 2.925063133239746,
                                height: 3.7659130096435547,
                              }}
                              paths={[
                                {
                                  d: "M2.17045 2.61796C2.71298 1.88731 2.98027 0.948174 2.91555 0C2.08667 0.0976654 1.32102 0.542085 0.771156 1.24471C0.502309 1.58796 0.296402 1.98728 0.165204 2.41986C0.0340068 2.85244 -0.0199074 3.30978 0.00654304 3.76575C0.42113 3.77054 0.831281 3.66973 1.2061 3.47091C1.58093 3.2721 1.91066 2.98046 2.17045 2.61796Z",
                                  fill: "rgba(255,255,255,1)",
                                  fillRule: "nonzero",
                                },
                              ]}
                              display="block"
                              gap="unset"
                              alignItems="unset"
                              justifyContent="unset"
                              position="absolute"
                              top="0%"
                              bottom="76.94%"
                              left="49.78%"
                              right="25.52%"
                              {...getOverrideProps(overrides, "<Path>43681250")}
                            ></Icon>
                          </View>
                        </View>
                        <View
                          padding="0px 0px 0px 0px"
                          width="50.18px"
                          height="11.72px"
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="36.94%"
                          bottom="0%"
                          left="24.57%"
                          right="0%"
                          {...getOverrideProps(overrides, "Group43681251")}
                        >
                          <Icon
                            width="7.39px"
                            height="9.31px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 7.387213706970215,
                              height: 9.313499450683594,
                            }}
                            paths={[
                              {
                                d: "M5.26474 6.79616L2.10028 6.79616L1.34034 9.3135L0 9.3135L2.99733 0L4.38989 0L7.38721 9.3135L6.02402 9.3135L5.26474 6.79616ZM2.42802 5.63454L4.93637 5.63454L3.69984 1.54909L3.66523 1.54909L2.42802 5.63454Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="1.33%"
                            bottom="19.17%"
                            left="0%"
                            right="85.28%"
                            {...getOverrideProps(overrides, "Vector43681252")}
                          ></Icon>
                          <Icon
                            width="5.7px"
                            height="9.11px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 5.701528072357178,
                              height: 9.10891342163086,
                            }}
                            paths={[
                              {
                                d: "M5.70153 3.46781C5.70153 5.57792 4.6948 6.93363 3.17558 6.93363C2.79073 6.95621 2.40799 6.85676 2.07119 6.64666C1.73438 6.43655 1.45712 6.12429 1.27116 5.74563L1.24241 5.74563L1.24241 9.10891L0 9.10891L0 0.0722996L1.20261 0.0722996L1.20261 1.20169L1.22546 1.20169C1.41997 0.824845 1.70191 0.514202 2.04128 0.302818C2.38065 0.0914335 2.76476 -0.0127844 3.15273 0.00125187C4.6889 0.00125937 5.70153 1.36356 5.70153 3.46781ZM4.42451 3.46781C4.42451 2.09306 3.79123 1.18924 2.82498 1.18924C1.87571 1.18924 1.2372 2.1121 1.2372 3.46781C1.2372 4.83598 1.87571 5.75224 2.82498 5.75224C3.79123 5.75224 4.42451 4.85502 4.42451 3.46781Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.25%"
                            bottom="0%"
                            left="16.26%"
                            right="72.38%"
                            {...getOverrideProps(overrides, "Vector43681253")}
                          ></Icon>
                          <Icon
                            width="5.7px"
                            height="9.11px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 5.701525688171387,
                              height: 9.108906745910645,
                            }}
                            paths={[
                              {
                                d: "M5.70153 3.4678C5.70153 5.57791 4.6948 6.93362 3.17558 6.93362C2.79073 6.95621 2.40799 6.85675 2.07119 6.64665C1.73438 6.43655 1.45712 6.12429 1.27116 5.74562L1.24241 5.74562L1.24241 9.10891L0 9.10891L0 0.0722929L1.20258 0.0722929L1.20258 1.20169L1.22543 1.20169C1.41995 0.824839 1.70189 0.514197 2.04126 0.302814C2.38063 0.0914309 2.76474 -0.0127862 3.15271 0.00125223C4.68893 0.00125223 5.70153 1.36355 5.70153 3.4678ZM4.42451 3.4678C4.42451 2.09305 3.79123 1.18924 2.82498 1.18924C1.87571 1.18924 1.2372 2.11209 1.2372 3.4678C1.2372 4.83597 1.87571 5.75223 2.82498 5.75223C3.79123 5.75223 4.42451 4.85501 4.42451 3.4678Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.25%"
                            bottom="0%"
                            left="29.53%"
                            right="59.1%"
                            {...getOverrideProps(overrides, "Vector43681254")}
                          ></Icon>
                          <Icon
                            width="6.37px"
                            height="9.62px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 6.36939811706543,
                              height: 9.623998641967773,
                            }}
                            paths={[
                              {
                                d: "M1.27181 6.87382C1.36387 7.7974 2.16364 8.40382 3.25653 8.40382C4.30374 8.40382 5.05715 7.79737 5.05715 6.9646C5.05715 6.2417 4.60275 5.80885 3.52682 5.51221L2.4509 5.22144C0.926449 4.80835 0.218732 4.00855 0.218732 2.71069C0.218732 1.10376 1.46702 0 3.23956 0C4.9938 0 6.1964 1.10376 6.23688 2.71069L4.9827 2.71069C4.90763 1.78125 4.22276 1.22022 3.22192 1.22022C2.22107 1.22022 1.53621 1.78785 1.53621 2.61402C1.53621 3.27247 1.97363 3.65992 3.04368 3.95652L3.95835 4.20847C5.66168 4.66038 6.3694 5.42797 6.3694 6.79026C6.3694 8.53269 5.13221 9.624 3.16446 9.624C1.32336 9.624 0.0803048 8.55832 0 6.87375L1.27181 6.87382Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="0%"
                            bottom="17.85%"
                            left="47.14%"
                            right="40.17%"
                            {...getOverrideProps(overrides, "Vector43681255")}
                          ></Icon>
                          <Icon
                            width="3.28px"
                            height="8.44px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.279357433319092,
                              height: 8.436675071716309,
                            }}
                            paths={[
                              {
                                d: "M2.12835 0L2.12835 1.60694L3.27936 1.60694L3.27936 2.71069L2.12835 2.71069L2.12835 6.4541C2.12835 7.03564 2.35882 7.30664 2.86479 7.30664C3.00143 7.30397 3.13785 7.2932 3.27348 7.27439L3.27348 8.37156C3.046 8.41924 2.81478 8.44084 2.5834 8.43601C1.35797 8.43601 0.880063 7.91965 0.880063 6.60276L0.880063 2.71069L0 2.71069L0 1.60694L0.880047 1.60694L0.880047 0L2.12835 0Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="9.15%"
                            bottom="18.84%"
                            left="60.93%"
                            right="32.53%"
                            {...getOverrideProps(overrides, "Vector43681256")}
                          ></Icon>
                          <Icon
                            width="5.74px"
                            height="6.96px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 5.74198579788208,
                              height: 6.958004951477051,
                            }}
                            paths={[
                              {
                                d: "M0 3.479C0 1.34253 1.12162 0 2.87067 0C4.62558 0 5.74199 1.3425 5.74199 3.479C5.74199 5.62134 4.63146 6.958 2.87067 6.958C1.1105 6.958 0 5.62133 0 3.479ZM4.47607 3.479C4.47607 2.01343 3.87739 1.14844 2.87066 1.14844C1.86394 1.14844 1.26591 2.02002 1.26591 3.479C1.26591 4.95044 1.86394 5.80884 2.87066 5.80884C3.87739 5.80884 4.47607 4.95044 4.47607 3.479Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.15%"
                            bottom="18.46%"
                            left="68.79%"
                            right="19.76%"
                            {...getOverrideProps(overrides, "Vector43681257")}
                          ></Icon>
                          <Icon
                            width="3.1px"
                            height="6.86px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 3.0952789783477783,
                              height: 6.862511157989502,
                            }}
                            paths={[
                              {
                                d: "M5.10054e-06 0.0722364L1.18496 0.0722364L1.18496 1.22799L1.21371 1.22799C1.29388 0.867021 1.48238 0.548023 1.74751 0.324609C2.01265 0.101195 2.33825 -0.013014 2.66961 0.00118005C2.81279 0.000619225 2.95557 0.0180644 3.09528 0.0531863L3.09528 1.3569C2.91453 1.29493 2.72597 1.26648 2.53705 1.27267C2.35656 1.26446 2.17662 1.30016 2.00959 1.37732C1.84256 1.45449 1.6924 1.57129 1.5694 1.7197C1.44641 1.86812 1.3535 2.04464 1.29706 2.23715C1.24061 2.42965 1.22197 2.63357 1.24241 2.83492L1.24241 6.86251L0 6.86251L5.10054e-06 0.0722364Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.25%"
                            bottom="19.17%"
                            left="82.28%"
                            right="11.55%"
                            {...getOverrideProps(overrides, "Vector43681258")}
                          ></Icon>
                          <Icon
                            width="5.53px"
                            height="6.96px"
                            viewBox={{
                              minX: 0,
                              minY: 0,
                              width: 5.529155254364014,
                              height: 6.958004951477051,
                            }}
                            paths={[
                              {
                                d: "M5.45995 4.87939C5.29281 6.11206 4.22276 6.958 2.85369 6.958C1.0929 6.958 0 5.63452 0 3.51123C0 1.38135 1.09878 0 2.80146 0C4.47607 0 5.52916 1.29052 5.52916 3.34936L5.52916 3.8269L1.25417 3.8269L1.25417 3.91113C1.23444 4.16103 1.26232 4.41278 1.33598 4.64966C1.40964 4.88654 1.52738 5.10313 1.68138 5.28504C1.83539 5.46695 2.02212 5.61002 2.2292 5.70475C2.43627 5.79948 2.65894 5.8437 2.88242 5.83447C3.17602 5.86533 3.47065 5.78905 3.7225 5.61696C3.97434 5.44488 4.16995 5.1862 4.28022 4.87939L5.45995 4.87939ZM1.26004 2.85278L4.28609 2.85278C4.29724 2.62808 4.26687 2.40323 4.19689 2.19232C4.12691 1.98141 4.01883 1.78901 3.87942 1.62719C3.74002 1.46536 3.5723 1.33761 3.3868 1.25195C3.2013 1.16628 3.00202 1.12456 2.80146 1.12939C2.59914 1.12804 2.39858 1.17165 2.21137 1.25771C2.02415 1.34376 1.85397 1.47056 1.71067 1.63079C1.56736 1.79101 1.45376 1.98149 1.37641 2.19123C1.29907 2.40097 1.25952 2.6258 1.26004 2.85278Z",
                                fill: "rgba(255,255,255,1)",
                                fillRule: "nonzero",
                              },
                            ]}
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            position="absolute"
                            top="22.15%"
                            bottom="18.46%"
                            left="88.98%"
                            right="0%"
                            {...getOverrideProps(overrides, "Vector43681259")}
                          ></Icon>
                        </View>
                      </View>
                    </View>
                    <View
                      padding="0px 0px 0px 0px"
                      width="46.23px"
                      height="4.77px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="21.09%"
                      bottom="63.01%"
                      left="29.81%"
                      right="12.41%"
                      {...getOverrideProps(overrides, "<Group>43681260")}
                    >
                      <View
                        padding="0px 0px 0px 0px"
                        width="46.23px"
                        height="4.77px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        position="absolute"
                        top="0%"
                        bottom="0%"
                        left="0%"
                        right="0%"
                        {...getOverrideProps(overrides, "Group43681261")}
                      >
                        <Icon
                          width="3.33px"
                          height="4.48px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 3.3318910598754883,
                            height: 4.480207920074463,
                          }}
                          paths={[
                            {
                              d: "M1.44088 0.00507811C1.70135 -0.0158913 1.96272 0.0282537 2.20615 0.134328C2.44957 0.240403 2.66898 0.405763 2.84854 0.618482C3.02809 0.831201 3.16333 1.08597 3.2445 1.36443C3.32566 1.64288 3.35072 1.93807 3.31788 2.22871C3.31788 3.6584 2.62911 4.48021 1.44088 4.48021L0 4.48021L0 0.00507811L1.44088 0.00507811ZM0.619568 3.84733L1.37167 3.84733C1.5578 3.85981 1.74406 3.82557 1.9169 3.74711C2.08975 3.66865 2.24482 3.54794 2.37084 3.39377C2.49685 3.23959 2.59063 3.05584 2.64536 2.85587C2.70009 2.65591 2.71438 2.44477 2.6872 2.23783C2.71241 2.0317 2.69667 1.82187 2.6411 1.62338C2.58553 1.42488 2.49152 1.24263 2.36579 1.08968C2.24006 0.936731 2.08573 0.816875 1.91387 0.738697C1.742 0.66052 1.55685 0.625957 1.37167 0.637486L0.619568 0.637486L0.619568 3.84733Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="4.52%"
                          bottom="1.53%"
                          left="0%"
                          right="92.79%"
                          {...getOverrideProps(overrides, "Vector43681262")}
                        ></Icon>
                        <Icon
                          width="2.85px"
                          height="3.51px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.852759599685669,
                            height: 3.5075626373291016,
                          }}
                          paths={[
                            {
                              d: "M0.00671555 1.75228C-0.0122102 1.53034 0.0104218 1.30642 0.0731578 1.09487C0.135894 0.883315 0.237348 0.688809 0.371017 0.523822C0.504685 0.358835 0.667618 0.227011 0.849361 0.136804C1.0311 0.0465975 1.22764 1.33227e-15 1.42638 0C1.62512 1.33227e-15 1.82166 0.0465975 2.0034 0.136804C2.18514 0.227011 2.34807 0.358835 2.48174 0.523822C2.61541 0.688809 2.71687 0.883315 2.7796 1.09487C2.84234 1.30642 2.86497 1.53034 2.84604 1.75228C2.86533 1.97445 2.84297 2.19869 2.7804 2.41058C2.71783 2.62247 2.61643 2.81734 2.48273 2.98265C2.34903 3.14796 2.18597 3.28006 2.00405 3.37046C1.82212 3.46086 1.62535 3.50756 1.42638 3.50756C1.22741 3.50756 1.03064 3.46086 0.84871 3.37046C0.666785 3.28006 0.503733 3.14796 0.37003 2.98265C0.236327 2.81734 0.134932 2.62247 0.0723621 2.41058C0.00979211 2.19869 -0.0125693 1.97445 0.00671555 1.75228ZM2.23495 1.75228C2.23495 1.02023 1.94181 0.592132 1.42736 0.592132C0.91094 0.592132 0.620433 1.02023 0.620433 1.75229C0.620433 2.49021 0.91096 2.91501 1.42736 2.91501C1.94183 2.915 2.23495 2.48727 2.23495 1.75228Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.17%"
                          left="8.68%"
                          right="85.15%"
                          {...getOverrideProps(overrides, "Vector43681263")}
                        ></Icon>
                        <Icon
                          width="4.17px"
                          height="3.38px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 4.17183256149292,
                            height: 3.377197265625,
                          }}
                          paths={[
                            {
                              d: "M3.3453 3.3772L2.72899 3.3772L2.10681 0.88989L2.05981 0.88989L1.44023 3.3772L0.829797 3.3772L0 0L0.602601 0L1.14187 2.577L1.18627 2.577L1.80519 0L2.37514 0L2.99406 2.577L3.04107 2.577L3.57772 0L4.17183 0L3.3453 3.3772Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="27.65%"
                          bottom="1.53%"
                          left="15.76%"
                          right="75.21%"
                          {...getOverrideProps(overrides, "Vector43681264")}
                        ></Icon>
                        <Icon
                          width="2.57px"
                          height="3.45px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.56718111038208,
                            height: 3.4457123279571533,
                          }}
                          paths={[
                            {
                              d: "M5.10054e-06 0.068515L0.571913 0.068515L0.571913 0.605012L0.616311 0.605012C0.691625 0.412321 0.81865 0.250792 0.979681 0.142938C1.14071 0.0350836 1.32773 -0.0137221 1.51466 0.00332441C1.66114 -0.00903026 1.80821 0.0157464 1.94499 0.0758184C2.08178 0.13589 2.20476 0.229719 2.30483 0.350351C2.40489 0.470984 2.47949 0.61533 2.52308 0.772695C2.56667 0.93006 2.57814 1.09641 2.55664 1.25942L2.55664 3.44567L1.96253 3.44567L1.96253 1.42679C1.96253 0.884072 1.75231 0.61417 1.31292 0.61417C1.21347 0.608974 1.11419 0.627962 1.0219 0.669835C0.929602 0.711707 0.846469 0.775474 0.778195 0.856763C0.70992 0.938051 0.65812 1.03494 0.626346 1.14079C0.594573 1.24664 0.583575 1.35894 0.594109 1.47L0.594109 3.44571L0 3.44571L5.10054e-06 0.068515Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.21%"
                          bottom="1.53%"
                          left="26.3%"
                          right="68.15%"
                          {...getOverrideProps(overrides, "Vector43681265")}
                        ></Icon>
                        <Icon
                          width="0.59px"
                          height="4.7px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 0.5941110849380493,
                            height: 4.695554733276367,
                          }}
                          paths={[
                            {
                              d: "M0 0L0.594111 0L0.594111 4.69555L0 4.69555L0 0Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="1.53%"
                          left="33.88%"
                          right="64.84%"
                          {...getOverrideProps(overrides, "Vector43681266")}
                        ></Icon>
                        <Icon
                          width="2.85px"
                          height="3.51px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.853053331375122,
                            height: 3.50760555267334,
                          }}
                          paths={[
                            {
                              d: "M0.00669887 1.75235C-0.0122124 1.53039 0.0104336 1.30646 0.0731844 1.09489C0.135935 0.883335 0.237407 0.688822 0.371089 0.523831C0.504772 0.35884 0.667717 0.227014 0.849472 0.136806C1.03123 0.0465982 1.22778 0 1.42653 0C1.62528 0 1.82183 0.0465982 2.00359 0.136806C2.18534 0.227014 2.34828 0.35884 2.48197 0.523831C2.61565 0.688822 2.71712 0.883335 2.77987 1.09489C2.84262 1.30646 2.86527 1.53039 2.84635 1.75235C2.86561 1.97452 2.84323 2.19876 2.78064 2.41065C2.71805 2.62255 2.61664 2.81741 2.48293 2.98271C2.34921 3.14802 2.18615 3.28011 2.00421 3.37051C1.82228 3.46091 1.62551 3.50761 1.42653 3.50761C1.22755 3.50761 1.03077 3.46091 0.848839 3.37051C0.666907 3.28011 0.503844 3.14802 0.370128 2.98271C0.236412 2.81741 0.135001 2.62255 0.0724117 2.41065C0.00982221 2.19876 -0.0125616 1.97452 0.00669887 1.75235ZM2.23494 1.75235C2.23494 1.02029 1.9418 0.592195 1.42734 0.592195C0.910924 0.592195 0.620419 1.02029 0.620419 1.75235C0.620419 2.49027 0.910944 2.91507 1.42734 2.91507C1.9418 2.91506 2.23494 2.48733 2.23494 1.75235Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.17%"
                          left="36.93%"
                          right="56.9%"
                          {...getOverrideProps(overrides, "Vector43681267")}
                        ></Icon>
                        <Icon
                          width="2.53px"
                          height="3.5px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.526604175567627,
                            height: 3.50349760055542,
                          }}
                          paths={[
                            {
                              d: "M0 2.4873C0 1.87939 0.403474 1.52893 1.11967 1.47912L1.9351 1.42639L1.9351 1.13489C1.9351 0.778193 1.72488 0.57678 1.3188 0.57678C0.987141 0.57678 0.757333 0.713378 0.691389 0.952148L0.116211 0.952148C0.176927 0.372068 0.663318 0 1.34622 0C2.10094 0 2.5266 0.421501 2.5266 1.13489L2.5266 3.44238L1.95469 3.44238L1.95469 2.96777L1.90768 2.96777C1.81227 3.13802 1.6783 3.2768 1.51944 3.36996C1.36058 3.46312 1.18252 3.50731 1.00346 3.49802C0.877084 3.51277 0.749355 3.49764 0.628509 3.45362C0.507663 3.4096 0.396383 3.33765 0.30184 3.24242C0.207297 3.1472 0.131593 3.0308 0.0796041 2.90074C0.0276154 2.77068 0.000497909 2.62984 0 2.4873ZM1.9351 2.19872L1.9351 1.91638L1.19997 1.96911C0.7854 2.00024 0.59737 2.15844 0.59737 2.45617C0.59737 2.76012 0.832401 2.937 1.1556 2.937C1.25029 2.94775 1.34595 2.93703 1.43687 2.90546C1.5278 2.87389 1.61213 2.82213 1.68486 2.75325C1.75759 2.68437 1.81722 2.59978 1.86021 2.50451C1.9032 2.40925 1.92867 2.30526 1.9351 2.19872Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.25%"
                          left="44.44%"
                          right="50.09%"
                          {...getOverrideProps(overrides, "Vector43681268")}
                        ></Icon>
                        <Icon
                          width="2.81px"
                          height="4.75px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.8112552165985107,
                            height: 4.752007961273193,
                          }}
                          paths={[
                            {
                              d: "M0 3.0055C0 1.93837 0.489003 1.26234 1.24959 1.26234C1.43773 1.25261 1.62445 1.30317 1.78792 1.40809C1.9514 1.51301 2.08487 1.66796 2.17275 1.85484L2.21715 1.85484L2.21715 0L2.81126 0L2.81126 4.69555L2.24196 4.69555L2.24196 4.16198L2.19495 4.16198C2.10028 4.3476 1.96215 4.50063 1.79552 4.60449C1.62888 4.70835 1.44009 4.75909 1.2496 4.75121C0.48378 4.75125 0 4.07521 0 3.0055ZM0.613718 3.0055C0.613718 3.72181 0.914695 4.15285 1.41806 4.15285C1.91879 4.15285 2.22826 3.7156 2.22826 3.00844C2.22826 2.30458 1.91554 1.86109 1.41806 1.86109C0.917938 1.86109 0.613718 2.29505 0.613718 3.0055Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="0.35%"
                          left="51.6%"
                          right="42.32%"
                          {...getOverrideProps(overrides, "Vector43681269")}
                        ></Icon>
                        <Icon
                          width="2.85px"
                          height="3.51px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.852759599685669,
                            height: 3.5075626373291016,
                          }}
                          paths={[
                            {
                              d: "M0.0067159 1.75228C-0.0122098 1.53034 0.0104196 1.30642 0.0731555 1.09487C0.135891 0.883315 0.237348 0.688809 0.371017 0.523822C0.504686 0.358835 0.667613 0.227011 0.849356 0.136804C1.0311 0.0465975 1.22764 0 1.42638 0C1.62512 1.33227e-15 1.82166 0.0465975 2.0034 0.136804C2.18514 0.227011 2.34807 0.358835 2.48174 0.523822C2.61541 0.688809 2.71686 0.883315 2.7796 1.09487C2.84234 1.30642 2.86497 1.53034 2.84604 1.75228C2.86533 1.97445 2.84297 2.19869 2.7804 2.41058C2.71783 2.62247 2.61643 2.81734 2.48273 2.98265C2.34902 3.14796 2.18597 3.28006 2.00405 3.37046C1.82212 3.46086 1.62535 3.50756 1.42638 3.50756C1.22741 3.50756 1.03063 3.46086 0.848708 3.37046C0.666783 3.28006 0.50373 3.14796 0.370027 2.98265C0.236325 2.81734 0.13493 2.62247 0.0723599 2.41058C0.00978991 2.19869 -0.012569 1.97445 0.0067159 1.75228ZM2.23495 1.75228C2.23495 1.02023 1.94181 0.592132 1.42736 0.592132C0.91094 0.592132 0.620433 1.02023 0.620433 1.75229C0.620433 2.49021 0.91096 2.91501 1.42736 2.91501C1.94182 2.915 2.23495 2.48727 2.23495 1.75228Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0.17%"
                          left="62.98%"
                          right="30.85%"
                          {...getOverrideProps(overrides, "Vector43681270")}
                        ></Icon>
                        <Icon
                          width="2.57px"
                          height="3.45px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.5671818256378174,
                            height: 3.4457123279571533,
                          }}
                          paths={[
                            {
                              d: "M0 0.068515L0.571914 0.068515L0.571914 0.605012L0.616314 0.605012C0.691628 0.412321 0.81865 0.250792 0.979681 0.142938C1.14071 0.0350836 1.32773 -0.0137221 1.51466 0.00332441C1.66114 -0.00903026 1.80821 0.0157464 1.94499 0.0758184C2.08178 0.13589 2.20476 0.229719 2.30483 0.350351C2.4049 0.470984 2.47949 0.61533 2.52308 0.772695C2.56667 0.93006 2.57814 1.09641 2.55664 1.25942L2.55664 3.44567L1.96253 3.44567L1.96253 1.42679C1.96253 0.884072 1.75231 0.61417 1.31293 0.61417C1.21348 0.608974 1.11419 0.627962 1.0219 0.669835C0.929602 0.711707 0.846469 0.775474 0.778195 0.856763C0.709921 0.938051 0.65812 1.03494 0.626347 1.14079C0.594573 1.24664 0.583578 1.35894 0.594111 1.47L0.594111 3.44571L0 3.44571L0 0.068515Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.21%"
                          bottom="1.53%"
                          left="70.86%"
                          right="23.58%"
                          {...getOverrideProps(overrides, "Vector43681271")}
                        ></Icon>
                        <Icon
                          width="1.72px"
                          height="4.24px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 1.7248858213424683,
                            height: 4.236689567565918,
                          }}
                          paths={[
                            {
                              d: "M1.07266 0L1.07266 0.8562L1.72489 0.8562L1.72489 1.4176L1.07266 1.4176L1.07266 3.15418C1.07266 3.50794 1.20259 3.66284 1.49834 3.66284C1.57405 3.66257 1.64969 3.65743 1.72489 3.64746L1.72489 4.20263C1.61819 4.22405 1.51009 4.23544 1.40171 4.23669C0.741008 4.23669 0.4779 3.97595 0.4779 3.32483L0.4779 1.41757L0 1.41757L0 0.85617L0.4779 0.85617L0.4779 0L1.07266 0Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="10.01%"
                          bottom="1.14%"
                          left="81.34%"
                          right="14.93%"
                          {...getOverrideProps(overrides, "Vector43681272")}
                        ></Icon>
                        <Icon
                          width="2.6px"
                          height="4.7px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.5993804931640625,
                            height: 4.695562362670898,
                          }}
                          paths={[
                            {
                              d: "M0 0L0.588888 0L0.588888 1.86109L0.63589 1.86109C0.714867 1.6666 0.845379 1.50415 1.00964 1.39589C1.17389 1.28763 1.36391 1.23882 1.55379 1.25611C1.69951 1.24721 1.84518 1.2745 1.98041 1.33604C2.11564 1.39757 2.2371 1.49182 2.33612 1.61207C2.43515 1.73233 2.5093 1.87562 2.55328 2.03172C2.59725 2.18782 2.60998 2.35288 2.59055 2.51514L2.59055 4.69556L1.99585 4.69556L1.99585 2.67956C1.99585 2.14013 1.77189 1.86694 1.35212 1.86694C1.25001 1.85754 1.1473 1.87329 1.05118 1.91308C0.955056 1.95287 0.867847 2.01574 0.795649 2.0973C0.723451 2.17885 0.668009 2.27711 0.633202 2.38522C0.598394 2.49332 0.585061 2.60865 0.594137 2.72314L0.594137 4.69555L2.55027e-05 4.69555L0 0Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="0%"
                          bottom="1.53%"
                          left="86.82%"
                          right="7.55%"
                          {...getOverrideProps(overrides, "Vector43681273")}
                        ></Icon>
                        <Icon
                          width="2.74px"
                          height="3.52px"
                          viewBox={{
                            minX: 0,
                            minY: 0,
                            width: 2.7437856197357178,
                            height: 3.5156145095825195,
                          }}
                          paths={[
                            {
                              d: "M2.70788 2.53057C2.62716 2.83923 2.45227 3.10621 2.21381 3.28483C1.97535 3.46344 1.6885 3.5423 1.40345 3.50762C1.20512 3.51349 1.00802 3.47088 0.825844 3.38273C0.643667 3.29458 0.480769 3.16302 0.348459 2.99717C0.216149 2.83131 0.117598 2.63514 0.059641 2.42228C0.0016845 2.20941 -0.0142845 1.98495 0.0128385 1.76446C-0.0135721 1.5433 0.00267812 1.31835 0.0604979 1.10484C0.118318 0.89134 0.216356 0.694264 0.34797 0.526959C0.479583 0.359654 0.641701 0.226025 0.82335 0.13512C1.005 0.0442141 1.20194 -0.00184611 1.40083 5.66375e-05C2.23846 5.66375e-05 2.74379 0.642056 2.74379 1.70256L2.74379 1.93512L0.618043 1.93512L0.618043 1.97247C0.60874 2.09641 0.622629 2.22116 0.658812 2.33872C0.694995 2.45628 0.752676 2.56406 0.82816 2.65515C0.903644 2.74625 0.995263 2.81864 1.09714 2.86769C1.19902 2.91673 1.30891 2.94135 1.41977 2.93997C1.56188 2.95911 1.70586 2.93041 1.83339 2.8575C1.96092 2.7846 2.06624 2.67079 2.13596 2.53055L2.70788 2.53057ZM0.618028 1.44219L2.13856 1.44219C2.14605 1.32885 2.13232 1.215 2.09825 1.10796C2.06418 1.00092 2.01054 0.903061 1.94075 0.820668C1.87097 0.738275 1.78659 0.673171 1.69304 0.62954C1.59949 0.585908 1.49884 0.564714 1.39755 0.567319C1.2948 0.565871 1.19283 0.587514 1.09765 0.630972C1.00247 0.674431 0.916007 0.738826 0.84336 0.820361C0.770712 0.901896 0.713351 0.998921 0.674654 1.10572C0.635957 1.21251 0.616692 1.32692 0.618028 1.44219Z",
                              fill: "rgba(255,255,255,1)",
                              fillRule: "nonzero",
                            },
                          ]}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          position="absolute"
                          top="26.28%"
                          bottom="0%"
                          left="94.06%"
                          right="0%"
                          {...getOverrideProps(overrides, "Vector43681274")}
                        ></Icon>
                      </View>
                    </View>
                  </View>
                </View>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="39.3px"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 55743681276")}
              >
                <Image
                  width="100.96px"
                  height="30px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={playstoresrc}
                  onClick={() => {
                    googleplaybadgeTwoFourThreeSixEightOneTwoSevenEightOnClick();
                  }}
                  {...getOverrideProps(
                    overrides,
                    "google-play-badge 243681278"
                  )}
                ></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="48px 10px 48px 10px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Why")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 432")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            color="rgba(157,40,226,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Benefits"
            {...getOverrideProps(overrides, "Benefits")}
          ></Text>
          <Text
            //fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="32px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Why run in a group?"
            {...getOverrideProps(overrides, "Why run in a group?")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 63")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 10px 0px 10px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 518")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 39042831055")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42942831056")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="32px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Physical Health."
                  {...getOverrideProps(overrides, "Physical Health.")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13842831058")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Regular running is one of the best ways to stay physically healthy."
                  {...getOverrideProps(
                    overrides,
                    "Regular running is one of the best ways to stay physically healthy."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13942831060")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Low levels of physical activity Studies have shown running a bit and often halves your heart attack risk."
                  {...getOverrideProps(
                    overrides,
                    "Low levels of physical activity Studies\u00A0have shown running a bit and often halves your heart attack risk."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 137")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="One of the biggest challenges is staying consistent which is where Rundezvous comes in."
                  {...getOverrideProps(
                    overrides,
                    "One of the biggest challenges is staying consistent which is where Rundezvous comes in."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 10px 0px 10px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 519")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 39042831065")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42942831066")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="32px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Mental Health."
                  {...getOverrideProps(overrides, "Mental Health.")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13842831068")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Running has innate mental health benefits, as does socialising with others."
                  {...getOverrideProps(
                    overrides,
                    "Running has innate mental health benefits, as does socialising with others."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13942831070")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rundezvous gives its users the opportunity to join these two activities together."
                  {...getOverrideProps(
                    overrides,
                    "Rundezvous gives its users the opportunity to join these two activities together."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 10px 0px 10px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 520")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 391")}
            >
              <Flex
                gap="15px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42942831074")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="32px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Make Connections."
                  {...getOverrideProps(overrides, "Make Connections.")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13842831076")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="In an ever increasingly online and virtual world, connections IRL (In Real Life) are more and more difficult to come by. "
                  {...getOverrideProps(
                    overrides,
                    "In an ever increasingly online and virtual world, connections IRL (In Real Life) are more and more difficult to come by."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13942831078")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rundezvous makes these connections for you and groups you with like-minded people who want to go for a casual run and maybe have a chat too."
                  {...getOverrideProps(
                    overrides,
                    "Rundezvous makes these connections for you and groups you with like-minded people who want to go for a casual run and maybe have a chat too."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="900px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "Action")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 20px 30px 20px"
          display="flex"
          {...getOverrideProps(overrides, "Right42831081")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={image3src}
            {...getOverrideProps(overrides, "image42831082")}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="120px 10px 120px 10px"
          display="flex"
          {...getOverrideProps(overrides, "Left42831083")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage42831084")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message42831085")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Ready to get started?"
                {...getOverrideProps(overrides, "Heading42831086")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Registration takes minutes and you could be on your first Rundezvous next week!&#xA;&#xA;"
                {...getOverrideProps(overrides, "Body42831087")}
              ></Text>
            </Flex>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Get started"
              onClick={() => {
                buttonFourTwoEightThreeOneZeroEightEightOnClick();
              }}
              {...getOverrideProps(overrides, "Button42831088")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Pricing")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="350px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 0px 30px 0px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Right42831095")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={image4src}
            {...getOverrideProps(overrides, "image42831096")}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left42831090")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 20px 0px 20px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage42831091")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "HeroMessage4493996")}
            >
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message4493997")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="24px"
                  fontWeight="600"
                  color="rgba(13,26,38,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={PricingTitle}
                  {...getOverrideProps(overrides, "Heading4493998")}
                ></Text>
                <Flex
                  gap="16px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Message4493999")}
                >
                  <Text
                    //fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(48,64,80,1)"
                    lineHeight="24px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.01px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={Pricing1}
                    {...getOverrideProps(overrides, "Body44931000")}
                  ></Text>
                </Flex>
                <Flex
                  gap="16px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Message44931001")}
                >
                  <Text
                    //fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(48,64,80,1)"
                    lineHeight="24px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.01px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={Pricing2}
                    {...getOverrideProps(overrides, "Body44931002")}
                  ></Text>
                </Flex>
                <Flex
                  gap="16px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Message44931003")}
                >
                  <Text
                    //fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(48,64,80,1)"
                    lineHeight="24px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.01px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={Pricing3}
                    {...getOverrideProps(overrides, "Body44931004")}
                  ></Text>
                </Flex>
                <Flex
                  gap="16px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Message44931005")}
                ></Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Beta")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="350px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 0px 30px 0px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Right44931007")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={betasrc}
            {...getOverrideProps(overrides, "image44931008")}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 0px 20px 0px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Left44931009")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 20px 0px 20px"
            display="flex"
            {...getOverrideProps(overrides, "HeroMessage44931010")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Message44931021")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Beta"
                {...getOverrideProps(overrides, "Heading44931022")}
              ></Text>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message44931023")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rundezvous is currently in Beta testing in the following countries:"
                  {...getOverrideProps(overrides, "Body44931024")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message44931025")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="United Kingdom, Belgium"
                  {...getOverrideProps(overrides, "Body44931026")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message44931027")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="If your country is not in the list, you can still join the waiting list and we will email you when we have enough people to launch in your country."
                  {...getOverrideProps(overrides, "Body44931028")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Message44931029")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(48,64,80,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Just create an account and get started and give us your details using the buttons above and we’ll take care of the rest!"
                  {...getOverrideProps(overrides, "Body44931030")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="40px 40px 40px 40px"
        backgroundColor="rgba(223,188,245,1)"
        display="flex"
        {...getOverrideProps(overrides, "Footer")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 313")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 434")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="471px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Rundezvous"
              {...getOverrideProps(overrides, "Rundezvous")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 405")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 403")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Navigation"
              {...getOverrideProps(overrides, "Navigation")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Home"
              onClick={() => {
                homeOnClick();
              }}
              {...getOverrideProps(overrides, "Home")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms"
              onClick={() => {
                termsFourTwoEightThreeOneOneZeroFiveOnClick();
              }}
              {...getOverrideProps(overrides, "Terms42831105")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Login"
              onClick={() => {
                termsFourTwoEightThreeOneOneZeroSixOnClick();
              }}
              {...getOverrideProps(overrides, "Terms42831106")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Support"
              onClick={() => {
                supportOnClick();
              }}
              {...getOverrideProps(overrides, "Support")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 433")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "LogoWithText")}
          >
            <Image
              width="197px"
              height="39px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={logosrc}
              {...getOverrideProps(
                overrides,
                "long+logo+overlapped+(1) 142831109"
              )}
            ></Image>
          </Flex>
          <Text
            //fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(102,112,133,1)"
            lineHeight="24px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="© 2024 Rundezvous Ltd. All rights reserved."
            {...getOverrideProps(
              overrides,
              "\u00A9 2024 Rundezvous Ltd. All rights reserved."
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
