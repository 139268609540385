/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { useState } from "react";
import {
  getOverrideProps,
  useAuthSignOutAction,
  useNavigateAction,
} from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function GenericNavbar(props) {
  const {
    logoURL,
    link1title,
    link1click,
    link2title,
    link2click,
    link3title,
    link3click,
    link4title,
    logoutURL,
    link4click,
    mouseOver1,
    link1color,
    mouseLeave1,
    hoverColor,
    backgroundHoverColor,
    titlestr,
    logosrc,
    logoutsrc,
    widthProp = "100%",
    innerWidthProp = "1200px",
    overrides,
    ...rest
  } = props;
  const [headOneColor, setHeadOneColor] = useState("rgba(48,64,80,1)");
  const [headTwoColor, setHeadTwoColor] = useState("rgba(48,64,80,1)");
  const [headThreeColor, setHeadThreeColor] = useState("rgba(48,64,80,1)");
  const [headFourColor, setHeadFourColor] = useState("rgba(48,64,80,1)");
  const longLogoColorOneOnClick = useNavigateAction({
    type: "url",
    url: link1click,
  });
  const headOneFrameOnClick = useNavigateAction({
    type: "url",
    url: link1click,
  });
  const headOneFrameOnMouseEnter = () => {
    setHeadOneColor(hoverColor);
  };
  const headOneFrameOnMouseLeave = () => {
    setHeadOneColor(link1color);
  };
  const headTwoFrameOnClick = useNavigateAction({
    type: "url",
    url: link2click,
  });
  const headTwoFrameOnMouseEnter = () => {
    setHeadTwoColor(hoverColor);
  };
  const headTwoFrameOnMouseLeave = () => {
    setHeadTwoColor(link1color);
  };
  const headThreeFrameOnClick = useNavigateAction({
    type: "url",
    url: link3click,
  });
  const headThreeFrameOnMouseEnter = () => {
    setHeadThreeColor(hoverColor);
  };
  const headThreeFrameOnMouseLeave = () => {
    setHeadThreeColor(link1color);
  };
  const headFourFrameOnClick = useNavigateAction({
    type: "url",
    url: link4click,
  });
  const headFourFrameOnMouseEnter = () => {
    setHeadFourColor(hoverColor);
  };
  const headFourFrameOnMouseLeave = () => {
    setHeadFourColor(link1color);
  };
  const frameFiveEightZeroOnClick = useAuthSignOutAction({ global: false });
  const logoutOnClick = useAuthSignOutAction({ global: false });
  return (
    <Flex
      gap="0"
      direction="column"
      width={widthProp}
      height="80px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(250,250,250,1)"
      {...getOverrideProps(overrides, "GenericNavbar")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width={widthProp}
        height="71px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.10000000149011612)"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "NavBar")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="1200px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 17px 0px 17px"
          maxWidth={innerWidthProp}
          {...getOverrideProps(overrides, "Frame 445")}
        >
          <Image
            width="242px"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={logosrc}
            onClick={() => {
              longLogoColorOneOnClick();
            }}
            {...getOverrideProps(overrides, "LongLogoColor 1")}
          ></Image>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(217,217,217,0)"
            onClick={() => {
              headOneFrameOnClick();
            }}
            onMouseEnter={() => {
              headOneFrameOnMouseEnter();
            }}
            onMouseLeave={() => {
              headOneFrameOnMouseLeave();
            }}
            {...getOverrideProps(overrides, "Head1Frame")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color={headOneColor}
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={link1title}
              {...getOverrideProps(overrides, "Head1")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            onClick={() => {
              headTwoFrameOnClick();
            }}
            onMouseEnter={() => {
              headTwoFrameOnMouseEnter();
            }}
            onMouseLeave={() => {
              headTwoFrameOnMouseLeave();
            }}
            {...getOverrideProps(overrides, "Head2Frame")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color={headTwoColor}
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={link2title}
              {...getOverrideProps(overrides, "Head2")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            onClick={() => {
              headThreeFrameOnClick();
            }}
            onMouseEnter={() => {
              headThreeFrameOnMouseEnter();
            }}
            onMouseLeave={() => {
              headThreeFrameOnMouseLeave();
            }}
            {...getOverrideProps(overrides, "Head3Frame")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color={headThreeColor}
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={link3title}
              {...getOverrideProps(overrides, "Head3")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            onClick={() => {
              headFourFrameOnClick();
            }}
            onMouseEnter={() => {
              headFourFrameOnMouseEnter();
            }}
            onMouseLeave={() => {
              headFourFrameOnMouseLeave();
            }}
            {...getOverrideProps(overrides, "Head4Frame")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color={headFourColor}
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={link4title}
              {...getOverrideProps(overrides, "Head4")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            onClick={() => {
              frameFiveEightZeroOnClick();
            }}
            {...getOverrideProps(overrides, "Frame 580")}
          >
            <Image
              width="30px"
              height="30px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={logoutsrc}
              onClick={() => {
                logoutOnClick();
              }}
              {...getOverrideProps(overrides, "logout")}
            ></Image>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
