/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Text, View } from "@aws-amplify/ui-react";
export default function Confirmed(props) {
  const {
    viewDetailsHandler,
    buttonLabel = "View Details",
    ConfirmedString = "Confirmed",
    overrides,
    ...rest
  } = props;
  return (
    <View
      width="300px"
      height="70px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Confirmed")}
      {...rest}
    >
      <View
        width="300px"
        height="70px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Confirmed40331214")}
      >
        <View
          width="300px"
          height="70px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Area")}
        ></View>
        <View
          width="150px"
          height="70px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 465")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="20px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="30px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="20px"
            left="25px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={ConfirmedString}
            {...getOverrideProps(overrides, "label")}
          ></Text>
        </View>
        <View
          width="150px"
          height="70px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="0px"
          left="150px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 466")}
        >
          <Button
            width="unset"
            height="unset"
            position="absolute"
            top="calc(50% - 16.5px - 0.5px)"
            left="calc(50% - 55px - 0.5px)"
            backgroundColor="rgba(125,26,183,1)"
            size="small"
            isDisabled={false}
            variation="primary"
            children={buttonLabel}
            onClick={viewDetailsHandler}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </View>
      </View>
    </View>
  );
}
