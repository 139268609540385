import AcceptReject from '../ui-components/AcceptReject.jsx';
import updateAttendanceAPI from '../utils/updateAttendanceAPI.js'
import { useState } from 'react';

const AcceptRejectWrapper = ({at}) => {  
    const [isChecked, setIsChecked] = useState(at.accepted);
    return (
            <div className="content">
                <AcceptReject key={Math.random()} overrides= {{
                                                        "SwitchField": {isChecked: isChecked}
                                                }}  onChange={(e) => {
                                        console.log(at.accepted)
                                        if(!at.accepted){
                                            setIsChecked(e.target.checked);
                                        } else {
                                            return;
                                        }
                                        var currentdate = new Date(); 
                                        var datetime = currentdate.getFullYear() + "-"
                                        + (currentdate.getMonth()+1) + "-"
                                        + currentdate.getDate() + " "
                                        + currentdate.getHours() + ":"
                                        + currentdate.getMinutes() + ":"
                                        + currentdate.getSeconds() + "."
                                        + currentdate.getMilliseconds();
                                        var toUpdateAttendance = {};
                                        toUpdateAttendance.last_updated = datetime;
                                        toUpdateAttendance.pk = at.pk;
                                        toUpdateAttendance.sk = at.sk;
                                        toUpdateAttendance.accepted = e.target.checked;
                                        toUpdateAttendance.checked_in = false;
                                        at.accepted = e.target.checked;
                                        console.log(toUpdateAttendance);
                                        updateAttendanceAPI(toUpdateAttendance);
                                        
                                    }}
                                    displayConfirmed={false}
                />
          </div>
  );
}
 
export default AcceptRejectWrapper;