import { get } from 'aws-amplify/api';

async function getMember(email) {
    var pk = 'member-' + email
    try{
        var result = get({  apiName: 'membereventsapi',
                            path: '/member/' + pk, 
                            options: {}});
        const { body } = await result.response;
        // console
        // if( body.)
        const json = await body.json();
        // console.log(json);
        if (json.exists) {
            console.log(json);
            return JSON.parse(json.body);
        }else{
            console.log('GET call failed: member did not exist');
            return 
        }
        // console.log('GET call succeeded: ', json.body);
        // return JSON.parse(json.body);

    } catch (e) {
        console.log('GET call failed: ', JSON.parse(e.response.body));
        return;
    }
}


export default getMember;