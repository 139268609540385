/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function ProgressBar(props) {
  const {
    onecomplete = true,
    oneactive = true,
    twocomplete = true,
    twoactive = true,
    threecomplete = true,
    threeactive = true,
    fourcomplete = true,
    fouractive = true,
    onetocome = true,
    twotocome = true,
    threetocome = true,
    fourtocome = true,
    onetext = "Details",
    twotext = "Confirm email",
    threetext = "Subscription",
    fourtext = "Availability",
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="1500px"
      height="120px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(250,250,250,1)"
      {...getOverrideProps(overrides, "ProgressBar")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="100px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.10000000149011612)"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "NavBar")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="1200px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 17px 0px 17px"
          {...getOverrideProps(overrides, "Frame 445")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(217,217,217,0)"
            {...getOverrideProps(overrides, "Head1Frame")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 602")}
            >
              <Icon
                width={onecomplete == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45281444")}
              ></Icon>
              <Icon
                width={onetocome == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311464")}
              ></Icon>
              <Icon
                width={oneactive == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311469")}
              ></Icon>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="31px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={onetext}
              {...getOverrideProps(overrides, "Details")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Head2Frame")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 603")}
            >
              <Icon
                width={twocomplete == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45301456")}
              ></Icon>
              <Icon
                width={twotocome == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311466")}
              ></Icon>
              <Icon
                width={twoactive == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311470")}
              ></Icon>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="31px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={twotext}
              {...getOverrideProps(overrides, "Confirm Email")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Head3Frame")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 604")}
            >
              <Icon
                width={threecomplete == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45301458")}
              ></Icon>
              <Icon
                width={threetocome == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311467")}
              ></Icon>
              <Icon
                width={threeactive == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311471")}
              ></Icon>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="31px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={threetext}
              {...getOverrideProps(overrides, "Subscription")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Head4Frame")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 605")}
            >
              <Icon
                width={fourcomplete == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36ZM29.18 11.16L16 24.34L10.82 19.18L8 22L16 30L32 14L29.18 11.16Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45301460")}
              ></Icon>
              <Icon
                width={fourtocome == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311468")}
              ></Icon>
              <Icon
                width={fouractive == true ? "40" : "0"}
                height="40px"
                viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
                paths={[
                  {
                    d: "M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20.5 13C11.68 13 20.5 28.32 20.5 19.5C11.68 19.5 20.5 10.68 20.5 19.5C20.5 10.68 29.32 19.5 20.5 19.5C20.5 28.32 29.32 13 20.5 13Z",
                    fill: "rgba(151,71,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                {...getOverrideProps(overrides, "Vector45311472")}
              ></Icon>
            </Flex>
            <Text
              //fontFamily="Inter"
              fontSize="18px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="31px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={fourtext}
              {...getOverrideProps(overrides, "Availability")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
