import { post} from 'aws-amplify/api';

// Intention of this file is to wrap the call to api logic so it can be used to grab:
// - members
// - events
// - attendances
//
// The "path" input will define what gets returned, though this function will interpret the path
// so it knows whether to return a list or a single item

const updateAttendanceAPI = (item) => {

    setTimeout(() => {
        // console.log(item)
        try {
            const result = post({apiName: 'membereventsapi', path: '/attendance', options: {body:item}});

        } catch {
            // console.log(err);
            console.log('Attendance - didnt update attendance')
        }
    }, 1000);

    return ;
}

export default updateAttendanceAPI;