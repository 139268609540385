/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, View } from "@aws-amplify/ui-react";
export default function FilterHolder(props) {
  const {
    toConfirmHandler,
    comingUpHandler,
    completedHandler,
    toConfirmBackground,
    comingUpBackground,
    completedBackground,
    toConfirmColor,
    comingUpColor,
    completedColor,
    overrides,
    ...rest
  } = props;
  return (
    <View
      width="200px"
      height="1000px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "FilterHolder")}
      {...rest}
    >
      <View
        width="200px"
        height="1000px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(250,250,250,1)"
        {...getOverrideProps(overrides, "Filter Holder")}
      >
        <View
          width="180px"
          height="261px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="24px"
          left="10px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15000000596046448)"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "InnerContent")}
        >
          <Button
            width="142px"
            height="unset"
            position="absolute"
            top="35px"
            left="19px"
            size="default"
            isDisabled={false}
            variation="default"
            backgroundColor={toConfirmBackground}
            color={toConfirmColor}
            children="To Confirm"
            onClick={toConfirmHandler}
            {...getOverrideProps(overrides, "Button40311193")}
          ></Button>
          <Button
            width="142px"
            height="unset"
            position="absolute"
            top="110px"
            left="19px"
            size="default"
            isDisabled={false}
            variation="default"
            backgroundColor={comingUpBackground}
            color={comingUpColor}
            children="Coming Up"
            onClick={comingUpHandler}
            {...getOverrideProps(overrides, "Button40311197")}
          ></Button>
          <Button
            width="142px"
            height="unset"
            position="absolute"
            top="185px"
            left="19px"
            size="default"
            isDisabled={false}
            variation="default"
            backgroundColor={completedBackground}
            color={completedColor}
            children="Completed"
            onClick={completedHandler}
            {...getOverrideProps(overrides, "Button40311201")}
          ></Button>
        </View>
      </View>
    </View>
  );
}
