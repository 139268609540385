import Navbar from '../components/Navbar.js';
import { Flex, Card, useTheme } from '@aws-amplify/ui-react';
import React from "react";
// import useMyFetch from '../utils/useMyFetch.js'
import getEvent from '../utils/getEvent.js'
import getMember from '../utils/getMember.js'
import getEventAttendance from '../utils/getEventAttendance.js'
import { useParams } from 'react-router';
import EventDetails from '../ui-components/EventDetails.jsx';
import { useState } from 'react';
import DesktopLayout from '../ui-components/DesktopLayout.jsx';
import MobileLayout from '../ui-components/MobileLayout.jsx';
import MyDetailsHeader from '../ui-components/MyDetailsHeader.jsx';
import MobileHeaderBar from '../ui-components/MobileHeaderBar.jsx';
import RDVLogo from '../specific/images/Logo.png';
import logo from '../specific/images/LongLogoColor.png';
import MenuIcon from '../resources/menu.png';
import MobileSideBar from '../ui-components/MobileSideBar.jsx';
import LogoutIcon from '../resources/logout.png';
import updateAttendanceAPI from '../utils/updateAttendanceAPI.js'
// import { Storage } from '@aws-amplify/storage';
import { uploadData, list } from 'aws-amplify/storage';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getCurrentUser } from 'aws-amplify/auth'; 
import { StorageImage } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';

function calcCrow(lat1, lon1, lat2, lon2) {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}

async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      return signInDetails.loginId;
    } catch (err) {
      console.log(err);
    }
  }


function EventDetailsScreen(state) {
    const { id } = useParams();
    const error = false;
    const isPending = false;
    const [event, setEvent] = useState(null);
    const [member, setMember] = useState(null);
    const [eventAttendance, setEventAttendance] = useState({});
    const [menu, setMenu] = useState(false);
    const { tokens } = useTheme();
    const [file, setFile] = useState(null);
    const hiddenFileInput = React.useRef(null);
    const [images, setImages]= useState(null);
    const [memberImage, setMemberImage] = useState({});

    React.useEffect(() => {
        (async function () {
            const tempEmail = await currentAuthenticatedUser();
            // console.log("tempemail: "+tempEmail);
            var tempMember = await getMember(tempEmail);
            setMember(await tempMember);
            var tempEvent = await getEvent(id);
            // console.log("Event received");
            // console.log(tempEvent);
            // console.log("attendance: ")
            // console.log(await tempAttendance);
            setEvent(tempEvent);
            // console.log(member.member_name);
            var tempEventAttendance = await getEventAttendance(tempEmail, id);
            setEventAttendance(tempEventAttendance);
            const date = new Date(tempEvent.date_time.slice(0, 10) + " "+tempEvent.date_time.slice(11, 16) );
            console.log("events/"+date.getFullYear()+"/"+(date.getMonth()+1)+"/"+(date.getDate()+1)+"/"+tempEvent.pk);
            const imsResult = await list({prefix:"events/"+date.getFullYear()+"/"+(date.getMonth()+1)+"/"+(date.getDate())+"/"+tempEvent.pk, options:{level: 'public'}});
            console.log(imsResult)
            const tempIms = [];
            if(imsResult.items.length>0){
                for (let i = 0; i < imsResult.items.length; i++) {
                    const arr = imsResult.items[i].key.split('.')
                    if (arr[arr.length-1] === "png" || arr[arr.length-1] === "jpg"){
                        tempIms.push(imsResult.items[i].key);
                        console.log("adding: "+imsResult.items[i].key)
                    }
                }
                console.log(tempIms);
                setImages(tempIms);
            }
            const path = "members/"+tempMember.country+"/"+tempMember.pk_hash+".png";
            const imsResult2 = await list({prefix:path, options:{level: 'public'}});
            // console.log(imsResult);
            if(imsResult2.items.length>0) {
                setMemberImage(imsResult2.items[0].key);
                // console.log("Set the image")
            }
            
        })();
        // list("events/2024/3/20/event-15994181569631032059/", {level: 'public'}) // for listing ALL files without prefix, pass '' instead '/public/events/2024/3/20/event-15994181569631032059/'
        // .then(({ results }) => {
        //     var ims = []
        //     for (let i=0; i<results.length; i++){
        //         addImage(results[i].key)
        //         // var url = Storage.get(results[i].key);
        //         // console.log(url);
        //         // ims.push(url);
        //     }
        //     // setImages(ims);
        //     console.log(results);
        // })
        // .catch((err) => console.log(err));
    }, [])

    // async function addImage(key) {
    //     var url = getUrl(key);
    //     console.log("url is: "+url);
    //     var ims = images;
    //     ims.push(url);
    //     setImages(ims);
    // }

    const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
        }
      };

    const performUpload = async () => {
        // const file = file;
        try {
            console.log(file.name);
            const result = uploadData({key:event.pk+"-"+file.name, data: file, options: {accessLevel: 'private'}});
            console.log("Uploaded!", result);
            } catch (error) {
            console.log('Error : ', error);
          }
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
      };
    
    return (
    <div className="EventDetails">
        <div className="content">
        
          <Flex backgroundColor={tokens.colors.background.primary} >
            {(state.state.state==="desktop" ?
            (<DesktopLayout
                // display="block"
                // margin="auto"
                height = "1500px"
                width="100%"
                navBar=<Navbar width={"100%"}/>
                mainContent=<div>    
                                {/* <div className="Uploader"> */}
            
                                    {/* <p> Pick a file</p> */}
                                    {/* <input type="file" ref={this.fileInput} /> */}
                                    {/* <S3Text level="private" path="" /> */}
                                    {/* <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {performUpload()}}
                                    >
                                    Upload
                                    </Button> */}
                                    {/* <input id="file" type="file" onChange={handleFileChange} />
                                    {file && (
                                        <section>
                                        File details:
                                        <ul>
                                            <li>Name: {file.name}</li>
                                            <li>Type: {file.type}</li>
                                            <li>Size: {file.size} bytes</li>
                                        </ul>
                                        </section>
                                    )}  */}
                                    {/* <br /> <br /> */}

                                {/* </div>                 */}
                    {isPending && <Card>Loading...</Card>}
                    {error && <Card>{error}</Card>}
                    {event && eventAttendance && <EventDetails 
                        width="100%" 
                        height="1000px"
                        date={event? (new Date(event.date_time.slice(0, 10))).toLocaleDateString() : ""}
                        time={event ? event.date_time.slice(11, 16) : ""}
                        pace= {event ? (Math.round(event.pace * 10) / 10) + "kph" : ""}
                        distanceFromHome={(Math.round(calcCrow(member.lat, member.lng, event.lat, event.lng)*10)/10) + " km"}
                        attendees= { event.invitees }
                        address = {event.address}
                        latlng = {event.lat+", "+event.lng}
                        status = {event.event_status}
                        attendanceStatus = {eventAttendance.accepted ? "Accepted" : "Not Accepted"}
                        confirmDisplay={eventAttendance.accepted ? "none" : ""}
                        checkInDisplay={eventAttendance.accepted ? "" : "none"}
                        cancelDisplay={eventAttendance.accepted ? "" : "none"}
                        uploadDisplay={eventAttendance.accepted ? "" : "none"}
                        confirmHandler={ () => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds();
                            var toUpdateAttendance = {};
                            toUpdateAttendance.last_updated = datetime;
                            toUpdateAttendance.pk = eventAttendance.pk;
                            toUpdateAttendance.sk = eventAttendance.sk;
                            toUpdateAttendance.accepted = true;
                            toUpdateAttendance.checked_in = false;
                            // eventAttendance.accepted = true;
                            // setEvent
                            console.log(toUpdateAttendance);
                            updateAttendanceAPI(toUpdateAttendance);
                            setEventAttendance(toUpdateAttendance);
                            // window.location.reload();
                        }}
                        cancelHandler={ () => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds();
                            var toUpdateAttendance = {};
                            toUpdateAttendance.last_updated = datetime;
                            toUpdateAttendance.pk = eventAttendance.pk;
                            toUpdateAttendance.sk = eventAttendance.sk;
                            toUpdateAttendance.accepted = false;
                            toUpdateAttendance.checked_in = false;
                            eventAttendance.accepted = false;
                            console.log(toUpdateAttendance);
                            updateAttendanceAPI(toUpdateAttendance);
                            setEventAttendance(toUpdateAttendance);
                        }}
                        checkinHandler={ () => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds();
                            var toUpdateAttendance = {};
                            toUpdateAttendance.last_updated = datetime;
                            toUpdateAttendance.pk = eventAttendance.pk;
                            toUpdateAttendance.sk = eventAttendance.sk;
                            toUpdateAttendance.accepted = eventAttendance.accepted;
                            toUpdateAttendance.checked_in = true;
                            console.log(toUpdateAttendance);
                            updateAttendanceAPI(toUpdateAttendance);
                            setEventAttendance(toUpdateAttendance);
                        }}
                        uploadHandler={() => {performUpload()}}
                        uploadSlot={(
                            <div>
                            <input id="file" type="file" ref={hiddenFileInput} accept="image/*" onChange={handleFileChange} style={{display: 'none'}} />
                            <div>{file ?<button enabled="False" className="button-upload" onClick={handleClick}>{file.name}</button>  : <button enabled="False" className="button-upload" onClick={handleClick}>Choose file</button>}</div>
                                </div>
                                )}
                        imagesSlot={(
                            <div>
                            {images ? 
                                <Carousel infiniteLoop={true} emulateTouch={true} autoPlay={true} interval={5000} showThumbs={false} showArrows={true} onChange={() => {}} onClickItem={() => {}} onClickThumb={() => {}}>
                                    
                                 {images && 
                                    images.map(image => ( 
                                        <div key={image}>
                                        <StorageImage alt="Event Image" imgKey={image} accessLevel="guest" />
                                        </div>
                                    ))
                                    }
                                </Carousel>
                                :
                                ""
                            }
                            </div>
                        )}
                            
                        // imageSrc= {"https://rundezvous-event-pictures.s3.eu-west-2.amazonaws.com/events/"+
                        //     Number(event.date_time.slice(0, 4))+"/"+
                        //     Number(event.date_time.slice(5, 7))+"/"+
                        //     Number(event.date_time.slice(8, 10))+"/"+
                        //     event.pk+".png" }
                        // overrides={{

                        // }}
                        /> }
                </div>
                leftBar={event && member && <MyDetailsHeader imageFrame={<StorageImage alt="Member" imgKey={memberImage} accessLevel="guest" />} 
                    name={member.member_name}
                    email={member.email}
                    status={member.full_member ? "Full Member" : "Unsubscribed"}
                    // date={event ? (new Date(event.date_time.slice(0, 10))).toLocaleDateString()  : ""}
                    // time={event ? event.date_time.slice(11, 16) : ""}
                    pace={event ? (Math.round(event.pace * 10) / 10) + "kph" : ""}
                    stat1title="Scheduled"
                    stat2title="Confirm"
                    stat3title="Completed"
                    stat1={member.upcoming_events}
                    stat2={member.confirmation_events}
                    stat3={member.completed_events} />} />)
            :
            <MobileLayout 
                width="100%"
                mobileHeader=<MobileHeaderBar 
                    width={"100%"}
                    logoURL={logo}
                    burgerURL={MenuIcon}
                    navigateHomeURL={"/HomePage"}
                    burgerHandler={() => {
                        setMenu(!menu);
                        // console.log("burger!")
                    }} />
                firstContent=<Flex width="100%">
                    {isPending && <Card>Loading...</Card>}
                    {error && <Card>{error}</Card>}
                    {event && !menu && <EventDetails 
                        width="100%" 
                        height="1000px"
                        date={event? (new Date(event.date_time.slice(0, 10))).toLocaleDateString() : ""}
                        time={event ? event.date_time.slice(11, 16) : ""}
                        pace= {event ? (Math.round(event.pace * 10) / 10) + "kph" : ""}
                        distanceFromHome={(Math.round(calcCrow(member.lat, member.lng, event.lat, event.lng)*10)/10) + " km"}
                        attendees= { event.invitees }
                        address = {event.address}
                        latlng = {event.lat+", "+event.lng}
                        status = {event.event_status}
                        attendanceStatus = {eventAttendance.accepted ? "Accepted" : "Not Accepted"}
                        confirmDisplay={eventAttendance.accepted ? "none" : ""}
                        checkInDisplay={eventAttendance.accepted ? "" : "none"}
                        cancelDisplay={eventAttendance.accepted ? "" : "none"}
                        uploadDisplay={eventAttendance.accepted ? "" : "none"}
                        confirmHandler={ () => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds();
                            var toUpdateAttendance = {};
                            toUpdateAttendance.last_updated = datetime;
                            toUpdateAttendance.pk = eventAttendance.pk;
                            toUpdateAttendance.sk = eventAttendance.sk;
                            toUpdateAttendance.accepted = true;
                            toUpdateAttendance.checked_in = false;
                            // eventAttendance.accepted = true;
                            // setEvent
                            console.log(toUpdateAttendance);
                            updateAttendanceAPI(toUpdateAttendance);
                            setEventAttendance(toUpdateAttendance);
                            // window.location.reload();
                        }}
                        cancelHandler={ () => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds();
                            var toUpdateAttendance = {};
                            toUpdateAttendance.last_updated = datetime;
                            toUpdateAttendance.pk = eventAttendance.pk;
                            toUpdateAttendance.sk = eventAttendance.sk;
                            toUpdateAttendance.accepted = false;
                            toUpdateAttendance.checked_in = false;
                            eventAttendance.accepted = false;
                            console.log(toUpdateAttendance);
                            updateAttendanceAPI(toUpdateAttendance);
                            setEventAttendance(toUpdateAttendance);
                        }}
                        checkinHandler={ () => {
                            var currentdate = new Date(); 
                            var datetime = currentdate.getFullYear() + "-"
                            + (currentdate.getMonth()+1) + "-"
                            + currentdate.getDate() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes() + ":"
                            + currentdate.getSeconds() + "."
                            + currentdate.getMilliseconds();
                            var toUpdateAttendance = {};
                            toUpdateAttendance.last_updated = datetime;
                            toUpdateAttendance.pk = eventAttendance.pk;
                            toUpdateAttendance.sk = eventAttendance.sk;
                            toUpdateAttendance.accepted = eventAttendance.accepted;
                            toUpdateAttendance.checked_in = true;
                            console.log(toUpdateAttendance);
                            updateAttendanceAPI(toUpdateAttendance);
                            setEventAttendance(toUpdateAttendance);
                        }}
                        uploadHandler={() => {performUpload()}}
                        uploadSlot={(
                            <div>
                            <input id="file" type="file" ref={hiddenFileInput} accept="image/*" onChange={handleFileChange} style={{display: 'none'}} />
                            <div>{file ?<button enabled="False" className="button-upload" onClick={handleClick}>{file.name}</button>  : <button enabled="False" className="button-upload" onClick={handleClick}>Choose file</button>}</div>
                                </div>
                                )}
                        imagesSlot={(
                            <div>
                            {images ? 
                                <Carousel infiniteLoop={true} emulateTouch={true} autoPlay={true} interval={5000} showThumbs={false} showArrows={true} onChange={() => {}} onClickItem={() => {}} onClickThumb={() => {}}>
                                    
                                 {images && 
                                    images.map(image => ( 
                                        <div key={image}>
                                        <StorageImage alt="Event Image" imgKey={image} accessLevel="guest" />
                                        </div>
                                    ))
                                    }
                                </Carousel>
                                :
                                ""
                            }
                            </div>
                        )}
                            
                        // imageSrc= {"https://rundezvous-event-pictures.s3.eu-west-2.amazonaws.com/events/"+
                        //     Number(event.date_time.slice(0, 4))+"/"+
                        //     Number(event.date_time.slice(5, 7))+"/"+
                        //     Number(event.date_time.slice(8, 10))+"/"+
                        //     event.pk+".png" }
                        // overrides={{

                        // }}
                        />}
                    {event && member && menu && <MobileSideBar
                        name={member.member_name}
                        logoURL={RDVLogo}
                        logoutURL={LogoutIcon}
                        runListURL={"/RunList"}
                        myDetailsURL={"/MyDetails"}
                        scheduleURL={"/Schedule"}
                        subscriptionURL={"/Subscribe"}
                        homeURL={"/HomePage"}
                    />
                    }
                </Flex>
            />)}
            </Flex>

        </div>
    </div>
  );
}

export default EventDetailsScreen;