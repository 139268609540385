import { Flex, useTheme, SelectField, Button, Text } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';
import getSchedule from '../utils/getSchedule.js';
import getMember from '../utils/getMember.js';
import QuickScheduleItem from '../ui-components/QuickScheduleItem.jsx';
import { useState, useEffect } from 'react';
import updateSchedule from '../utils/updateSchedule.js'
import { useNavigate } from 'react-router';


async function currentAuthenticatedUser() {
    try {
        const { username, userId, signInDetails } = await getCurrentUser();
        return signInDetails.loginId;
    } catch (err) {
        console.log(err);
    }
}

function createScheduleArray(str) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const bhs = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
        "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"];
    const ehs = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
        "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"];
    var retArr = [];
    str = str.slice(1, -1).replaceAll(" ", "");
    var active;
    var carry;
    var overIndex = 0;
    for (var i = 0; i < 7; i++) {
        active = false;
        carry = 0;
        for (var j = 0; j < 14; j++) {
            if (str.charAt((i * 14) + j) === "0") {
                if (active) {
                    active = false;
                    retArr.push({
                        // day: days[i],
                        // bh: bhs[j - carry],
                        // eh: ehs[j - 1],
                        bhindex: j - carry,
                        ehindex: j - 1,
                        dayindex: i,
                        index: overIndex
                    })
                    overIndex++;
                    carry = 0;
                } else {
                    active = false;
                }
            } else {
                if (active) {
                    carry++;
                } else {
                    active = true;
                    carry++;
                }
            }
        }
        if (active) {
            retArr.push({
                dayindex: i,
                bhindex: 14 - carry,
                ehindex: 13,
                index: overIndex
            })
            overIndex++;
        }
    }
    return retArr;
}

function convertBackSchedule(arr) {
    var str = "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
    var strArr = str.split("");
    for (var i = 0; i < arr.length; i++) {
        var dayIndex = arr[i].dayindex; //days.indexOf(arr[i].day);
        var bhIndex = arr[i].bhindex; //bhs.indexOf(arr[i].bh);
        var ehIndex = arr[i].ehindex; //ehs.indexOf(arr[i].eh);
        for (var j = bhIndex; j <= ehIndex; j++) {
            strArr[(dayIndex * 14) + j] = "1";
        }
    }
    str = "[ " + strArr.join(" ") + " ]";
    return str;
}

function QuickSchedule(props) {
    const days = ["Mon", "Tues", "Weds", "Thur", "Fri", "Sat", "Sun"];
    const bhs = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
        "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"];
    const ehs = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
        "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"];
    const { tokens } = useTheme();
    const [schedule, setSchedule] = useState([]);
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);

    const afterWork = async () => {
        console.log("Adding weekday evenings");
        setSchedule([...schedule,
            {
                dayindex: 0,
                bhindex: 11,
                ehindex: 13,
                index: schedule.length
            }, 
            {
                dayindex: 1,
                bhindex: 11,
                ehindex: 13,
                index: schedule.length+1
            }, 
            {
                dayindex: 2,
                bhindex: 11,
                ehindex: 13,
                index: schedule.length+2
            }, 
            {
                dayindex: 3,
                bhindex: 11,
                ehindex: 13,
                index: schedule.length+3
            }, 
            {
                dayindex: 4,
                bhindex: 11,
                ehindex: 13,
                index: schedule.length+4
            }
        ]);
        
    }
    const lunchBreaks = () =>{
        console.log("Adding lunchbreaks");
        setSchedule([...schedule,
            {
                dayindex: 0,
                bhindex: 6,
                ehindex: 7,
                index: schedule.length
            }, 
            {
                dayindex: 1,
                bhindex: 6,
                ehindex: 7,
                index: schedule.length+1
            }, 
            {
                dayindex: 2,
                bhindex: 6,
                ehindex: 7,
                index: schedule.length+2
            }, 
            {
                dayindex: 3,
                bhindex: 6,
                ehindex: 7,
                index: schedule.length+3
            }, 
            {
                dayindex: 4,
                bhindex: 6,
                ehindex: 7,
                index: schedule.length+4
            }
        ]);
    }
    const weekends = () => {
        console.log("Adding lunchbreaks");
        setSchedule([...schedule,
            {
                dayindex: 5,
                bhindex: 0,
                ehindex: 13,
                index: schedule.length
            }, 
            {
                dayindex: 6,
                bhindex: 0,
                ehindex: 13,
                index: schedule.length+1
            }
        ]);
    }

    const beforeWork = () => {
        console.log("Adding lunchbreaks");
        setSchedule([...schedule,
            {
                dayindex: 0,
                bhindex: 0,
                ehindex: 2,
                index: schedule.length
            }, 
            {
                dayindex: 1,
                bhindex: 0,
                ehindex: 2,
                index: schedule.length+1
            }, 
            {
                dayindex: 2,
                bhindex: 0,
                ehindex: 2,
                index: schedule.length+2
            }, 
            {
                dayindex: 3,
                bhindex: 0,
                ehindex: 2,
                index: schedule.length+3
            }, 
            {
                dayindex: 4,
                bhindex: 0,
                ehindex: 2,
                index: schedule.length+4
            }
        ]);
    }

    const update = (index, type, value) => {
        
        console.log(index + " - " + type + " - " + value);
        console.log(schedule[index]);
        if (type === "day") {
            var tempSched = schedule;
            console.log("tempSched:" +tempSched);
            console.log(tempSched);
            tempSched[index]['dayindex'] = value; 
            console.log(tempSched);
            setSchedule(tempSched);
        } else if (type === "start") {
            if(value>schedule[index]['ehindex']){
                schedule[index]['bhindex'] = value;
                schedule[index]['ehindex'] = value;
                // return;
            }else{
                schedule[index]['bhindex'] = value;   
            }
            
        } else if (type === "end") {
            if(value<schedule[index]['bhindex']){
                schedule[index]['ehindex'] = value;
                schedule[index]['bhindex'] = value;
                // return;
            }else{
                schedule[index]['ehindex'] = value;
            }
        } else {
            console.log("Something went wrong...");
        }
        console.log(convertBackSchedule(schedule));
        var item = {};
        var newStr = convertBackSchedule(schedule);
        item.email = email;
        item.schedule = newStr;
        // setScheduleString(newStr);
        setSchedule(createScheduleArray(newStr));
        // updateSchedule(item);
    }


    useEffect(() => {
        (async function () {
            const tempEmail = await currentAuthenticatedUser();
            // console.log("tempemail: "+tempEmail);
            var tempMember = await getMember(tempEmail);
            if (tempMember !== undefined) {
                var sched = await getSchedule(tempEmail);
                setSchedule(sched);
                console.log(sched);
                setEmail(tempMember.email);
            } else {
                navigate('/NewMember');
            }
        })();
    }, []);

    useEffect(() => {
        console.log("In the effect!");
        console.log(schedule);
        var item = {};
        var newStr = convertBackSchedule(schedule);
        console.log(newStr);
        item.email = email;
        item.schedule = newStr;
        // setScheduleString(newStr);
        // setSchedule(createScheduleArray(newStr));
        updateSchedule(item);
    }, [schedule]);

    return (
        <Flex padding={"10px"} width={"95%"} backgroundColor={tokens.colors.background.primary} direction={"column"}>
            <Flex width={"100%"} alignItems={"center"}>
                <Button width={"100%"} onClick={() => afterWork()}>After work</Button>
                <Button width={"100%"} onClick={() => lunchBreaks()}>Lunch breaks</Button>
                <Button width={"100%"} onClick={() => beforeWork()}>Before work</Button>
                <Button width={"100%"} onClick={() => weekends()}>Weekends</Button>
            </Flex>
            {schedule &&
                schedule.map(a =>
                    <>
                    <QuickScheduleItem
                        width="100%"
                        key={"index:"+a.index}
                        DaySlot={<SelectField
                            width={"100%"}
                            value={a['dayindex']}
                            onChange={(e) => {
                                let { value } = e.target;
                                console.log(value);
                                console.log(typeof Number(value));

                                update(a['index'], "day", Number(value));
                            }}
                        >
                            {days.map(n => <option index={"index:"+n} children={n} value={days.indexOf(n)} />)}
                        </SelectField>}
                        StartSlot={<SelectField
                            width={"100%"}
                            value={a['bhindex']}
                            onChange={(e) => {
                                let { value } = e.target;
                                console.log("updating value: "+value);
                                console.log(typeof Number(value));
                                update(a['index'], "start", Number(value));
                            }}
                        >
                            {bhs.map(n => <option index={"index:"+n} children={n} value={bhs.indexOf(n)} />)}
                        </SelectField>}
                        EndSlot={<SelectField
                            width={"100%"}
                            value={a['ehindex']}
                            onChange={(e) => {
                                let { value } = e.target;
                                console.log("updating value: "+value);
                                console.log(typeof Number(value));
                                update(a['index'], "end", Number(value));
                            }}
                        >
                            {ehs.map(n => <option index={"index:"+n} children={n} value={ehs.indexOf(n)} />)}
                        </SelectField>}
                    /></>)
                    
                     

            }
            <Flex width={"100%"} >
                <Button width={"100%"} onClick={()=>{
                    console.log(schedule);
                    setSchedule([...schedule,{
                        "dayindex": 0,
                        "bhindex": 0,
                        "ehindex": 0,
                        "index": schedule.length
                    }]);
                    console.log(schedule);
                }}>Add new slot</Button>
                <Button width={"100%"} onClick={()=>{
                    console.log(schedule);
                    setSchedule([]);
                    console.log(schedule);
                }}>Reset</Button>
            </Flex>
            <Flex width={"100%"}>
                <Button onClick={() => props.complete()} width={"100%"} isDisabled={schedule.length==0}>Complete sign-up</Button>
            </Flex>
        </Flex>
    );
}

export default QuickSchedule;