import { get } from 'aws-amplify/api';

async function getAttendance(email) {
    try{
        var pk = 'member-' + email;
        var result = get({apiName: 'membereventsapi', path: '/attendance/' + pk, options: {}})
        const { body } = await result.response;
        const json = await body.json();
        const parsed = JSON.parse(json.body);
        if(parsed.Count > 0){
            return parsed.Items;
        }else{
            console.log("Got no attendances.")
            return [];
        }
    }catch (e) {
        console.log('GET attendance call failed: ', JSON.parse(e.response.body));
        return;
    }
    
    return await result.response;
}


export default getAttendance;