/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function MobileSupport(props) {
  const {
    homeurl,
    termsurl,
    loginurl,
    supporturl,
    logosrc,
    whitelogosrc,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        "long+logo+overlapped+(1) 143521387": {},
        "Frame 517": {},
        Button: {},
        "Frame 321": {},
        Header: {},
        Support43521400: {},
        "Frame 43243521399": {},
        "Please consult our FAQs to see if your question is answered here. If you require further assistance, please email support@rundezvous.io .":
          {},
        "Frame 13843521406": {},
        "Frame 39043521403": {},
        "Frame 518": {},
        "Frame 6343521401": {},
        Guidelines: {},
        FAQ: {},
        "Frame 43243521415": {},
        "Frame 13843521544": {},
        "What does Rundezvous do?": {},
        "Rundezvous is a running event scheduling service. You can add your approximate location, your availability and running preferences to the app, it then uses advanced algorithms to create social running events of 3+ people at a location and time that is convenient for you. The more users on the app, the closer the starting point of the run will be to you.":
          {},
        "Frame 538": {},
        "How will I know when a run is happening?": {},
        "Every Wednesday afternoon our algorithm creates new runs for you for the following week (Monday-Sunday). You will receive event invitations by email and they will also appear in the app for you to accept or decline. You will receive final confirmation of the run 48 hours in advance of if enough people have accepted.":
          {},
        "Frame 531": {},
        "What do you do with my data?": {},
        "Your data is held securely encrypted in our database. All our users are welcome to use \u201Cusernames\u201D rather than full names as well as rough locations rather than home addresses if they prefer. This is an app made by runners for runners and we don\u2019t want to sell your data - ever. We will never disclose your information to anyone outside our service. Other users on the app can never view your information. You can delete your account at any time and your data will be completely removed from all our systems. A complete privacy policy is available at www.rundezvous.io/terms.":
          {},
        "Frame 532": {},
        "If I can\u2019t make a run anymore, what should I do?": {},
        "You can always cancel your attendance at a run, but please try to do this as far in advance as possible! We know that sometimes things come up, so we try to create larger runs, so any one person not making it doesn\u2019t stop the run going ahead.":
          {},
        "Frame 533": {},
        "Why do you need my location?": {},
        "Rundezvous is a service that tries to make events as convenient as possible for its users. To schedule runs close to where you live, we need a location for you. However please do feel free to provide a \u201Crough\u201D location instead of your exact location.":
          {},
        "Frame 534": {},
        "Why do I need a subscription?": {},
        "The goal of Rundezvous is to make social running as convenient and easy as possible to support people\u2019s physical and mental health by connecting local runners. We want to support as many people as possible to access our app. However, hosting the service and running the scheduling algorithms have a cost that we need to cover to be able to continue providing the service. We\u2019ve set the subscription cost as low as possible. We are an inclusive platform and wouldn\u2019t want anyone to be priced out. If you have personal circumstances which makes the subscription unaffordable for you but you think you\u2019d personally benefit from access, please send us an email at uk.rundezvous@gmail.com .":
          {},
        "Frame 535": {},
        "Where can I subscribe?": {},
        "You can subscribe to Rundezvous on our web application (login at the top of this page), or via the mobile applications on either Google Play Store or Apple App Store. Please be aware that \u00A0prices may differ slightly depending on the subscription method used, this is because different charges are applied by Google, Apple or Stripe. After you have subscribed, you can access your account on the web-app or Android/iPhone devices without restrictions regardless of the subscription platform.":
          {},
        "Frame 536": {},
        "How do I input my schedule?": {},
        "Both the web application and the Android/Apple apps allow you to enter your availability to join social runs by accessing the \u201CSchedule\u201D section. You can add as many slots as you like, bear in mind that you\u2019re inputting the slots during which you\u2019d be free to go for a run, you need to build in any time for getting changed, showering etc. before/after.":
          {},
        "Frame 537": {},
        "What is your referral scheme?": {},
        "The Rundezvous experience gets better the more people in your local area are using it, so we want to reward you for spreading the word. You will receive a free month of membership for every person who signs up using your unique code (found under \u201CReferrals\u201D). If you are a web application subscription user through stripe, this will be automatically applied. If you are an app subscription user you will receive a referral token which you can redeem to get your free month.":
          {},
        "Frame 526": {},
        "My referral didn\u2019t work?": {},
        "Your free month is only applied once the person you refer takes out a subscription. We want people to be able to try before they buy, so everyone starts on a free-trial period until scheduling is successful in their area.":
          {},
        "Frame 527": {},
        "Can I refer people before subscribing?": {},
        "Yes you can, though only as mobile app user and you won\u2019t be able to redeem your referral tokens until you have an active subscription.":
          {},
        "Frame 528": {},
        "Is there a run leader for every event?": {},
        "Not currently, at the moment every event is community led. When you meet you will be in your home location in an area you are very familiar with. It will be up to you to agree with the other attendees a good route! If there are any Strava users, you could make use of their routes feature to choose a good route. Please be careful to choose appropriate routes and only run places/routes you would be happy running on your own.":
          {},
        "Frame 529": {},
        "What is the free-trial period?": {},
        "This is an initial period after you create an account where you have full access to Rundezvous scheduling. It is initially one month long, but is extended until our system has enough matches. The time remaining in your trial period is displayed on the home screen and you will receive emails confirming any extensions or expiries.":
          {},
        "Frame 530": {},
        "Frame 39043521543": {},
        "Frame 6343521417": {},
        Terms43521414: {},
        Rundezvous: {},
        "Frame 434": {},
        "Frame 313": {},
        Navigation: {},
        Home: {},
        Terms43521534: {},
        Terms43521535: {},
        Support43521589: {},
        "Frame 403": {},
        "Frame 405": {},
        "long+logo+overlapped+(1) 143521538": {},
        LogoWithText: {},
        "\u00A9 2024 Rundezvous Ltd. All rights reserved.": {},
        "Frame 433": {},
        Footer: {},
        MobileSupport: {},
      },
      variantValues: {},
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const longPluslogoPlusoverlappedPlusOpenParenthesisOneCloseParenthesisOneFourThreeFiveTwoOneThreeEightSevenOnClick =
    useNavigateAction({ type: "url", url: homeurl });
  const buttonOnClick = useNavigateAction({ type: "url", url: loginurl });
  const homeOnClick = useNavigateAction({ type: "url", url: homeurl });
  const termsFourThreeFiveTwoOneFiveThreeFourOnClick = useNavigateAction({
    type: "url",
    url: termsurl,
  });
  const termsFourThreeFiveTwoOneFiveThreeFiveOnClick = useNavigateAction({
    type: "url",
    url: loginurl,
  });
  const supportFourThreeFiveTwoOneFiveEightNineOnClick = useNavigateAction({
    type: "url",
    url: supporturl,
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="350px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "MobileSupport")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
        padding="10px 20px 10px 20px"
        backgroundColor="rgba(125,26,183,1)"
        display="flex"
        {...getOverrideProps(overrides, "Header")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 517")}
        >
          <Image
            width="186.03px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={logosrc}
            onClick={() => {
              longPluslogoPlusoverlappedPlusOpenParenthesisOneCloseParenthesisOneFourThreeFiveTwoOneThreeEightSevenOnClick();
            }}
            {...getOverrideProps(
              overrides,
              "long+logo+overlapped+(1) 143521387"
            )}
          ></Image>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 321")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor="rgba(84,18,125,1)"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Sign in"
            onClick={() => {
              buttonOnClick();
            }}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="20px 20px 20px 20px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "Guidelines")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 43243521399")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="32px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Support"
            {...getOverrideProps(overrides, "Support43521400")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 6343521401")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 518")}
          >
            <Flex
              gap="24px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
              borderRadius="8px"
              padding="32px 32px 32px 32px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 39043521403")}
            >
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 13843521406")}
              >
                <Text
                  //fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Please consult our FAQs to see if your question is answered here. If you require further assistance, please email support@rundezvous.io ."
                  {...getOverrideProps(
                    overrides,
                    "Please consult our FAQs to see if your question is answered here. If you require further assistance, please email support@rundezvous.io ."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="20px 10px 20px 10px"
        backgroundColor="rgba(250,250,250,1)"
        display="flex"
        {...getOverrideProps(overrides, "Terms43521414")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 43243521415")}
        >
          <Text
            //fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="32px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="FAQ"
            {...getOverrideProps(overrides, "FAQ")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,0)"
          display="flex"
          {...getOverrideProps(overrides, "Frame 6343521417")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
            borderRadius="8px"
            padding="32px 32px 32px 32px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 39043521543")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 13843521544")}
            ></Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 538")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What does Rundezvous do?"
                {...getOverrideProps(overrides, "What does Rundezvous do?")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Rundezvous is a running event scheduling service. You can add your approximate location, your availability and running preferences to the app, it then uses advanced algorithms to create social running events of 3+ people at a location and time that is convenient for you. The more users on the app, the closer the starting point of the run will be to you."
                {...getOverrideProps(
                  overrides,
                  "Rundezvous is a running event scheduling service. You can add your approximate location, your availability and running preferences to the app, it then uses advanced algorithms to create social running events of 3+ people at a location and time that is convenient for you. The more users on the app, the closer the starting point of the run will be to you."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 531")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="How will I know when a run is happening?"
                {...getOverrideProps(
                  overrides,
                  "How will I know when a run is happening?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Every Wednesday afternoon our algorithm creates new runs for you for the following week (Monday-Sunday). You will receive event invitations by email and they will also appear in the app for you to accept or decline. You will receive final confirmation of the run 48 hours in advance of if enough people have accepted."
                {...getOverrideProps(
                  overrides,
                  "Every Wednesday afternoon our algorithm creates new runs for you for the following week (Monday-Sunday). You will receive event invitations by email and they will also appear in the app for you to accept or decline. You will receive final confirmation of the run 48 hours in advance of if enough people have accepted."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 532")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What do you do with my data?"
                {...getOverrideProps(overrides, "What do you do with my data?")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Your data is held securely encrypted in our database. All our users are welcome to use “usernames” rather than full names as well as rough locations rather than home addresses if they prefer. This is an app made by runners for runners and we don’t want to sell your data - ever. We will never disclose your information to anyone outside our service. Other users on the app can never view your information. You can delete your account at any time and your data will be completely removed from all our systems.&#xA;&#xA;A complete privacy policy is available at www.rundezvous.io/terms."
                {...getOverrideProps(
                  overrides,
                  "Your data is held securely encrypted in our database. All our users are welcome to use \u201Cusernames\u201D rather than full names as well as rough locations rather than home addresses if they prefer. This is an app made by runners for runners and we don\u2019t want to sell your data - ever. We will never disclose your information to anyone outside our service. Other users on the app can never view your information. You can delete your account at any time and your data will be completely removed from all our systems. A complete privacy policy is available at www.rundezvous.io/terms."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 533")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="If I can’t make a run anymore, what should I do?"
                {...getOverrideProps(
                  overrides,
                  "If I can\u2019t make a run anymore, what should I do?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="You can always cancel your attendance at a run, but please try to do this as far in advance as possible! We know that sometimes things come up, so we try to create larger runs, so any one person not making it doesn’t stop the run going ahead."
                {...getOverrideProps(
                  overrides,
                  "You can always cancel your attendance at a run, but please try to do this as far in advance as possible! We know that sometimes things come up, so we try to create larger runs, so any one person not making it doesn\u2019t stop the run going ahead."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 534")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Why do you need my location?"
                {...getOverrideProps(overrides, "Why do you need my location?")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Rundezvous is a service that tries to make events as convenient as possible for its users. To schedule runs close to where you live, we need a location for you. However please do feel free to provide a “rough” location instead of your exact location."
                {...getOverrideProps(
                  overrides,
                  "Rundezvous is a service that tries to make events as convenient as possible for its users. To schedule runs close to where you live, we need a location for you. However please do feel free to provide a \u201Crough\u201D location instead of your exact location."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 535")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Why do I need a subscription?"
                {...getOverrideProps(
                  overrides,
                  "Why do I need a subscription?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="The goal of Rundezvous is to make social running as convenient and easy as possible to support people’s physical and mental health by connecting local runners. We want to support as many people as possible to access our app. However, hosting the service and running the scheduling algorithms have a cost that we need to cover to be able to continue providing the service. We’ve set the subscription cost as low as possible. We are an inclusive platform and wouldn’t want anyone to be priced out. If you have personal circumstances which makes the subscription unaffordable for you but you think you’d personally benefit from access, please send us an email at uk.rundezvous@gmail.com ."
                {...getOverrideProps(
                  overrides,
                  "The goal of Rundezvous is to make social running as convenient and easy as possible to support people\u2019s physical and mental health by connecting local runners. We want to support as many people as possible to access our app. However, hosting the service and running the scheduling algorithms have a cost that we need to cover to be able to continue providing the service. We\u2019ve set the subscription cost as low as possible. We are an inclusive platform and wouldn\u2019t want anyone to be priced out. If you have personal circumstances which makes the subscription unaffordable for you but you think you\u2019d personally benefit from access, please send us an email at uk.rundezvous@gmail.com ."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 536")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Where can I subscribe?"
                {...getOverrideProps(overrides, "Where can I subscribe?")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="You can subscribe to Rundezvous on our web application (login at the top of this page), or via the mobile applications on either Google Play Store or Apple App Store. Please be aware that  prices may differ slightly depending on the subscription method used, this is because different charges are applied by Google, Apple or Stripe. After you have subscribed, you can access your account on the web-app or Android/iPhone devices without restrictions regardless of the subscription platform."
                {...getOverrideProps(
                  overrides,
                  "You can subscribe to Rundezvous on our web application (login at the top of this page), or via the mobile applications on either Google Play Store or Apple App Store. Please be aware that \u00A0prices may differ slightly depending on the subscription method used, this is because different charges are applied by Google, Apple or Stripe. After you have subscribed, you can access your account on the web-app or Android/iPhone devices without restrictions regardless of the subscription platform."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 537")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="How do I input my schedule?"
                {...getOverrideProps(overrides, "How do I input my schedule?")}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Both the web application and the Android/Apple apps allow you to enter your availability to join social runs by accessing the “Schedule” section. You can add as many slots as you like, bear in mind that you’re inputting the slots during which you’d be free to go for a run, you need to build in any time for getting changed, showering etc. before/after."
                {...getOverrideProps(
                  overrides,
                  "Both the web application and the Android/Apple apps allow you to enter your availability to join social runs by accessing the \u201CSchedule\u201D section. You can add as many slots as you like, bear in mind that you\u2019re inputting the slots during which you\u2019d be free to go for a run, you need to build in any time for getting changed, showering etc. before/after."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 526")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What is your referral scheme?"
                {...getOverrideProps(
                  overrides,
                  "What is your referral scheme?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="The Rundezvous experience gets better the more people in your local area are using it, so we want to reward you for spreading the word. You will receive a free month of membership for every person who signs up using your unique code (found under “Referrals”).&#xA;&#xA;If you are a web application subscription user through stripe, this will be automatically applied. If you are an app subscription user you will receive a referral token which you can redeem to get your free month."
                {...getOverrideProps(
                  overrides,
                  "The Rundezvous experience gets better the more people in your local area are using it, so we want to reward you for spreading the word. You will receive a free month of membership for every person who signs up using your unique code (found under \u201CReferrals\u201D). If you are a web application subscription user through stripe, this will be automatically applied. If you are an app subscription user you will receive a referral token which you can redeem to get your free month."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 527")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="My referral didn’t work?"
                {...getOverrideProps(
                  overrides,
                  "My referral didn\u2019t work?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Your free month is only applied once the person you refer takes out a subscription. We want people to be able to try before they buy, so everyone starts on a free-trial period until scheduling is successful in their area."
                {...getOverrideProps(
                  overrides,
                  "Your free month is only applied once the person you refer takes out a subscription. We want people to be able to try before they buy, so everyone starts on a free-trial period until scheduling is successful in their area."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 528")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Can I refer people before subscribing?"
                {...getOverrideProps(
                  overrides,
                  "Can I refer people before subscribing?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Yes you can, though only as mobile app user and you won’t be able to redeem your referral tokens until you have an active subscription."
                {...getOverrideProps(
                  overrides,
                  "Yes you can, though only as mobile app user and you won\u2019t be able to redeem your referral tokens until you have an active subscription."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 529")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Is there a run leader for every event?"
                {...getOverrideProps(
                  overrides,
                  "Is there a run leader for every event?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Not currently, at the moment every event is community led. When you meet you will be in your home location in an area you are very familiar with. It will be up to you to agree with the other attendees a good route! If there are any Strava users, you could make use of their routes feature to choose a good route. Please be careful to choose appropriate routes and only run places/routes you would be happy running on your own."
                {...getOverrideProps(
                  overrides,
                  "Not currently, at the moment every event is community led. When you meet you will be in your home location in an area you are very familiar with. It will be up to you to agree with the other attendees a good route! If there are any Strava users, you could make use of their routes feature to choose a good route. Please be careful to choose appropriate routes and only run places/routes you would be happy running on your own."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 530")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="What is the free-trial period?"
                {...getOverrideProps(
                  overrides,
                  "What is the free-trial period?"
                )}
              ></Text>
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="This is an initial period after you create an account where you have full access to Rundezvous scheduling. It is initially one month long, but is extended until our system has enough matches. The time remaining in your trial period is displayed on the home screen and you will receive emails confirming any extensions or expiries."
                {...getOverrideProps(
                  overrides,
                  "This is an initial period after you create an account where you have full access to Rundezvous scheduling. It is initially one month long, but is extended until our system has enough matches. The time remaining in your trial period is displayed on the home screen and you will receive emails confirming any extensions or expiries."
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="40px 40px 40px 40px"
        backgroundColor="rgba(223,188,245,1)"
        display="flex"
        {...getOverrideProps(overrides, "Footer")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 313")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 434")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="20px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="471px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Rundezvous"
              {...getOverrideProps(overrides, "Rundezvous")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 405")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 403")}
          >
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="600"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Navigation"
              {...getOverrideProps(overrides, "Navigation")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Home"
              onClick={() => {
                homeOnClick();
              }}
              {...getOverrideProps(overrides, "Home")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms"
              onClick={() => {
                termsFourThreeFiveTwoOneFiveThreeFourOnClick();
              }}
              {...getOverrideProps(overrides, "Terms43521534")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Login"
              onClick={() => {
                termsFourThreeFiveTwoOneFiveThreeFiveOnClick();
              }}
              {...getOverrideProps(overrides, "Terms43521535")}
            ></Text>
            <Text
              //fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(157,40,226,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Support"
              onClick={() => {
                supportFourThreeFiveTwoOneFiveEightNineOnClick();
              }}
              {...getOverrideProps(overrides, "Support43521589")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 433")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "LogoWithText")}
          >
            <Image
              width="197px"
              height="39px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={whitelogosrc}
              {...getOverrideProps(
                overrides,
                "long+logo+overlapped+(1) 143521538"
              )}
            ></Image>
          </Flex>
          <Text
            //fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(102,112,133,1)"
            lineHeight="24px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="© 2024 Rundezvous Ltd. All rights reserved."
            {...getOverrideProps(
              overrides,
              "\u00A9 2024 Rundezvous Ltd. All rights reserved."
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
