// import { getCurrentUser } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';

function createScheduleArray(str){
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const bhs = [  "06:00","07:00","08:00","09:00","10:00","11:00","12:00",
        "13:00","14:00","15:00","16:00","17:00","18:00","19:00"];
    const ehs  = [  "07:00","08:00","09:00","10:00","11:00","12:00","13:00",
    "14:00","15:00","16:00","17:00","18:00","19:00","20:00"];
    var retArr = [];
    str = str.slice(1,-1).replaceAll(" ","");
    var active;
    var carry;
    var overIndex = 0;
    for (var i=0; i<7; i++){
        active = false;
        carry = 0;
        for (var j=0; j<14; j++){
            if(str.charAt((i*14)+j)==="0"){
                if(active){
                    active = false;
                    retArr.push({
                        day: days[i],
                        bh: bhs[j-carry],
                        eh: ehs[j-1],
                        bhindex: j-carry,
                        ehindex: j-1,
                        index: overIndex
                    })
                    overIndex++;
                    carry = 0;
                } else {
                    active = false;
                }
            } else{
                if(active){
                    carry++;
                } else {
                    active = true;
                    carry++;
                }
            }
        }
        if(active){
            retArr.push({
                day: days[i],
                bh: bhs[14-carry],
                eh: ehs[13],
                bhindex: 14-carry,
                ehindex: 13,
                index: overIndex
            })
            overIndex++;
        }
    }
    return retArr;
}


async function updateMember(item) {
    item.pk = 'member-'+item.email
    item.sk ='member-'+item.email
    console.log(item);
    try{
        var result = post({apiName: 'membereventsapi', path: '/member', options: {body:item}});
        // console.log( result);
        return;
    } catch (e) {
        if(e.response.data.body.error.code && e.response.data.body.error.code === "BadWords"){
            result.error = "BadWords";
            alert("Your what3words was not valid!")
        }
        console.log('POST call failed: ', JSON.parse(e.response.body));
        return;
    }
}


export default updateMember;