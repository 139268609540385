/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useAuthSignOutAction,
  useNavigateAction,
} from "./utils";
import MyIcon from "./MyIcon";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function MobileSideBar(props) {
  const {
    logoURL,
    homeURL,
    runListURL,
    myDetailsURL,
    scheduleURL,
    subscriptionURL,
    logoutURL,
    name,
    overrides,
    ...rest
  } = props;
  const frameFourOneFourOnClick = useNavigateAction({
    type: "url",
    url: homeURL,
  });
  const labelFourZeroFourOneEightNineTwoOnClick = useNavigateAction({
    type: "url",
    url: "",
  });
  const linkFourZeroFourOneEightNineFiveOnClick = useNavigateAction({
    type: "url",
    url: runListURL,
  });
  const linkFourZeroFourOneEightNineNineOnClick = useNavigateAction({
    type: "url",
    url: myDetailsURL,
  });
  const linkFourZeroFourOneNineZeroThreeOnClick = useNavigateAction({
    type: "url",
    url: scheduleURL,
  });
  const linkFourZeroFourOneNineFiveZeroOnClick = useNavigateAction({
    type: "url",
    url: subscriptionURL,
  });
  const logoutOnClick = useAuthSignOutAction({ global: false });
  return (
    <Flex
      gap="10px"
      direction="row"
      width="300px"
      height="700px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="20px 20px 20px 20px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "MobileSideBar")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="32px 0px 32px 0px"
        {...getOverrideProps(overrides, "NavBarSide")}
      >
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 3214041885")}
        >
          <Flex
            gap="32px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 32px 0px 32px"
            {...getOverrideProps(overrides, "Frame 3214041886")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={() => {
                frameFourOneFourOnClick();
              }}
              {...getOverrideProps(overrides, "Frame 414")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 322")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  borderRadius="4px"
                  padding="6px 6px 6px 6px"
                  backgroundColor="rgba(223,188,245,1)"
                  {...getOverrideProps(overrides, "icon4041890")}
                >
                  <MyIcon
                    width="18px"
                    height="18px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    type="home"
                    {...getOverrideProps(overrides, "MyIcon4041891")}
                  ></MyIcon>
                </Flex>
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(157,40,226,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Home"
                  onClick={() => {
                    labelFourZeroFourOneEightNineTwoOnClick();
                  }}
                  {...getOverrideProps(overrides, "label4041892")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="30px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Section")}
            >
              <Text
                //fontFamily="Inter"
                fontSize="16px"
                fontWeight="600"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Manage"
                {...getOverrideProps(overrides, "label4041894")}
              ></Text>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={() => {
                  linkFourZeroFourOneEightNineFiveOnClick();
                }}
                {...getOverrideProps(overrides, "link4041895")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  borderRadius="4px"
                  padding="6px 6px 6px 6px"
                  backgroundColor="rgba(223,188,245,1)"
                  {...getOverrideProps(overrides, "icon4041896")}
                >
                  <MyIcon
                    width="18px"
                    height="18px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    type="content"
                    {...getOverrideProps(overrides, "MyIcon4041897")}
                  ></MyIcon>
                </Flex>
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(157,40,226,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Run List"
                  {...getOverrideProps(overrides, "label4041898")}
                ></Text>
              </Flex>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={() => {
                  linkFourZeroFourOneEightNineNineOnClick();
                }}
                {...getOverrideProps(overrides, "link4041899")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  borderRadius="4px"
                  padding="6px 6px 6px 6px"
                  backgroundColor="rgba(223,188,245,1)"
                  {...getOverrideProps(overrides, "icon4041900")}
                >
                  <MyIcon
                    width="18px"
                    height="18px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    type="group"
                    {...getOverrideProps(overrides, "MyIcon4041901")}
                  ></MyIcon>
                </Flex>
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(157,40,226,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="161px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="My Details"
                  {...getOverrideProps(overrides, "label4041902")}
                ></Text>
              </Flex>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={() => {
                  linkFourZeroFourOneNineZeroThreeOnClick();
                }}
                {...getOverrideProps(overrides, "link4041903")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  borderRadius="4px"
                  padding="6px 6px 6px 6px"
                  backgroundColor="rgba(223,188,245,1)"
                  {...getOverrideProps(overrides, "icon4041904")}
                >
                  <MyIcon
                    width="18px"
                    height="18px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    type="file"
                    {...getOverrideProps(overrides, "MyIcon4041905")}
                  ></MyIcon>
                </Flex>
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(157,40,226,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Schedule"
                  {...getOverrideProps(overrides, "label4041906")}
                ></Text>
              </Flex>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={() => {
                  linkFourZeroFourOneNineFiveZeroOnClick();
                }}
                {...getOverrideProps(overrides, "link4041950")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  borderRadius="4px"
                  padding="6px 6px 6px 6px"
                  backgroundColor="rgba(223,188,245,1)"
                  {...getOverrideProps(overrides, "icon40411536")}
                >
                  <MyIcon
                    width="18px"
                    height="18px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    type="data"
                    {...getOverrideProps(overrides, "MyIcon40411537")}
                  ></MyIcon>
                </Flex>
                <Text
                  //fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(157,40,226,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.05px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Subscription"
                  {...getOverrideProps(overrides, "label4041953")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="32px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 3214041921")}
          >
            <Flex
              gap="9px"
              direction="row"
              width="385px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 32px 0px 32px"
              {...getOverrideProps(overrides, "Frame 416")}
            >
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 415")}
              >
                <Flex
                  gap="0"
                  direction="column"
                  width="100px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 3214041925")}
                >
                  <Text
                    //fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(13,26,38,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.01px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={name}
                    {...getOverrideProps(overrides, "Wesley Peck")}
                  ></Text>
                </Flex>
                <Image
                  width="30px"
                  height="30px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={logoutURL}
                  onClick={() => {
                    logoutOnClick();
                  }}
                  {...getOverrideProps(overrides, "logout")}
                ></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
