import * as React from "react";
import { Button, Flex, useTheme} from "@aws-amplify/ui-react";
import settings from '../specific/settings.json';
import {Elements, ExpressCheckoutElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

export default function PaymentComponent(props) {
  const stripePromise = loadStripe(window.location.host === "www.rundezvous.io" ? 
    "pk_live_51PmvYXAlvTIDDgz5CS0mnYIgLJDp9ha6VhVGE50b8E7JNcffwtupAMOUm0aUmsbk2gkuwwIOoAx1p0vxcvZt1LTy00bS64IiMf" 
    :'pk_test_51PmvYXAlvTIDDgz5L3tarNV9cLOYshayPNMQnWfMFB24HJG97N39FtOSz9vTeukPryo3lvw3KboLhLQq0KOyoQq700Q0zDuvKA');

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const checkout = async () => {
      console.log("Clicked checkout");
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
    return (
        <Flex width={"100%"} direction={"column"}>
        
        
          <PaymentElement width={"100%"}/>
          
          <Button disabled={!stripe} onClick={()=> checkout()}>Pay {props.isClub && props.clubSettings ? props.currencySymbol+(4*(1-(props.clubSettings['PercentOff']/100)))+" per month" : props.currencySymbol +"4.00 per month"}</Button>
          <ExpressCheckoutElement />
        </Flex>
      );
    };
  
  // useEffect(() => {
  //   (async function () {
  //       console.log("IN THE USE EFFECT FOR PAYMENT COMPONENT");
  //       const tempEmail = await currentAuthenticatedUser();
  //       var tempMember = await getMember(tempEmail);
  //       if (tempMember !== undefined){
  //           if (tempMember.payment_intent_secret ===""){
  //             console.log("Member doesn't have a payment intent.");
  //           }
  //           setMember(tempMember);
  //       } else {
  //           console.log("DIDN'T FIND A MEMBER IN PAYMENT COMPONENT");
  //       }
  //   })();
  // },[]);

  return (
    props.member && props.member.payment_intent_secret &&
    <Elements stripe={stripePromise} options={
      {
          clientSecret: props.member.payment_intent_secret,
          appearance: {
            theme: 'flat',
            variables: {
              colorPrimary: settings.Theme.PrimaryColor,
              spacingUnit: '5px',
              borderRadius: '10px',
            }
          }
        }
    } currency={props.currency}>
      <CheckoutForm />
    </Elements>
  )
}
